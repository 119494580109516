<template>
  <div id="DepositList">
    <section class="content-header">
      <h1>{{$t('交易明細')}}</h1>
      <ol class="breadcrumb">
        <li><router-link to="/">{{$t('首頁')}}</router-link></li>
        <li class="active">{{$t('交易明細')}}</li>
      </ol>
    </section>
    <section class="content">
      <div class="box box-primary mb-10">
        <div class="box-body">
          <form class="top-box clearfix" @submit="$event.preventDefault(); resetDataList()">
            <div class="pull-left input-group mb-10 mr-10">
              <input type="text" v-model.trim="searchData.暱稱" class="form-control" placeholder="玩家暱稱"/>
            </div>
            <div class="pull-left input-group mb-10 mr-10">
              <input type="text" v-model.trim="searchData.交易ID" class="form-control" placeholder="訂單編號"/>
            </div>
            <div class="pull-left form-group mb-10 mr-10" style="width:max-content;max-width:100%;">
              <label class="pull-left mt-7 control-label">{{$t('查詢區間')}}：</label>
              <div class="pull-left input-group date">
                <div class="input-group-addon fa fa-calendar"></div>
                <DateRangePicker
                  :propTimePicker="false"
                  :propEndDate.sync="local.endTime"
                  :propStartDate.sync="local.startTime"
                  format="YYYY-MM-DD"
                />
              </div>
              <!-- <div class="select-group">
                <select class="form-control" v-model.number="searchData.平台" @change="resetDataList()">
                  <option value="ALL">所有平台</option>
                  <option value="GOOGLE">GooglePlay</option>
                  <option value="IOS">AppleStore</option>
                </select>
              </div> -->
            </div>
            <div class="pull-left mb-10 mr-10">
              <button type="submit" class="btn btn-primary mr-10" @click="">
                查詢
              </button>
              <button type="button" class="btn btn-default" @click="exportOrders()" :disabled="datas.length === 0">
                匯出
              </button>
            </div>
            <div class="pull-left form-group mb-10" style="width:max-content;max-width:100%;height:34px;">
              <label class="pull-left mt-7 control-label" for="searchBatch">{{$t('交易序號批次查詢')}}：</label>
              <input type="file" id="searchBatch">
            </div>
          </form>
          <div class="total-data" style="">
              <b>點數合計：{{ totalMoney }}</b>
              <b>不重複暱稱合計：{{ totalUnique }}</b>
              <b>帳單總筆數：{{ total }}</b>
          </div>
          
        </div>
        <!-- <div class="box-footer text-center no-border">
        </div> -->
        <!-- <pre>{{ datas[0] }}</pre> -->
      </div>
      <div class="nav-tabs-custom tab-warning mb-10">
            <ul class="nav nav-tabs">
              <li :class="{ active: tab == 1 }">
                <a @click="setTab(1)" aria-expanded="true" data-toggle="tab" style="cursor:pointer;">{{$t('點數合計查詢')}}</a>
              </li>
              <li :class="{ active: tab == 2 }">
                <a @click="setTab(2)" aria-expanded="true" data-toggle="tab" style="cursor:pointer;">{{$t('帳單查詢')}}</a>
              </li>
            </ul>
            <div class="tab-content" v-if="tab == 1">
              <div class="table-responsive">
                <table class="table table-bordered table-hover text-center">
                  <thead>
                    <tr class="bg-gray">
                      <th style="min-width: 92px;width:10%">日期</th>
                      <th style="min-width: 92px;width:10%">點數合計</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-if="loading"><td colspan="10">查詢中</td></tr>
                    <tr v-else-if="message"><td colspan="10">{{ message }}</td></tr>
                    <tr v-else-if="datas.length <= 0"><td colspan="10">查詢無資料</td></tr>
                    <tr v-else v-for="(item, index) in datas" :key="index">
                      <td>{{ item.平台商品ID }}</td>
                      <td>{{ item.獲得雀幣 }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="tab-content" v-if="tab == 2">
              <div class="table-responsive">
                <table class="table table-bordered table-hover text-center">
                  <thead>
                    <tr class="bg-gray">
                      <th style="min-width: 50px;width: 3%">編號</th>
                      <th style="min-width: 92px;width:10%">交易序號</th>
                      <th style="min-width: 92px;width:10%">暱稱</th>
                      <th style="min-width: 92px;width: 5%">點數</th>
                      <th style="min-width: 92px;width:10%">配點時間</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-if="loading"><td colspan="10">查詢中</td></tr>
                    <tr v-else-if="message"><td colspan="10">{{ message }}</td></tr>
                    <tr v-else-if="datas.length <= 0"><td colspan="10">查詢無資料</td></tr>
                    <tr v-else v-for="(item, index) in datas" :key="index">
                      <td>{{ uiDate(item.訂單最後處理時間) }}</td>
                      <td><router-link :to="{name:'NmjPlayerData', query:{nick:item.暱稱}}">{{ item.暱稱 }}</router-link></td>
                      <td>{{ item.訂單編號 }}</td>
                      <td>{{ item.平台商品ID }}</td>
                      <td>{{ item.商品名稱 }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="text-center mt-10">
                <Pagination
                  :pageLimitCount="searchData.pageSize"
                  :total="total"
                  ref="pagination"
                  v-on:pageMethod="onPageChange"
                />
              </div>
            </div>
          </div>
    </section>
  </div>
</template>

<style lang="scss" scoped>
.total-data{
  display:flex; 
  flex-wrap:wrap;
  padding:.5em;
  color:#333;
  >*{
    width: max-content;
    margin-right: 3em;
    @media (max-width:599px){
      width: 100%;
    }
  }
}
table.table > tbody > tr:nth-child(2n+1) td{
  background-color: rgba(0,0,0,.01);
}
  table.table > tbody > tr > td {
    word-break: break-word;
  }
  table.table > thead > tr > th{
    word-break: keep-all;
    font-weight: normal;
  }
  
  .nav-tabs-custom .nav-tabs{
    background: linear-gradient(transparent, rgba(0, 0, 0, 0.05));
  }
  .nav-tabs-custom, .tab-content{
    // box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05);
  }
</style>

<script>
/* eslint-disable */
import moment from "moment";
import DateRangePicker from "@/components/DateRangePicker";
import Pagination from "@/components/Pagination";
import define from "@/views/projects/NMJ/_Define";

export default {
  components: {
    DateRangePicker,
    Pagination
  },
  data() {
    return {
      def: define,

      tab: 1,

      loading: false,
      backBtn: false,
      total: 0, //總筆數
      totalMoney: 0, //總金額(TWD)
      totalUnique: 0, //不重複人數
      searchData: {
        平台: "ALL",
        暱稱: "",
        交易ID: "",
        開始時間: "2023-01-01 00:00:00",
        結束時間: "2023-08-25 23:59:59",
        pageNum: 1,
        pageSize: 20
      },
      local: {
        startTime: new moment().subtract(7, "days").format("YYYY/MM/DD 00:00:00"),
        endTime: new moment().format("YYYY/MM/DD 23:59:59"),
      },
      datas: [],
    };
  },
  async created() {
    if (this.$route.query.nick){
      this.searchData.暱稱 = this.$route.query.nick;
    }
    this.getDataList();
  },
  mounted() {
  },
  methods: {
    setTab(num){
      this.tab = num;
      let query = Object.assign({}, this.$route.query, {tab:num});
      if (JSON.stringify(query) != JSON.stringify(this.$route.query)){
        this.$router.replace({query:query});
      }
    },
    async onPageChange(pageIdx, pageSize) {
      this.searchData.pageNum = pageIdx;
      this.searchData.pageSize = pageSize;
      this.getDataList();
    },
    resetDataList(){
      this.searchData.pageNum = 1;
      this.getDataList();
    },

    getDataList() {
      // if (!this.searchData.暱稱) {
      //   this.$eventBus.$emit("showAlert", "請填寫玩家暱稱！");
      //   return;
      // }
      if (this.searchData.交易ID){
        this.searchData.開始時間 = new moment(new Date(0)).format("YYYY/MM/DD HH:mm:ss");
        this.searchData.結束時間 = new moment(new Date()).add(1, "month").format("YYYY/MM/DD HH:mm:ss");
      } else {
        this.searchData.開始時間 = new moment(this.local.startTime).format("YYYY/MM/DD HH:mm:ss");
        this.searchData.結束時間 = new moment(this.local.endTime).format("YYYY/MM/DD HH:mm:ss");
      }
      this.loading = true;
      this.$store
        .dispatch("nmjPlayerData/deposit", this.searchData)
        .then((result) => {
          this.datas = result.datas || [];
          this.total = result.total || 0;
          this.totalMoney = result.totalPoint ? result.totalPoint.點數合計 : 0;
          this.totalUnique = result.noRepeatNum ? result.noRepeatNum.不重複數 : 0;
          this.loading = false;
          let query = {};
          if (this.searchData.暱稱 != "") query['nick'] = this.searchData.暱稱;
          if (JSON.stringify(query) != JSON.stringify(this.$route.query)){
            this.$router.replace({query:query});
          }
        }, (res) => {
          this.datas = [];
          this.total = 0;
          this.loading = false;
        });
    },
    exportOrders() {
      this.$store.dispatch("nmjPlayerData/exportDeposit", this.searchData);
    },
    uiDate(date){
      let d = new Date(date);
      if (d == "Invalid Date") return date;
      return d.toLocaleString('zh-TW', {
        year:"numeric",
        month:"2-digit",
        day:"2-digit",
        hour:"2-digit",
        minute:"2-digit",
        second:"2-digit",
        hourCycle:"h23",
      });
    }
  },
};
</script>
