<template>
  <span>
    <div
      class="checkbox-inline"
      :id="'checkbox'+index"
      :key="index"
      v-for="(item,index) in propOptions"
    >
      <label>
        <iCheckCheckBox2
          v-bind:propValue="item"
          v-bind:propChecked="selecteds[item]"
          v-on:update:checked="onChange"
        />
        {{item}}
      </label>
    </div>
  </span>
</template>

<script>
import iCheckCheckBox2 from "@/components/iCheck_CheckBox";

export default {
  components: {
    iCheckCheckBox2
  },
  props: {
    propOptions: Array,
    propSelecteds: {}
  },
  data() {
    return {
      selecteds: {}
    };
  },
  created() {
    if (this.propSelecteds != undefined) {
      this.updateSelected(this.propSelecteds);
    }
  },
  watch: {
    propValue(value) {
      this.setStatus(value);
    },
    propSelecteds(selectValue) {
      this.updateSelected(selectValue);
    }
  },
  methods: {
    setStatus(value) {},
    onChange(values) {
      this.selecteds[values["value"]] = values["checked"];
      var res = [];
      this.propOptions.forEach((value, idx) => {
        if (this.selecteds[value] == 1) res.push(value);
      });
      this.$emit("update:propSelecteds", res);
    },
    updateSelected(selectValue) {
      this.propOptions.forEach((value, idx) => {
        this.$set(
          this.selecteds,
          value,
          selectValue.indexOf(value) >= 0 ? 1 : 0
        );
      });
    }
  }
};
</script>
