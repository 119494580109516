<template>
    <div>
        <div class="modal fade" ref="popUpImg">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <button @click="close" class="close">
                            <span aria-hidden="true">×</span>
                        </button>
                        <h4 class="modal-title" style="color: #333333;">{{ popUpImgSrc }}</h4>
                    </div>
                    <div class="modal-body" style="text-align: center;">
                        <img :src="popUpImgSrc" alt="">
                    </div>
                    <div class="modal-footer">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            popUpImgSrc: "",
        };
    },
    methods: {
        close(){    
            $(this.$refs.popUpImg).modal('hide');
        },
        Open(Content) {
            this.popUpImgSrc = Content;
            $(this.$refs.popUpImg).modal("show");
        },
    },
}
</script>