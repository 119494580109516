import { render, staticRenderFns } from "./GuildData.vue?vue&type=template&id=a97505a8&scoped=true"
import script from "./GuildData.vue?vue&type=script&lang=js"
export * from "./GuildData.vue?vue&type=script&lang=js"
import style0 from "./GuildData.vue?vue&type=style&index=0&id=a97505a8&prod&lang=scss&scoped=true"
import style1 from "./GuildData.vue?vue&type=style&index=1&id=a97505a8&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a97505a8",
  null
  
)

export default component.exports