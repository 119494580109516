<template>
  <div id="AnnouncementList">
    <section class="content-header">
      <h1>精華影片</h1>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li  class="active">精華影片</li>
      </ol>
    </section>

    <section class="content">
      <div class="box box-primary">
        <div class="box-body">
          <div class="top-box">

            <div class="select-group" id="RankSort">
              <select
                class="form-control"
                id="selectUnit"
                required="required"
                v-model="searchData.order"
              >
                <option value=1>默認排序</option>
                <option value=3>倍數排序</option>
                <option value=5>分數排序</option>
              </select>
            </div>

            <div class="select-group" id="RankClassify">
              <select
                class="form-control"
                id="selectUnit"
                required="required"
                v-model="searchData.類型"
              >
                <option value=null>默認分類</option>
                <option value="1,3">得分</option>
                <option value="2,3">倍率</option>
                <option value="3">得分&倍率</option>
              </select>
            </div>

            <div class="select-group">
              <select
                class="form-control"
                id="selectUnit"
                required="required"
                v-model="searchData.上架"
              >
                <option value=-1>上/下架</option>
                <option value=1>上架</option>
                <option value=0>下架</option>
              </select>
            </div>

            <div class="date-group w-a">
              <label class="control-label">開獎日期：</label>
              <div class="input-group date">
                  <div class="input-group-addon">
                      <i class="fa fa-calendar"></i>
                  </div>
                  <div class="pull-right">
                    <DatePicker :propValue.sync="date" />
                  </div>
              </div>
            </div>

            <!-- 搜尋 -->
            <div class="input-group">
              <div class="input-group-btn">
                <button
                  @click="search()"
                  class="btn btn-default"
                  type="button"
                >
                  <i class="fa fa-search"></i> 查詢
                </button>
              </div>
            </div>
          </div>

          <div class="table-responsive mt-10">
            <table class="table table-hover table-bordered min-800 AdListTable">
              <tbody>
                <tr class="bg-gray">
                  <!-- <th style="min-width:50px; width: 10%"></th> -->
                  <th style="min-width:100px; width: 10%">上傳日期</th>
                  <th style="min-width: 90px; width: 10%">分類</th>
                  <th style="min-width: 200px; width: 20%">影片縮圖</th>
                  <th style="min-width: 90px; width: 10%">觀看影片</th>
                  <th style="min-width: 90px; width: 10%">暱稱</th>
                  <th style="min-width: 90px; width: 10%">倍數</th>
                  <th style="min-width: 90px; width: 10%">得分</th>
                  <th style="min-width: 60px; width: 5%">等級</th>
                  <th style="min-width: 90px; width: 10%">開獎日期</th>
                  <th style="min-width: 90px; width: 10%">遊戲館編號</th>
                  <th style="min-width: 90px; width: 10%">上架狀態</th>
                  <th style="min-width: 90px; width: 10%">異動人員</th>
                  <th style="min-width: 90px; width: 10%">異動時間</th>
                </tr>
                <template v-if="total > 0">
                  <tr :key="item.id" v-for="(item, index) in datas">
                    <!-- <td>{{index+1}}</td> -->
                    <td class="text-center">{{ item.建立時間 | dateFormat('YYYY-MM-DD ') }}</td>
                    <td class="text-center">{{ GetStatus(item.類型) }}</td>
                    <td>
                      <!-- <i class="fa fa-fw fa-youtube-play"></i> -->
                      <img :src="item.封面連結"  :alt="item.封面連結" >
                    </td>
                    <td class="text-center">
                      <button class="btn btn-primary"
                        @click="openVideo(item)">播放</button>
                    </td>
                    <td class="text-center"> {{ item.暱稱  || ' - '}}</td>
                    <td class="text-center"> {{ Math.ceil(item.倍數) | currencyFormat }}</td>
                    <td class="text-center"> {{ item.分數 | currencyFormat }}</td>
                    <td class="text-center"> {{ item.等級 | currencyFormat }}</td>
                    <td class="text-center"> {{ item.中獎時間 | dateFormat('YYYY-MM-DD HH:mm:ss')}}</td>
                    <td class="text-center"> {{ item.遊戲館編號 }}</td>
                    <td align="center">
                      <div class="ckbx-style-8 ckbx toggleInput">
                        <input
                          type="checkbox"
                          name="ckbx-style-8"
                          :id="`check${index}`"
                          :false-value="false"
                          :true-value="true"
                          v-model="item.上架"
                          @change="toggle(item)"
                        />
                        <label :for="`check${index}`"></label>
                      </div>
                    </td>
                    <td class="text-center"> {{ item.異動人員 || ' - ' }}</td>
                    <td class="text-center">{{ item.異動時間 | dateFormat('YYYY-MM-DD HH:mm:ss')  }}</td>
                  </tr>
                </template>
                <template v-else>
                  <tr>
                    <td class="text-center" colspan="10">查詢無任何資料</td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
        </div>
        <div class="box-footer text-center no-border" v-show="total > searchData.pageSize">
          <Pagination
            ref="pagination"
            :total="total"
            :pageLimitCount="searchData.pageSize"
            v-on:pageMethod="onPageChange"
            :type2="true"
          />
        </div>

        <VideoModal ref="VideoModal" />

      </div>
    </section>
  </div>
</template>

<script>
/* eslint-disable */
import DatePicker from "@/components/DatePicker";
import moment from "moment";
import Pagination from "@/components/Pagination";
import VideoModal from "./components/VideoModal.vue";

export default {
  components: {
    DatePicker,
    moment,
    Pagination,
    VideoModal,
  },
  delimiters: ["${", "}"],
  name: "app",
  props: {
    id: "",
  },
  data() {
    return {
      total: 0, //總筆數
      projectId: this.$store.state.projectId,

      date:  new moment().format("YYYY-MM-DD"),
      searchData: {
        上架: -1, //-1:all, 1:上架, 0:下架
        類型: null,
        date:  "",
        order: 1,
        pageNum: 1,
        pageSize: 10, //每頁顯示筆數
      },
      datas: [],
      // 1, 中獎時間 asc,2 倍數 asc,  中獎時間 asc,
      //3 倍數 desc,  中獎時間 asc,4 分數 asc,  中獎時間 asc,
      //5 分數 desc,  中獎時間 asc

    };
  },
  mounted() {
    this.date = new moment().subtract(1, "day").format("YYYY-MM-DD");
    this.getDataList();
  },
  methods: {
    search(){
      this.searchData.pageNum = 1;
      if (this.searchData.類型 == "null" || !this.searchData.類型) {
        this.searchData.類型 = null;
      } 
      this.getDataList();
    },
    getDataList() {

      this.searchData.date = this.date;
      console.log(this.searchData);
      this.$store
        .dispatch("xinVideo/精華影片列表查詢", this.searchData)
        .then((result) => {
          if (result) {
            this.datas = result.datas || [];
            // this.datas = this.datas.concat(this.fake);
            this.total = result.total;
            this.$refs.pagination.setPage(this.searchData.pageNum, this.total);
          } else {
            this.datas = [];
            this.total = 0;
          }
        });
    },

    async onPageChange(pageIdx, pageSize) {
      this.searchData.pageNum = pageIdx;
      this.searchData.pageSize = pageSize;
      this.getDataList();
    },

    toggle(item) {
      const action = item.上架 ? "上架" : "下架";

      console.log(item);
      this.$eventBus.$emit("showConfirm", `確定要${action}嗎?`, (isOK) => {
        if (isOK) {
          this.$store
            .dispatch("xinVideo/精華影片上下架", {
              精華影片ID: item.精華影片ID,
              上架: item.上架
            })
            .then((result) => {
              this.$eventBus.$emit("showAlert", `${action}更新成功！`);
              this.getDataList();
            });
        } else {
          item.上架 = item.上架 ? false : true;
        }
      });
    },

    openVideo(item){
      this.$refs.VideoModal.show(item);
    },

    GetStatus(item) {
      switch (item) {
        case 1:
          return "得分";
        case 2:
          return "倍率";
        case 3:
          return "得分&倍率";
        default:
          return "舊資料";
      }
    },
  },
};
</script>
<style lang="scss" scoped>
  .videoWrapper {
    width: 100%;
    margin: 0 auto ;
    position: relative;

    video {
      width: 100%;
    }
  }

  .video-box {
    position: relative;

    &:hover {
      img {
        filter: brightness(1.1);
      }
    }

    img {
      filter: brightness(0.8);
    }

    i {
      font-size: 30px;
      height: 30px;
      color: #d8413e;
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      margin: auto;
      z-index: 2;
    }
    


  }


</style>