<template>
    <div class="modal fade" data-backdrop="static" ref="popForm">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <button aria-label="Close" class="close" type="button" @click="hide()">
                        <span aria-hidden="true">×</span>
                    </button>
                    <h4 class="modal-title">匯入中獎發票</h4>
                </div>
                <div class="box-body" ref="uploadForm">
                    <div class="form-group">
                        <label><span class="text-red">*</span>匯入A檔</label>
                        <input name="file" type="file" ref="invoiceTypeA" />
                    </div>
                    <div class="form-group">
                        <label><span class="text-red">*</span>匯入X檔</label>
                        <input name="file" type="file" ref="invoiceTypeX" />
                    </div>
                    <div class="form-group">
                        <label><span class="text-red">*</span>匯入Z檔</label>
                        <input name="file" type="file" ref="invoiceTypeZ" />
                    </div>
                    <div class="modal-footer">
                        從財政部下載後解碼出來的文字檔進行匯入
                        <button class="btn btn-primary" type="button" @click="readExcel()">確定匯入</button>
                        <button class="btn btn-default pull-right" type="button" @click="hide()">關閉</button>
                    </div>
                </div>
                <div class="box-body" style="display: none;" ref="processForm">
                    <div class="form-group logDiv">
                        <div v-for="(item, i) of logs" :key="i + item" style="white-space: pre-line;"> 
                            <label>{{ item }}</label>
                        </div>
                    </div>
                    <div class="modal-footer" style="display: none;" ref="switchFormBtn">
                        <button class="btn btn-default pull-right" type="button" @click="匯入結束()">匯入結束</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
  
<script>
import api from "@/assets/js/app-api";
export default {
    components: {
    },
    data() {
        return {
            logs: []
        };
    },
    computed: {
    },
    methods: {
        show() {
            $(this.$refs.popForm).modal("show");
        },
        hide() {
            $(this.$refs.popForm).modal("hide");
        },
        async readExcel() {
            //$(this.$refs.uploadForm).hide();
            $(this.$refs.processForm).show();
            $(this.$refs.switchFormBtn).hide();

            this.logs = [];
            try {
                //檢查資料，出錯由catch處理
                this.checkExcel(this.$refs.invoiceTypeA, 'A');
                this.checkExcel(this.$refs.invoiceTypeX, 'X');
                this.checkExcel(this.$refs.invoiceTypeZ, 'Z');
                
                //上傳資料，出錯回傳字串
                this.logs.push("A檔" + await this.uploadExcel(this.$refs.invoiceTypeA));
                this.logs.push("X檔" + await this.uploadExcel(this.$refs.invoiceTypeX));
                this.logs.push("Z檔" + await this.uploadExcel(this.$refs.invoiceTypeZ));
            } catch (e) {
                console.error(`上傳異常: ${e}`)
            }
            this.$refs.invoiceTypeA.value = "";
            this.$refs.invoiceTypeX.value = "";
            this.$refs.invoiceTypeZ.value = "";
            $(this.$refs.switchFormBtn).show();
        },
        匯入結束() {
            $(this.$refs.uploadForm).show();
            $(this.$refs.processForm).hide();
            $(this.$refs.switchFormBtn).hide();
        },
        checkExcel(file, 歸戶類型) {
            if (!file) {
                // throw "請選取檔案:" + 歸戶類型;
                return true;
            }
            if (!file.files) {
                // throw "請選取檔案:" + 歸戶類型;
                return true;
            }
            if (!file.files[0]) {
                // throw "請選取檔案:" + 歸戶類型;
                return true;
            }
            if (!new RegExp('^' + 歸戶類型 + '_', 'g').test(file.files[0].name)) {
                this.$eventBus.$emit('showAlert', "匯入失敗，請檢查檔名:" + 歸戶類型)
                throw "匯入失敗，請檢查檔名:" + 歸戶類型;
            }
            return true;
        },
        async uploadExcel(file, fackStatus = '1') {
            if (!(file && file.files && file.files[0])) return "無上傳資料";

            let data = new FormData();
            data.append("File", file.files[0]);

            let res = await api.IPSSetting.InvoiceJackpotImport(data);
            if (res && res.data && res.data.status == 1) {
                let msg = (res.data.message && res.data.content) ? res.data.message + "\r\n" + res.data.content : '匯入成功';
                this.$eventBus.$emit('showAlert', msg)
                this.hide()
            } else {
                let errMsg = (res && res.data) && (res.data.errorDetail ? res.data.errorDetail.errorMsg : (res.data.message ? res.data.message : `匯入失敗: ${this.logs}`));
                this.$eventBus.$emit('showAlert', errMsg)
                throw errMsg + "(S)";
            }
        }
    }
};
</script>

<style scoped>
.logDiv {
    overflow-y: scroll;
    height: 300px;
}
</style>