/* eslint-disable */
import api from '@/assets/js/app-api';
import dfn from "@/views/projects/XSW/_Define";
import i18n from "@/assets/js/i18nFunc";

// actions
const actions = {
    /** @param data {{path:string, data:any}} */
    post({commit}, data){ return callAPI.call(this, data) },

    /** @param data {{path:string, data:FormData}} */
    form({commit}, data){ return callAPIForm.call(this, data) },
}
function callAPI(data = {path:'/', data:{}}, lang = "zh-TW"){
    let pathArr = data.path.split('/');
    let command = pathArr[pathArr.length - 1];
    let projectLang = i18n.locale;
    if (projectLang) lang = projectLang;
    return new Promise((resolve, reject) => {
        if (!this._vm.$user.hasPermission(command)){
            let msg = i18n.t("使用者無開通「X」API權限", [command]);
            this._vm.$eventBus.$emit('showAlert', msg);
            reject(msg);
        }
        api.xsa.post(data.path, data.data).then(res => {
            if (res.data.status === '1'){
                resolve(res.data.result);
            } else {
                if (res.data.message) reject(res.data.message);
                else reject(i18n.t(res.data.stateCode));
            }
        }).catch( err => {
            this._vm.$eventBus.$emit('showAlert', err.message);
            reject(err.message);
        });
    });
}
function callAPIForm(data = {path:'/', data: new FormData()} , lang = "zh-TW"){
    let pathArr = data.path.split('/');
    let command = pathArr[pathArr.length - 1];
    let projectLang = i18n.locale;
    if (projectLang) lang = projectLang;
    return new Promise((resolve, reject) => {
        if (!this._vm.$user.hasPermission(command)){
            let msg = i18n.t("使用者無開通「X」API權限", [command]);
            this._vm.$eventBus.$emit('showAlert', msg);
            reject(msg);
        }
        data.data.append("permission", command);
        data.data.append("lang", lang);
        // console.log (api);
        api.xsa.form(data.path, data.data).then(res => {
            if (res.data.status === '1'){
                resolve(res.data.result);
            } else {
                if (res.data.message) reject(res.data.message);
                else reject(i18n.t(res.data.stateCode));
            }
        }).catch( err => {
            this._vm.$eventBus.$emit('showAlert', err.message);
            reject(err.message);
        });
    });
}

export default {
    namespaced: true,
    actions,
}