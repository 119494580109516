import Vue from "vue";
import api from "@/assets/js/app-api";
import moment from "moment";
import { saveTxt } from "@/assets/js/Tool/exportCSV.js";
import {
    訊息系統,
    ChatMsgType,
    ActionType,
    檢查事件狀態,
    ProcessStatus,
    // 同步時間間隔,
    // 斷線重連參數,
    取得客服Account,
    監測異常模組,
    MessageForm,
    ProjectSetting,
    DefSampleRate,
    InvokeCmd,
} from "@/store/MessageSystem/messageSystemConst";
import {
    WriteLog,
    ReadLog,
} from "@/store/MessageSystem/messageSystemLog";

import { 移除特殊字元, 移除特殊字元List } from "@/assets/js/Tool/parseStr";
import { 關鍵字特殊處理Function } from "@/views/projects/ChannelMonitor/ChannelMonitorLib";
import { parseImgUrl } from "@/assets/js/MessageSystem/parseImgUrl";
const MessageSystemServer = {
    myWorker: null,
    projectId: "",
    //Build----初始化
    async build(url, token/*,store*/, { commit, state }, eventBus, projectId) {
        if (process.env.VUE_APP_DEBUG === 'true') {
            await ReadLog.load(this);
            commit('ServerIsOnline', 'Connected');//Connected
            return;
        }

        let me = this;
        if (window.Worker) {
            if (this.myWorker == null) {
                this.myWorker = new Worker("web_worker/worker.js?" + process.env.BuildTime);
                this.projectId = projectId;
                this.myWorker.onmessage = function (e) {
                    // console.log("WS", JSON.stringify(e.data));
                    switch (e.data.type) {
                        case 'ServerOn':
                            if (me[e.data.cmd]) {
                                me[e.data.cmd](commit, state, e.data.arg);
                            } else {
                                console.error("收到不合法的cmd:" + e.data.cmd)
                            }
                            break;
                        case 'ServerOnClose':
                            me.OnClose(commit, state, e.data.arg);
                            break;
                        case 'Commit':
                            commit(e.data.cmd, e.data.arg);
                            if (e.data.cmd == "ServerIsOnline" && e.data.arg == "Connected") {
                                //這個判斷是，webworker跟server那邊第一次連線成功後，這裡會執行
                                me.Refresh();//
                            }
                            break;
                        case 'ShowAlert':
                            if (eventBus) {
                                eventBus.$emit("showAsyncErrorCodeAlert", e.data.arg.ErrCode, e.data.arg);
                                // eventBus.$emit("showAlert", e.data.arg);
                            }
                            break;
                    }
                }
            }
        } else {
            console.error('Your browser doesn\'t support web workers.');
        }
        this.PostMessage({ cmd: 'build', arg: { url, token } });
    },
    PostMessage(postData) {
        if (this.myWorker) {
            WriteLog.add({ e: "send", detail: postData });
            this.myWorker.postMessage(postData);
        }
    },
    Refresh() {
        let MessageSystemEnable = ProjectSetting.MessageSystemEnable(this.projectId);
        let ChannelManagerEnable = ProjectSetting.ChannelManagerEnable(this.projectId);
        if (MessageSystemEnable) {
            this.PostMessage({ cmd: 'invoke', arg: [InvokeCmd.GetOnlineCsList] });
            this.PostMessage({ cmd: 'invoke', arg: [InvokeCmd.GetPlayerList] });
            this.PostMessage({ cmd: 'invoke', arg: [InvokeCmd.GetCsChatEventList] });//取得客服聊天事件列表
        }
        if (ChannelManagerEnable) {
            this.PostMessage({ cmd: 'invoke', arg: [InvokeCmd.GetMonitoringHistoryData] });
            this.PostMessage({ cmd: 'invoke', arg: [InvokeCmd.GetKeywordList] });
        }
    },
    GetBasicDataRes(commit, state, time) {
        WriteLog.add({ e: "rec", detail: { cmd: "GetBasicDataRes", data: JSON.parse(time) } });
        commit('更新線上時間', time);
    },
    GetOnlineCsListRes(commit, state, json) {
        WriteLog.add({ e: "rec", detail: { cmd: "GetOnlineCsListRes", data: JSON.parse(json) } });
        let 線上客服列表 = JSON.parse(json);
        for (let 客服 of 線上客服列表) {
            if (客服.Account == state.CSLoginUser) {
                commit('更新狀態', 客服.AutoDispatchState);
            }
            客服.AccountName = 取得客服Account(state.客服顯示對應表, 客服.Account);
        }
        commit('更新客服列表', 線上客服列表);
    },
    GetPlayerListRes(commit, state, json) {
        let 線上玩家列表 = [];
        let countNum = {};
        try {
            WriteLog.add({ e: "rec", detail: { cmd: "GetPlayerListRes", data: JSON.parse(json) } });
            線上玩家列表 = JSON.parse(json);
        } catch (e) {
            WriteLog.add({ e: "rec", detail: { cmd: "GetPlayerListRes", data: json } });
            // console.log("e", e);//已處理，就不打印了
        }
        線上玩家列表 = 線上玩家列表.filter((item) => {
            return 檢查事件狀態.是否為尚未回應(item) || !item.EventType;
        });
        for (let 玩家 of 線上玩家列表) {
            //統計服務的玩家數量
            if (玩家.Cs != "" && (玩家.ProcessStatus == ProcessStatus.Assigned.value || 玩家.ProcessStatus == ProcessStatus.Processing.value)) {
                countNum[玩家.Cs] = countNum[玩家.Cs] ? countNum[玩家.Cs] + 1 : 1;
            }
            //加上狀態
            玩家.是否出現接手事件按鈕 = 檢查事件狀態.是否出現接手事件按鈕(玩家, state.CSLoginUser, state.線上客服列表);
            //加上客服Name
            玩家.CSName = 取得客服Account(state.客服顯示對應表, 玩家.Cs);
        }
        commit('更新玩家列表', 線上玩家列表);
        commit('更新我的未回數', 計算未回數(線上玩家列表, state.CSLoginUser, state.線上客服列表));
        //計算服務數量
        for (let 客服 of state.線上客服列表) {
            更新線上客服(commit, state, {
                Account: 客服.Account,
                AutoDispatchState: 客服.AutoDispatchState,
                IsServiceVIP: 客服.IsServiceVIP,
                ServiceCount: countNum[客服.Account] ? countNum[客服.Account] : 0,
            });
        }
    },
    UpdateCsList(commit, state, [account, autoDispatch, serviceCount, type, IsServiceVIP, ...rest]) {
        WriteLog.add({ e: "rec", detail: { cmd: "UpdateCsList", data: [account, autoDispatch, serviceCount, type, IsServiceVIP, ...rest] } });
        if (account == state.CSLoginUser) {
            commit('更新狀態', autoDispatch);
            commit('更新IsServiceVIP', IsServiceVIP);
        }
        type = type + "";
        switch (type) {
            case ActionType.Add:
                更新線上客服(commit, state, { Account: account, AutoDispatchState: autoDispatch, IsServiceVIP, ServiceCount: 取得服務數量(state, account) });
                break;
            case ActionType.Update:
                更新線上客服(commit, state, { Account: account, AutoDispatchState: autoDispatch, IsServiceVIP, ServiceCount: 取得服務數量(state, account) });
                break;
            case ActionType.Remove:
                {
                    let index = state.線上客服列表.findIndex((客服) => { return 客服.Account == account });
                    if (index >= 0) {
                        state.線上客服列表.splice(index, 1);
                        commit('更新客服列表', state.線上客服列表);
                    }
                }
                break;
            default: {
                console.error("出錯....", type)
            }
        }
        for (let 玩家 of state.線上玩家列表) {
            //統計服務的玩家數量
            if (玩家.Cs == account) {
                //異動的Cs才更新
                玩家.是否出現接手事件按鈕 = 檢查事件狀態.是否出現接手事件按鈕(玩家, state.CSLoginUser, state.線上客服列表);
            }
        }
        commit('更新玩家列表', state.線上玩家列表);
        commit('更新我的未回數', 計算未回數(state.線上玩家列表, state.CSLoginUser, state.線上客服列表));
    },
    UpdatePlayerList(commit, state, json) {
        WriteLog.add({ e: "rec", detail: { cmd: "UpdatePlayerList", data: JSON.parse(json) } });
        let playerEvent = JSON.parse(json);
        playerEvent.CSName = 取得客服Account(state.客服顯示對應表, playerEvent.Cs);
        playerEvent.是否出現接手事件按鈕 = 檢查事件狀態.是否出現接手事件按鈕(playerEvent, state.CSLoginUser, state.線上客服列表);
        let index = state.線上玩家列表.findIndex((item) => { return item.EventId == playerEvent.EventId; });
        let oldEventCs = '';
        if (index >= 0) {
            oldEventCs = state.線上玩家列表[index].Cs;
            state.線上玩家列表.splice(index, 1, playerEvent);
        } else {
            state.線上玩家列表.push(playerEvent);
        }
        commit('更新玩家列表', state.線上玩家列表);
        commit('更新我的未回數', 計算未回數(state.線上玩家列表, state.CSLoginUser, state.線上客服列表));
        if (playerEvent.Cs) {
            let findCs = state.線上客服列表.find((item) => { return item.Account == playerEvent.Cs });
            if (findCs) {
                更新線上客服(commit, state, {
                    Account: playerEvent.Cs,
                    AutoDispatchState: findCs.AutoDispatchState,
                    IsServiceVIP: findCs.IsServiceVIP,
                    ServiceCount: 取得服務數量(state, playerEvent.Cs)
                });
            }
        }
        if (oldEventCs && playerEvent.Cs != oldEventCs) {
            let findCs = state.線上客服列表.find((item) => { return item.Account == oldEventCs });
            if (findCs) {
                更新線上客服(commit, state, {
                    Account: oldEventCs, AutoDispatchState: findCs.AutoDispatchState,
                    IsServiceVIP: findCs.IsServiceVIP,
                    ServiceCount: 取得服務數量(state, oldEventCs)
                });
            }
        }
    },
    ReceiveChatMessage(commit, state, { player, message, ChatMsgType, time, ChatId, Sender, EventId }) {
        WriteLog.add({ e: "rec", detail: { cmd: "ReceiveChatMessage", data: { player, message, ChatMsgType, time, ChatId, Sender, EventId } } });
        Sender = ChatMsgType == 1 ? Sender : player;
        let SenderName = ChatMsgType == 1 ? 取得客服Account(state.客服顯示對應表, Sender) : player;
        commit('收到會員聊天訊息', {
            target: player,
            messages: [{ Msg: message, ChatMsgType, Time: time, Sender, SenderName, ChatId, EventId, firstEvent: false }]
        })
    },
    UpdateAccountData(commit, state, [autoDispatch, IsServiceVIP, ...rest]) {
        WriteLog.add({ e: "rec", detail: { cmd: "UpdateAccountData", data: [autoDispatch, IsServiceVIP, ...rest] } });
        commit('更新狀態', autoDispatch);
        commit('更新IsServiceVIP', IsServiceVIP);
    },
    GetHistoryMsgRes(commit, state, json) {
        WriteLog.add({ e: "rec", detail: { cmd: "GetHistoryMsgRes", data: JSON.parse(json) } });
        let data = JSON.parse(json);
        let addList = [];

        for (let i of data.MsgArr) {
            let SenderName = i.ChatMsgType == 1 ? 取得客服Account(state.客服顯示對應表, i.Sender) : i.Sender;
            addList.push({ Msg: i.Msg, ChatMsgType: i.ChatMsgType, Time: i.Time, Sender: i.Sender, SenderName, ChatId: i.ChatId, EventId: i.EventId, firstEvent: false });
        }
        commit('收到會員聊天訊息', { target: data.Nick, messages: addList, token: data.NextToken ? data.NextToken : "無" });
    },
    GetCsChatEventListRes(commit, state, json) {
        WriteLog.add({ e: "rec", detail: { cmd: "GetCsChatEventListRes", data: JSON.parse(json) } });
        let data = JSON.parse(json);
        let group = {
            EventId: data.Group.EventId,
            EventRelatedCs: data.Group.EventRelatedCs,
            LastMsg: {
                Sender: data.Group.LastMsg ? data.Group.LastMsg.Sender : "",
                ChatId: data.Group.LastMsg ? data.Group.LastMsg.ChatId : "",
                EventId: data.Group.LastMsg ? data.Group.LastMsg.EventId : "",
                Msg: data.Group.LastMsg ? data.Group.LastMsg.Msg : "",
                Time: data.Group.LastMsg ? data.Group.LastMsg.Time : state.Server時間,
                ChatMsgType: data.Group.LastMsg ? data.Group.LastMsg.ChatMsgType : 6
            }
        };
        commit('更新CsChatEventGroup', group);
        //加CS中文姓名
        if (data.ChatData) {
            for (let chatData of data.ChatData) {
                chatData.EventRelatedCsName = 取得客服Account(state.客服顯示對應表, chatData.EventRelatedCs);
            }
        } else {
            data.ChatData = [];
        }
        commit('更新CsChatEventList', data.ChatData);
    },
    UpdeteCsChatEventList(commit, state, json) {
        WriteLog.add({ e: "rec", detail: { cmd: "UpdeteCsChatEventList", data: JSON.parse(json) } });
        let data = JSON.parse(json);
        //1.更新CsChatEvent Group或List
        if (data.CsChatEventId == "group") {
            //自己收送群組，這裡會收到兩次，一次OwnToGroup；一次GroupToOwn，這裡只收GroupToOwn
            if (data.LastMsg.ChatMsgType == ChatMsgType.OwnToGroup) {
                //不處理
            } else {
                commit('更新CsChatEventGroup', {
                    EventId: data.CsChatEventId,
                    EventRelatedCs: data.EventRelatedCs,
                    LastMsg: {
                        Sender: data.LastMsg.Sender,
                        ChatId: data.LastMsg.ChatId,
                        EventId: data.LastMsg.EventId,
                        Msg: data.LastMsg.Msg,
                        Time: data.LastMsg.Time,
                        ChatMsgType: data.LastMsg.ChatMsgType,
                    }
                });
            }
        } else {
            let index = state.CsChatEventList.findIndex((item) => { return item.EventId == data.CsChatEventId })
            if (index >= 0) {
                let obj = {
                    EventId: data.CsChatEventId,
                    EventRelatedCs: data.EventRelatedCs,
                    EventRelatedCsName: 取得客服Account(state.客服顯示對應表, data.EventRelatedCs),
                    LastMsg: {
                        Sender: data.LastMsg.Sender,
                        ChatId: data.LastMsg.ChatId,
                        EventId: data.LastMsg.EventId,
                        Msg: data.LastMsg.Msg,
                        Time: data.LastMsg.Time,
                        ChatMsgType: data.LastMsg.ChatMsgType
                    }
                };
                state.CsChatEventList.splice(index, 1);//刪除
                state.CsChatEventList.unshift(obj);
            } else {
                data.EventRelatedCsName = 取得客服Account(state.客服顯示對應表, data.EventRelatedCs);
                state.CsChatEventList.unshift({
                    EventId: data.CsChatEventId,
                    EventRelatedCs: data.EventRelatedCs,
                    EventRelatedCsName: 取得客服Account(state.客服顯示對應表, data.EventRelatedCs),
                    LastMsg: {
                        Sender: data.LastMsg.Sender,
                        ChatId: data.LastMsg.ChatId,
                        EventId: data.LastMsg.EventId,
                        Msg: data.LastMsg.Msg,
                        Time: data.LastMsg.Time,
                        ChatMsgType: data.LastMsg.ChatMsgType
                    }
                });
            }
            commit('更新CsChatEventList', state.CsChatEventList);
        }
        // 2.更新訊息
        if (data.LastMsg.ChatMsgType == ChatMsgType.GroupToOwn && data.LastMsg.Sender == state.CSLoginUser) {
            //不處理
        } else {
            commit('收到客服聊天訊息', {
                target: data.EventRelatedCs,
                messages: [{
                    Msg: data.LastMsg.Msg,
                    ChatMsgType: data.LastMsg.ChatMsgType,
                    Time: data.LastMsg.Time,
                    Sender: data.LastMsg.Sender,
                    SenderName: 取得客服Account(state.客服顯示對應表, data.LastMsg.Sender),
                    ChatId: data.LastMsg.ChatId,
                    EventId: data.LastMsg.EventId,
                    firstEvent: false,
                    MsgColor: data.LastMsg.MsgColor,
                }]
            });

            //3.更新未讀訊息數
            if (state.聊天客服對象 != data.EventRelatedCs) {
                state.ChatCsUnRead.push(data.EventRelatedCs);
                commit('更新客服未讀訊息', state.ChatCsUnRead);
            }
        }
    },
    GetCsHistoryMsgRes(commit, state, json) {
        WriteLog.add({ e: "rec", detail: { cmd: "GetCsHistoryMsgRes", data: JSON.parse(json) } });
        let data = JSON.parse(json);
        let addList = [];
        if (data.CsChat) {
            for (let i of data.CsChat) {
                addList.push({
                    Msg: i.Msg,
                    ChatMsgType: i.ChatMsgType,
                    Time: i.Time,
                    Sender: i.Sender,
                    SenderName: 取得客服Account(state.客服顯示對應表, i.Sender),
                    ChatId: i.ChatId,
                    EventId: i.EventId,
                    firstEvent: false,
                    MsgColor: i.MsgColor
                });
            }
        }
        commit('收到客服聊天訊息', {
            target: data.Cs,
            messages: addList,
            token: data.NextToken ? data.NextToken : "無",
        });
    },
    CsPrivateMsgReport(commit, state, json) {
        WriteLog.add({ e: "rec", detail: { cmd: "CsPrivateMsgReport", data: JSON.parse(json) } });
        json = JSON.parse(json);
        commit('收到密語回覆', json);
        // this._vm.$eventBus.$emit("showAlert", JSON.parse(acc));
    },
    AddMonitoringMsg(commit, state, json) {
        WriteLog.add({ e: "rec", detail: { cmd: "AddMonitoringMsg", data: JSON.parse(json) } });
        json = JSON.parse(json);
        commit('收到監頻訊息', json);
    },
    GetKeywordListRes(commit, state, json) {
        WriteLog.add({ e: "rec", detail: { cmd: "GetKeywordListRes", data: JSON.parse(json) } });
        json = JSON.parse(json);
        commit("更新關鍵字_公開", json && json.PublicKeywordData || []);
        commit("更新關鍵字_密語", json && json.PrivateKeywordData || []);
    },
    GetMonitoringHistoryDataRes(commit, state, json) {
        WriteLog.add({ e: "rec", detail: { cmd: "GetMonitoringHistoryDataRes", data: JSON.parse(json) } });
        json = JSON.parse(json);
        for (let i of json) {
            commit('收到監頻訊息', i);
        }

        // let t = 0;
        // setInterval(() => {
        //     console.error("enter....")
        //     t++;
        //     let ran = Math.floor(Math.random() * 3);
        //     commit('收到監頻訊息', {
        //         "SendId": "6660104cfe7468131abc0466" + t,
        //         "Sender": "麻將新手0000401",
        //         "Receiver": null,
        //         "Content": ran == 0 ? "https://storage.googleapis.com/centralcontrolstoragetest/PlayerData/1008367/ChatPhoto/Picture4.png" :
        //             "https://storage.googleapis.com/centralcontrolstoragetest/PlayerData/1006952/ChatPhoto/Picture1.png",
        //         "SendTime": 1717571688000,
        //         "ServiceNo": null,
        //         "MsgType": ran == 2 ? 1 : 2,
        //         "ChannelType": 1,
        //         "Channel": "公開頻道"
        //     });
        // }, 5000)
    },
    StopConnect(commit, state, data) {
        //斷線處理會由WebWorker處理，這邊不作任何事
        WriteLog.add({ e: "rec", detail: { cmd: "StopConnect", data } });
    },
    ErrorMsg(commit, state, { msg, code }) {
        WriteLog.add({ e: "rec", detail: { cmd: "ErrorMsg", data: { msg, code } } });
        commit('ErrorMsg', { msg, code });
    },
    OnClose(commit, state, error) {
        //斷線處理會由WebWorker處理，這邊不作任何事
        WriteLog.add({ e: "rec", detail: { cmd: "OnClose", data: error } });
    },
}
function 取得服務數量({ 線上玩家列表 }, CS) {
    let count = 0;
    for (let 玩家 of 線上玩家列表) {
        //統計服務的玩家數量
        if (玩家.Cs == CS && (玩家.ProcessStatus == ProcessStatus.Assigned.value || 玩家.ProcessStatus == ProcessStatus.Processing.value)) {
            count++;
        }
    }
    return count;
}
function 更新線上客服(commit, state, { Account, AutoDispatchState, ServiceCount, IsServiceVIP }) {
    let find = state.線上客服列表.find((客服) => { return 客服.Account == Account })
    if (find) {
        find.AutoDispatchState = AutoDispatchState;
        find.IsServiceVIP = IsServiceVIP;
        find.ServiceCount = ServiceCount;
    } else {
        state.線上客服列表.push({
            Account,
            AccountName: 取得客服Account(state.客服顯示對應表, Account),
            AutoDispatchState,
            IsServiceVIP,
            ServiceCount
        })
    }
    commit('更新客服列表', state.線上客服列表);
}
function 計算未回數(線上玩家列表, CSLoginUser, 線上客服列表) {
    let 我的未回數 = 0;
    let 總未回數 = 0;
    for (let item of 線上玩家列表) {
        if (檢查事件狀態.是否為尚未回應(item)) {
            if (item.Cs == CSLoginUser && item.NoReturnCount != 0) {
                我的未回數 = 我的未回數 + 1;
            }
            if (item.Cs == "" || !客服是否在線上(item.Cs, 線上客服列表)) {
                總未回數 = 總未回數 + 1;
            }
        }
    }
    return {
        我的未回數,//我尚未回應的事件數量，也就是NoReturnCount為0的不算
        總未回數//離線，或未派發的事件數量
    };
}
function 客服是否在線上(cs, 線上客服列表) {
    let find = 線上客服列表.find((item) => { return item.Account == cs });
    return find ? true : false;
}
function 檢查是否能登入客服系統(要登入的projectId) {
    if (!ProjectSetting.AllowCSSystemProjects().includes(要登入的projectId)) {
        return false;//要登入的project不含在登入列表中
    }
    return true;
}
const state = {
    客服顯示對應表: [],
    線上客服列表: [],//{"julie":{"Account":"julie","AutoDispatchState":false,"ServiceCount":0}
    線上玩家列表: [],
    autoDispatch: 0,//是否自動派發
    IsServiceVIP: false,//是否服務VIP
    CSLoginUser: "",//登入者id,
    ProjectId: "",//登入專案
    CSToken: "",//登入者token
    ServerIsOnline: "Disconnected",//與Server連線狀況：//Connected、Connecting、Disconnected、Disconnecting、Reconnecting	
    聊天會員對象: "",
    聊天會員訊息暫存: {

    },
    聊天客服對象: "",
    聊天客服訊息暫存: {

    },
    Server時間: new Date().getTime(),
    Client時間: new Date().getTime(),
    我的未回數: 0,
    總未回數: 0,
    CsChatEventList: [],
    ChatCsUnRead: [],//CS未讀數
    GroupChatEvent: {
        EventId: "",
        EventRelatedCs: "",
        LastMsg: {
            Sender: "",
            ChatId: "",
            EventId: "",
            Msg: "",
            Time: "",
            ChatMsgType: 4
        }
    },
    最後一次刷新時間: Math.floor(new Date().getTime() / 1000),
    異常狀態: false,
    messageForm: MessageForm.Chat,//,或chat
    CSHistory: [],//取代 ChatCsMessage、
    ChatHistory: [],//取代 ChatMessage,
    csColor: "#000000",
    分類EventId: "",
    強制分類EventId: "",
    監頻訊息_公開: [],
    監頻訊息_密語: [],
    關鍵字_公開: "",
    關鍵字_密語: "",

    是否要通知Server斷掉其它Client: false,//
    SampleRate: DefSampleRate,
}
// getters
const getters = {
    ChatMessage(state) {
        let find = state.ChatHistory.find((item) => { return item.target == state.聊天會員對象 })
        let list = find ? find.messages : [];
        for (let i of list) {
            i.img = parseImgUrl(i.Msg);//i.Msg.indexOf("https://storage.xin-stars.com") == 0 ? i.Msg : "";
        }
        return list;
    },
    ChatCsMessage(state) {
        let find = state.CSHistory.find((item) => { return item.target == state.聊天客服對象 });
        return find ? find.messages : [];
    },
    IsAllowCsProject(state) {
        return ProjectSetting.AllowCSSystemProjects().includes(state.ProjectId);
    },
    固定事件類型(state) {
        return ProjectSetting.固定事件類型(state.ProjectId);
    },
    計算玩家列表(state) {
        let list = JSON.parse(JSON.stringify(state.線上玩家列表));
        //加屬性：服務的客服是否仍在線上
        let 現在時間 = new Date().getTime() - state.Client時間 + state.Server時間;//todo:記得移到頁面
        for (let 玩家 of list) {
            let find = state.線上客服列表.find((item) => { return item.Account == 玩家.Cs });
            Vue.set(玩家, "客服online", find ? true : false);
            Vue.set(玩家, "是否顯示未讀數", 檢查事件狀態.是否顯示未讀數(玩家));
            Vue.set(玩家, "倒數時間或最後時間", 檢查事件狀態.顯示倒數時間或最後時間(
                玩家,
                現在時間,
                moment
            ));


            Vue.set(玩家, "聊天視窗_分類和完成事件", 檢查事件狀態.聊天視窗_分類和完成事件(玩家, state.CSLoginUser));
            Vue.set(玩家, "聊天視窗_接手事件按鈕", 檢查事件狀態.是否出現接手事件按鈕(玩家, state.CSLoginUser, state.線上客服列表));
            Vue.set(玩家, "事件狀態是否為已結束", 檢查事件狀態.按鈕已結束(玩家));

            let 顯示客服名稱 = 玩家.事件狀態是否為已結束 || 玩家.客服online;
            Vue.set(玩家, "顯示客服名稱", 顯示客服名稱);

            Vue.set(玩家, "聊天視窗_強制分類和完成事件", 檢查事件狀態.聊天視窗_強制分類和完成事件(玩家, state.CSLoginUser));
            Vue.set(玩家, "強制接手權限", 檢查事件狀態.是否出現強制接手事件按鈕(玩家, state.CSLoginUser, state.線上客服列表));
            // console.error(JSON.stringify(玩家));
        }
        return list;
    },
}

// actions
const actions = {
    async 載入客服顯示對應表({ commit, state }, arg) {
        let csData = [];
        try {
            csData = (await api.CS.GetCsDataList(arg)).data;
            if (csData == "") {
                csData = [];
            }
        } catch (e) {
            console.error(e);
        }
        commit('更新客服顯示對應表', csData);
    },
    async Login(context, { projectId }) {
        WriteLog.clear();
        try {
            if (檢查是否能登入客服系統(projectId)) {
                let MessageSystemEnable = ProjectSetting.MessageSystemEnable(projectId);
                WriteLog.add({ e: "action", detail: { cmd: "Login", data: "處理:" + this.state.loginUser.userid } });
                if (MessageSystemEnable) {
                    await this.dispatch('messageSystemServer/載入客服顯示對應表', { ServiceNo: projectId });
                }
                let CSTokenRes = "";

                CSTokenRes = await api.CS.Login({ ProjectId: projectId }).catch((e) => {
                    throw "與客服系統伺服器登入失敗，詳細訊息：", e;
                });
                let CSToken = CSTokenRes.data;
                WriteLog.add({ e: "log", detail: "交換token回傳:" + CSToken });
                context.commit('UpdateCSLoginUser', this.state.loginUser.userid);
                context.commit('UpdateProjectId', projectId);
                context.commit('UpdateCSToken', CSToken);
                MessageSystemServer.build(process.env.VUE_APP_CS_SYSTEM_PATH + `/signalRHub?sn=${projectId}`, state.CSToken, context, this._vm.$eventBus, projectId);

                if (MessageSystemEnable) {
                    //處理客服顏色
                    let CSColorList = {};
                    let csColor = "#000000";
                    try {
                        CSColorList = JSON.parse(localStorage.getItem('CSColorList'));
                        csColor = CSColorList[this.state.loginUser.userid];
                        if (!csColor) {
                            csColor = "#000000";
                        }
                    } catch (e) {
                        csColor = "#000000";
                    }
                    context.commit('UpdateCSColor', csColor);
                }
                context.commit('UpdateSampleRate', ProjectSetting.SampleRate(projectId));
            }
        } catch (e) {
            alert(e);
        }
    },
    載入歷史訊息({ state }, _訊息系統) {
        let historyAll = state.ChatHistory;
        let state聊天對象 = state.聊天會員對象;
        let invoke = [InvokeCmd.GetPlayerHistoryMsg, "", state.聊天會員對象];
        if (_訊息系統 == 訊息系統.客服系統) {
            historyAll = state.CSHistory;
            state聊天對象 = state.聊天客服對象;
            invoke = [InvokeCmd.GetCsHistoryMsg, state.聊天客服對象];
        }
        let find = historyAll.find((item) => { return item.target == state聊天對象 });
        let token = find ? find.token : "";
        invoke.push(token);
        if (token == "無" || token == "等待回傳" || !state聊天對象) {
            return false;
        }
        if (find) {
            find.token = "等待回傳";
        } else {
            historyAll.push({
                target: state聊天對象,
                token: "等待回傳",
                messages: []
            });
        }
        MessageSystemServer.PostMessage({ cmd: 'invoke', arg: invoke });
        return true;
    },
    開始聊天({ commit, state }, { 聊天對象, 訊息系統: _訊息系統 }) {
        let state聊天對象 = state.聊天會員對象;
        let commit更新聊天對象 = '更新聊天會員對象';
        if (_訊息系統 == 訊息系統.客服系統) {
            state聊天對象 = state.聊天客服對象;
            commit更新聊天對象 = '更新聊天客服對象';
            commit('更新客服未讀訊息', state.ChatCsUnRead.filter((item) => { return item != 聊天對象 }));
        }
        if (聊天對象 == state聊天對象) {
            return;
        }
        commit(commit更新聊天對象, 聊天對象);
        if (聊天對象 != "") {
            this.dispatch('messageSystemServer/載入歷史訊息', _訊息系統);
        }
    },
    更新暫存聊天訊息({ commit }, { 聊天對象, 訊息系統: _訊息系統, 訊息 }) {
        let commit更新暫存聊天訊息 = '更新會員暫存聊天訊息';
        if (_訊息系統 == 訊息系統.客服系統) {
            commit更新暫存聊天訊息 = '更新客服暫存聊天訊息';
        }
        commit(commit更新暫存聊天訊息, { 聊天對象, 訊息 });
    },
    刷新畫面({ commit, state }) {
        let logData = WriteLog.download();
        saveTxt(state.CSLoginUser + "-" + new moment().format("YYYY-MM-DD HH:mm:ss"), logData);
        MessageSystemServer.Refresh();
    },
    invoke({ commit }, args) {
        MessageSystemServer.PostMessage({ cmd: 'invoke', arg: args });
    },
    是否要通知Server斷掉其它Client({ commit }, bol) {
        commit('是否要通知Server斷掉其它Client', bol);
    },
    stop({ commit, state }, args) {
        MessageSystemServer.PostMessage({ cmd: 'stop', arg: state.是否要通知Server斷掉其它Client });//呼叫WebWorker，斷線
        commit('是否要通知Server斷掉其它Client', false);
    },
    ReadLog({ commit, state }) {
        if (process.env.VUE_APP_DEBUG === 'true') {
            ReadLog.read({ commit, state });
        }
    },
    ChangeMessageForm({ commit }, messageForm) {
        commit('messageForm', messageForm);
    },
}



// mutations
const mutations = {
    更新客服顯示對應表(state, 客服顯示對應表) {
        state.客服顯示對應表 = 客服顯示對應表;
    },
    UpdateCSToken(state, CSToken) {
        state.CSToken = CSToken;
    },
    UpdateCSLoginUser(state, CSLoginUser) {
        state.CSLoginUser = CSLoginUser;
    },
    UpdateProjectId(state, ProjectId) {
        state.ProjectId = ProjectId;
    },
    ServerIsOnline(state, ServerIsOnline) {
        WriteLog.add({ e: "log-state-ServerIsOnline", detail: ServerIsOnline });
        state.ServerIsOnline = ServerIsOnline;
    },
    更新聊天會員對象(state, 聊天會員對象) {
        state.聊天會員對象 = 聊天會員對象;
        if (聊天會員對象 && !state.聊天會員訊息暫存[聊天會員對象]) {
            Vue.set(state.聊天會員訊息暫存, 聊天會員對象, "");
        }
        WriteLog.add({ e: "log-state-更新聊天會員對象", detail: 聊天會員對象 });
    },
    更新會員暫存聊天訊息(state, { 聊天對象, 訊息 }) {
        if (聊天對象) {
            Vue.set(state.聊天會員訊息暫存, 聊天對象, 訊息);
        }
    },
    更新聊天客服對象(state, 聊天客服對象) {
        state.聊天客服對象 = 聊天客服對象;
        if (聊天客服對象 && !state.聊天客服訊息暫存[聊天客服對象]) {
            Vue.set(state.聊天客服訊息暫存, 聊天客服對象, "");
        }
        WriteLog.add({ e: "log-state-更新聊天客服對象", detail: 聊天客服對象 });
    },
    更新客服暫存聊天訊息(state, { 聊天對象, 訊息 }) {
        if (聊天對象) {
            Vue.set(state.聊天客服訊息暫存, 聊天對象, 訊息);
        }
    },
    更新客服列表(state, 線上客服列表) {
        state.線上客服列表 = 線上客服列表;
        let 異常 = 監測異常模組.線上客服列表(線上客服列表, state.CSLoginUser);
        if (異常) {
            state.異常狀態 = true;
        }
        WriteLog.add({ e: "log-state-更新客服列表", detail: JSON.stringify(線上客服列表) });
    },
    更新玩家列表(state, 線上玩家列表) {
        state.線上玩家列表 = 線上玩家列表;
        let 異常 = 監測異常模組.線上玩家列表(線上玩家列表);
        if (異常) {
            state.異常狀態 = true;
        }
        WriteLog.add({ e: "log-state-更新玩家列表", detail: JSON.stringify(state.線上玩家列表) });
    },
    收到會員聊天訊息(state, { target, messages, token }) {
        let index = state.ChatHistory.findIndex((item) => { return item.target == target });
        if (index == -1) {
            state.ChatHistory.push({
                target,
                token: "",
                messages: []
            });
            index = state.ChatHistory.length - 1;
        }

        if (messages != undefined) {
            //合併
            messages = [...state.ChatHistory[index].messages, ...messages];
            //排序
            messages.sort((a, b) => {
                return a.Time - b.Time;
            });
            //過瀘
            let result = [];
            messages.filter((item) => {
                if (!(result.find((item2) => { return item2.ChatId == item.ChatId }))) {
                    result.push(item)
                }
            });

            let firstEventId = "";
            for (let i of result) {
                i.firstEvent = firstEventId != i.EventId;//是否為此事件的第一資資料
                firstEventId = i.EventId;
            }
            state.ChatHistory[index].messages = result;
        }
        if (token !== undefined) {
            state.ChatHistory[index].token = token;
        }
        WriteLog.add({ e: "log-state-收到會員聊天訊息", detail: JSON.stringify(state.ChatHistory) });
    },
    收到客服聊天訊息(state, { target, messages, token }) {
        let index = state.CSHistory.findIndex((item) => { return item.target == target });
        if (index == -1) {
            state.CSHistory.push({
                target,
                token: "",
                messages: []
            });
            index = state.CSHistory.length - 1;
        }
        if (messages !== undefined) {
            //合併
            messages = [...state.CSHistory[index].messages, ...messages];
            //排序
            messages.sort((a, b) => {
                return a.Time - b.Time;
            });
            //過瀘
            let result = [];
            messages.filter((item) => {
                if (!(result.find((item2) => { return item2.ChatId == item.ChatId }))) {
                    result.push(item)
                }
            });
            state.CSHistory[index].messages = result;
        }
        if (token !== undefined) {
            state.CSHistory[index].token = token;
        }
        WriteLog.add({ e: "log-state-收到客服聊天訊息", detail: JSON.stringify(state.CSHistory) });
    },
    更新關鍵字_公開(state, 關鍵字列表) {
        關鍵字列表 = 移除特殊字元List(關鍵字列表, 關鍵字特殊處理Function);//理論上新增時移除掉了，但為了相容性，這裡再檢查一次
        state.關鍵字_公開 = 關鍵字列表.join("|");
    },
    更新關鍵字_密語(state, 關鍵字列表) {
        關鍵字列表 = 移除特殊字元List(關鍵字列表, 關鍵字特殊處理Function);//理論上新增時移除掉了，但為了相容性，這裡再檢查一次
        state.關鍵字_密語 = 關鍵字列表.join("|");
    },
    收到監頻訊息(state, msg) {
        Vue.set(msg, "時間", new moment(msg.SendTime).format("MM/DD HH:mm"));
        Vue.set(msg, "移除特殊符號Content", 移除特殊字元(msg.Content, 關鍵字特殊處理Function));
        let {
            publicTypes,
            // privateTypes,
        } = ProjectSetting.ChannelTypes(state.ProjectId);
        let list = publicTypes.includes(msg.ChannelType) ? state.監頻訊息_公開 : state.監頻訊息_密語;
        let isRepeat = list.find((item) => { return item.SendId == msg.SendId });
        if (!isRepeat) {
            list.push(msg);
        }
    },
    收到密語回覆(state, json) {
        this._vm.$eventBus.$emit("on密語回覆", json);
    },
    更新客服未讀訊息(state, target) {
        state.ChatCsUnRead = target;
        WriteLog.add({ e: "log-state-更新客服未讀訊息", detail: JSON.stringify(state.ChatCsUnRead) });
    },
    更新狀態(state, autoDispatch) {
        state.autoDispatch = autoDispatch;
        WriteLog.add({ e: "log-state-更新狀態", detail: JSON.stringify(state.autoDispatch) });
    },
    更新IsServiceVIP(state, IsServiceVIP) {
        state.IsServiceVIP = IsServiceVIP === true ? true : false;
        WriteLog.add({ e: "log-state-更新IsServiceVIP", detail: JSON.stringify(state.IsServiceVIP) });
    },
    更新線上時間(state, serverTime) {
        // console.log("state 更新線上時間=>", serverTime);
        state.Client時間 = new Date().getTime();
        state.Server時間 = parseInt(serverTime);
    },
    更新我的未回數(state, sum) {
        state.我的未回數 = sum.我的未回數;
        state.總未回數 = sum.總未回數;
        WriteLog.add({ e: "log-state-更新我的未回數", detail: JSON.stringify(sum) });
    },
    更新CsChatEventGroup(state, group) {
        state.GroupChatEvent = group;
        WriteLog.add({ e: "log-state-更新CsChatEventGroup", detail: JSON.stringify(group) });
    },
    更新CsChatEventList(state, ChatData) {
        state.CsChatEventList = ChatData;
        WriteLog.add({ e: "log-state-更新CsChatEventList", detail: JSON.stringify(ChatData) });
    },
    ErrorMsg(state, { msg, code }) {
        try {
            // let errMsgObj = JSON.parse(errCode);
            if (!code) {
                this._vm.$eventBus.$emit("showAlert", msg);
            } else {
                if (code == "9999") {
                    this._vm.$eventBus.$emit("showAlert", msg);
                } else {
                    this._vm.$eventBus.$emit("showAsyncErrorCodeAlert", `MSErrCode-${code}`);
                }
            }
        } catch (e) {
            this._vm.$eventBus.$emit("showAlert", "UnKnow:", code);
        }
        WriteLog.add({ e: "log-state-ErrorMsg", detail: { msg, code } });
    },
    更新最後一次刷新時間(state, timestamp) {
        state.最後一次刷新時間 = timestamp;
        state.異常狀態 = false;
        WriteLog.add({ e: "log-state-最後一次刷新時間", detail: timestamp });
    },
    斷線清空(state, ServerIsOnline) {
        //state.客服顯示對應表=[];
        state.線上客服列表 = [];
        state.線上玩家列表 = [];
        state.autoDispatch = false;//是否自動派發
        state.ServerIsOnline = ServerIsOnline;//Server連線狀態
        state.聊天會員對象 = "";//聊天會員對象帳號
        state.聊天客服對象 = "";//聊天會員客服帳號
        state.我的未回數 = 0;
        state.總未回數 = 0;
        state.CsChatEventList = [];
        state.ChatCsUnRead = [];//CS未讀數
        state.GroupChatEvent = {
            EventId: "",
            EventRelatedCs: "",
            LastMsg: {
                Sender: "",
                ChatId: "",
                EventId: "",
                Msg: "",
                Time: "",
                ChatMsgType: 4
            }
        };
        state.ChatHistory = [];
        state.CSHistory = [];
        // state.CSLoginUser = "";//登入者id
        // state.ProjectId = "";//登入專案 待確認
        // state.CSToken = "";//登入者token
        state.監頻訊息_公開 = [];
        state.監頻訊息_密語 = [];

        state.是否要通知Server斷掉其它Client = false;
        WriteLog.add({ e: "log-state-斷線清空", detail: "" });
    },
    messageForm(state, messageForm) {
        state.messageForm = messageForm;
    },
    UpdateCSColor(state, csColor) {
        state.csColor = csColor;
        {
            //回存localStorage
            let CSColorList = {};
            try {
                CSColorList = JSON.parse(localStorage.getItem('CSColorList'));
                CSColorList[this.state.loginUser.userid] = csColor;
            } catch (e) {
                CSColorList = {};
                CSColorList[this.state.loginUser.userid] = csColor;
            } finally {
                localStorage.setItem('CSColorList', JSON.stringify(CSColorList));
            }
        }
    },
    UpdateSampleRate(state, SampleRate) {
        state.SampleRate = SampleRate;
    },
    Update分類EventId(state, EventId) {
        state.分類EventId = EventId;
    },
    Update強制分類EventId(state, EventId) {
        state.強制分類EventId = EventId;
    },
    是否要通知Server斷掉其它Client(state, bol) {
        state.是否要通知Server斷掉其它Client = bol;
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}