<template>
  <div id="AnnouncementList">
    <section class="content-header">
      <h1>APP推播管理</h1>
      <ol class="breadcrumb">
        <li><router-link to="/">Home</router-link></li>
        <li class="active">APP推播管理</li>
      </ol>
    </section>
    <section class="content customer-service">
      <div class="box box-primary">
        <!-- <pre>{{ searchData }}</pre> -->
          <!-- 活動公告設定 -->
          <div class="box-body">
            <div class="top-box">
              <button type="button" @click="addNew()" class="btn btn-block btn-warning btn-add fa fa-plus"> 新增推播</button>
              
              <div class="form-group pull-left m-0">
                <div class="input-group mb-10 mr-10">
                  <div class="input-group-addon fa fa-calendar"></div>
                  <DateRangePicker
                    :propEndDate.sync="searchData.結束時間"
                    :propStartDate.sync="searchData.開始時間"
                    displayFormat="YYYY/MM/DD HH:mm"
                    format="YYYY-MM-DD HH:mm:ss"
                  />
                </div>
              </div>
              <div class="select-group">
                <select class="form-control" v-model.number="searchData.發送狀態" @change="resetDataList()">
                  <option value="-1">全部狀態</option>
                  <option v-for="(option,i) in def.notificationState.狀態" :value="i">{{ option }}</option>
                </select>
              </div>
              <div class="btns pull-left m-0">
                  <button type="button" @click="resetDataList()" class="btn btn-primary">查詢</button>
              </div>
            </div>
            <div class="table-responsive mt-10">
              <table class="table table-bordered table-hover table-center min-800 AdListTable text-center">
                <thead>
                  <tr class="bg-gray">
                    <th style="min-width: 60px; width: 3%; word-break:keep-all">編號</th>
                    <th style="min-width: 60px; width: 3%; word-break:keep-all">狀態</th>
                    <th style="min-width:200px; width:25%; word-break:keep-all">推播內容</th>
                    <th style="min-width: 80px; width: 3%; word-break:keep-all">發送對象</th>
                    <th style="min-width: 80px; width: 3%; word-break:keep-all">是否登入APP</th>
                    <!-- <th style="min-width: 88px; width: 5%; word-break:keep-all">排序</th> -->
                    <th style="min-width: 92px; width:10%; word-break:keep-all">發送時間</th>
                    <th style="min-width: 92px; width:10%; word-break:keep-all">建立時間</th>
                    <th style="min-width:135px; width: 3%; word-break:keep-all">功能</th>
                  </tr>
                </thead>
                <tbody v-if="total > 0">
                  <tr v-for="(item, index) in pagedDatas" :key="item.id" :class="{'bg-gray-light':item.發送狀態 != 1}">
                    <td>{{ item.id }}</td>
                    <td>{{ def.notificationState.狀態[item.發送狀態] || item.發送狀態 }}</td>
                    <td>
                      <div class="preview">
                        <div class="text-left">
                          <img v-if="item.推播圖片" :src="item.推播圖片" alt="" style="height:3em; border-radius:.25em; width:auto; float:right; margin-left:.35em; object-fit:cover;" >
                          <strong class="text-clamp">{{ item.標題 }}</strong>
                          <span class="text-clamp">{{ item.內容 }}</span>
                        </div>
                        <button onclick="this.parentElement.classList.toggle('open')"></button>
                      </div>
                    </td>
                    <td>{{ def.notificationState.發送對象[item.發送對象] || item.發送對象 }}</td>
                    <td>{{ def.notificationState.是否登入[item.登入狀態] || item.登入狀態 }}</td>
                    <!-- <td class="td-sort text-nowrap">
                      <input type="number" v-model="item.排序" min="1" :max="total" />
                      <button type="button" @click="updateSort(item)" class="btn btn-warning btn-add btn-upDate">更新</button>
                    </td> -->
                    <td>{{ uiDate(item.預約時間) }}</td>
                    <td>{{ uiDate(item.建立時間) }}</td>
                    <td>
                      <template v-if="item.發送狀態 == 1">
                        <button type="button" @click="editorItem(item)" class="btn btn-primary">修改</button>
                        <button type="button" @click="deleteItem(item)" class="btn btn-danger ml-5">刪除</button>
                      </template>
                      <template v-if="item.發送狀態 == 2">
                        <button type="button" @click="viewItem(item)" class="btn btn-success">明細</button>
                      </template>
                    </td>
                  </tr>
                </tbody>
                <tbody v-else>
                  <tr>
                    <td colspan="11">查詢沒有資料</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="box text-center no-border no-shadow mt-20">
              <Pagination :pageLimitCount="pageSize" :total="total" ref="pagination" @pageMethod="onPageChange" />
            </div>
            <!-- <pre>{{ datas }}</pre> -->
          </div>

          <!-- 頁數 -->
          <FormDialog ref="viewDialog" title="發送明細">
            <p style="display:flex; align-items:center; justify-content:space-between;">
              <span>
                {{ $t('發送對象') }}: 
                <span v-if="viewType == 2">{{ viewSuccess }} / {{ viewSuccess + viewFail }}</span>
                <span v-else>{{ def.notificationState.發送對象[viewType] || viewType }}</span>
              </span>
              <input v-show="viewType == 2" type="text" v-model="viewFilter" class="form-control" :placeholder="$t('暱稱搜尋')"
                style="width:50%" />
            </p>
            <table v-show="viewType == 2" class="table table-bordered mt-10 mb-10">
              <thead>
                <tr class="bg-gray-light">
                  <th colspan="10" style="padding-block: .25em;">{{ $t('玩家名單') }}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="row in pagedResults">
                  <td style="padding:.1em .5em;width:35%">{{ row.name1 }}</td>
                  <td style="padding:.1em .5em;width:15%" :class="row.status1 == 1 ? 'text-green' : 'text-red'">
                    {{ row.status1 == 1 ? $t('成功') : $t('失敗') }}
                  </td>
                  <td style="padding:.1em .5em;width:35%">{{ row.name2 }}</td>
                  <td style="padding:.1em .5em;width:15%" :class="row.status2 == 1 ? 'text-green' : 'text-red'" v-show="row.name2">
                    {{ row.status2 == 1 ? $t('成功') : $t('失敗') }}
                  </td>
                </tr>
              </tbody>
            </table>
            <div class="text-center" v-show="viewPageTotal > viewPageSize">
              <Pagination ref="paginationView" :pageLimitCount="viewPageSize" :total="viewPageTotal"
                v-on:pageMethod="onViewPageChange" />
            </div>
            <template slot="footer">
              <button type="submit" class="btn btn-primary" style="min-width:8em" data-dismiss="modal">{{$t('確認')}}</button>
            </template>
          </FormDialog>
      </div>
    </section>
  </div>
</template>
<style scoped lang="scss">
.text-clamp{
  display:-webkit-box;
  -webkit-box-orient:vertical;
  -webkit-line-clamp:1;
  overflow: hidden;
  text-overflow: ellipsis;
}

.preview{
  display: flex;
  justify-content: center;
  // align-items: center;
  >div{
    flex-grow: 1;
  }
  button{
    font-size: 16px;
    display: inline-block;
    border: none;
    background: transparent;
    width: 1.5em;
    height: 2.5em;
    flex: 0 0 auto;
    &::before{
      content: "\2BC7";
    }
  }
  &.open{
    .text-clamp{
      display: block;
    }
    button{
      &::before{
        content: "\2BC6"
      }
    }
  }
}
</style>
<script>
/* eslint-disable */
//import CKEditor from "@/components/CKEditor";
import Pagination from "@/components/Pagination";
import DateRangePicker from "@/components/DateRangePicker";
import FormDialog from "../XSG/components/FormDialog";
import define from "@/views/projects/NMJ/_Define";
import moment from "moment";
import { ToggleButton } from "vue-js-toggle-button";
import { mapState } from "vuex";
import { get } from "sortablejs";

export default {
  components: {
    DateRangePicker,
    FormDialog,
    Pagination,
    ToggleButton
  },
  data() {
    return {
      def: define,
      loading: true,
      message: "",
      total: 0, //總筆數
      datas: [],
      searchData: {
        發送狀態: -1,
        開始時間: new moment().subtract(28, "days").format("YYYY-MM-DDT00:00:01"),
        結束時間: new moment().add(28, "days").format("YYYY-MM-DDT23:59:59"),
      },
      pageNum: 1,
      pageSize: 20, //每頁顯示筆數

      viewType: "",
      viewFilter: "",
      viewData: [],
      viewSuccess: 0,
      viewFail: 0,
      viewPageNum: 1,
      viewPageSize: 10, //每頁顯示筆數
      viewPageTotal: 0,
    };
  },
  async created() {
    this.searchData = this.$route.params.searchData || this.searchData;
    this.getDataList();
  },
  mounted() {
    window.v = this;
  },
  computed: {
  //   ...mapState({
  //     event: (state) => state.fcEventAnnouncement.queryAll,
  //   }),
    
    pagedDatas: function(){
      return this.datas.slice((this.pageNum - 1) * this.pageSize, (this.pageNum) * this.pageSize);
    },
    filteredResults: function(){
      let data;
      if (this.filterView != "") data = this.viewData.filter(x => new RegExp(this.viewFilter, "gi").test(x.name));
      else data = this.viewData;
      let arr = [];
      for (let i = 0; i < data.length; i+=2){
        arr.push({
          name1: data[i].name,
          status1: data[i].status,
          name2: data[i+1] !== undefined ? data[i+1].name : "",
          status2: data[i+1] !== undefined ? data[i+1].status : "",
        })
      }
      this.viewPageTotal = arr.length;
      return arr;
    },
    pagedResults: function(){
      return this.filteredResults.slice((this.viewPageNum - 1) * this.viewPageSize, (this.viewPageNum) * this.viewPageSize);
    },
  },
  // watch: {
  //   bannerTypes() {},
  //   response() {
  //     if (this.response.狀態 == "1")
  //       this.$eventBus.$emit("showAlert", "更新成功!");
  //     else this.$eventBus.$emit("showAlert", "更新失敗!");

  //     this.getDataList();
  //   },
  //   event() {
  //     this.$refs.pagination.setPage(
  //       this.searchData.pageNum,
  //       this.fcEventAnnouncement.total
  //     );
  //   },
  // },
  methods: {
    resetDataList() {
      // this.searchData.開始時間 = new Date(0).toJSON();
      // this.searchData.結束時間 = new moment().add(10, "days").format("YYYY-MM-DDT23:59:59");
      
      this.pageNum = 1;
      // console.log(this.searchData.分類, this.searchData.狀態)
      this.getDataList();
    },
    getDataList() {
      this.loading = true;
      // this.searchData.開始時間 = this.searchData.開始時間.replace(/\//g, "-").replace(" ", "T").replace(/(Z|\+.*)?$/, "");
      // this.searchData.結束時間 = this.searchData.結束時間.replace(/\//g, "-").replace(" ", "T").replace(/(Z|\+.*)?$/, "");
      this.$store
      .dispatch("nmjNotification/queryAll", this.searchData)
      .then((result) => {
        this.result = result;
        this.datas = result.datas || [];
        this.total = result.total;
        this.loading = false;
        this.$refs.pagination.setPage(this.pageNum, this.total);

      },
      (reject) => {
          this.total = 0;
          this.loading = false;
      });
    },
    async onPageChange(pageIdx, pageSize) {
      this.pageNum = pageIdx;
      this.pageSize = pageSize;
      this.getDataList();
    },
    async onViewPageChange(pageIdx, pageSize) {
      this.viewPageNum = pageIdx;
      this.viewPageSize = pageSize;
    },

    updateSort(item) {
      // item.排序 = parseInt(item.排序);
      if (item.排序 == 0 || isNaN(item.排序)) item.排序 = 1;
      if (item.排序 > this.total)
        return this.$eventBus.$emit( "showAlert", "排序不可大於最大排序(" + this.total + ")!!");

      this.$store.dispatch("nmjMarquee/update", item).then(() => {
        this.getDataList()
      });;
    },

    //*---- 新增內容
    addNew() {
      this.$router.push({
        name: "NmjNotificationEdit",
        params: {
          type: this.searchData.分類,
          searchData: this.searchData,
        },
      });
    },
    //*---- 修改內容
    async editorItem(item) {
      this.$router.push({
        name: "NmjNotificationEdit",
        params: { 
          id: item.id,
          type: this.searchData.分類,
          searchData: this.searchData,
        }
      });
      return;
    },

    //*---- 刪除
    deleteItem(item) {
      this.$eventBus.$emit("showConfirm", "確定要刪除嗎?", (isOK) => {
        if (isOK) {
          this.$store.dispatch("nmjMarquee/delete", item).then(() => {
            this.getDataList();
          });
        }
      });
    },

    viewItem(item){
      let list = [];
      let failList = [];
      try{
        list = JSON.parse(item.推播名單);
        failList = JSON.parse(item.失敗log);
        failList = failList.Names || [];
      } catch { }
      let arr = [];
      for (let i = 0; i < list.length; i++){
        let item = list[i];
        arr.push ({
          name: item,
          status: !failList.includes(item),
        });
      }
      this.viewType = item.發送對象;
      this.viewData = arr;
      this.viewSuccess = list.length - failList.length;
      this.viewFail = failList.length;
      this.$refs.viewDialog.show();
    },

    uiDate(date){
      let d = new Date(date);
      if (d && date) return d.toLocaleString('zh-TW', {
        year:"numeric",
        month:"2-digit",
        day:"2-digit",
        hour:"2-digit",
        minute:"2-digit",
        second:"2-digit",
        hourCycle:"h23",
      });
      else return '';
    }
  },
};
</script>