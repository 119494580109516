<template>
  <div id="AnnouncementEdit">
    <section class="content-header">
      <h1>常見問題查詢</h1>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li>常見問題列表</li>
        <li class="active">常見問題編輯</li>
      </ol>
    </section>

    <section class="content">
      <div class="box box-primary">
        <div class="box-body">
          <div class="top-box with-border">
            <h4 class="pull-left" id="title">編輯內容</h4>
            <button
              @click="backList()"
              class="btn btn-block btn-default btn-cog"
              type="button"
            >
              <i class="fa fa-reply"></i> 返回列表
            </button>
          </div>

          <form class="form-horizontal" name="announcementForm">
            <div class="box-body">
              <div class="form-group">
                <label class="col-sm-2 control-label" for="inputTitle">
                  <span class="text-red">*</span>客服編號：
                </label>
                <div class="col-sm-10">
                  <input
                    class="form-control"
                    id="inputTitle"
                    required="required"
                    type="text"
                    v-model="typeEdit.CId"
                  />
                </div>
              </div>

              <div class="form-group">
                <label class="col-sm-2 control-label" for="inputTitle">
                  <span class="text-red">*</span>分類標題：
                </label>

                <div class="col-sm-10">
                  <input
                    class="form-control"
                    type="text"
                    v-model="typeEdit.CName"
                  />
                </div>
              </div>

              <div class="form-group">
                <label class="col-sm-2 control-label" for="inputSort">
                  <span class="text-red">*</span> 排序：
                </label>
                <div class="col-sm-10">
                  <input
                    class="form-control"
                    id="inputSort"
                    type="text"
                    v-model="typeEdit.sort"
                  />
                </div>
              </div>

              <div class="form-group">
                <label class="col-sm-2 control-label" for="inputSort">
                  <span class="text-red">*</span> 項目：
                </label>
                <div class="col-sm-10">
                  <button
                    data-v-c404774c=""
                    type="button"
                    class="btn btn-warning mt-10 mb-10"
                  >
                    <i class="fa fa-plus"></i> 新增項目
                  </button>

                  <div class="c-panel js-accordion w100">
                    <h3 class="panel-title js-accordion-head w100 row left">
                      [Membership Details] arrow
                    </h3>

                    <div class="panel-content js-accordion-content w100 row">
                      <div class="form-group">
                        <label class="col-sm-2 control-label">
                          項目標題：
                        </label>
                        <div class="col-sm-6">
                          <input
                            class="form-control"
                            name="imgInfo"
                            type="text"
                            v-model="Question" 
                          />
                        </div>
                      </div>

                      <div class="form-group">
                        <label class="col-sm-3 control-label">
                          <span class="text-red">*</span> 是否開啟：
                        </label>
                        <div class="col-sm-9 mt-7">
                          <fieldset class="ckbx-style-8 ckbx toggleInput mr-10">
                            <input
                              type="checkbox"
                              :true-value="1"
                              :false-value="0"
                              :id="`checkEdit`"
                              v-model.number="edit.FaqState"
                              @change="changeStatus(item)"
                              name="ckbx-style-8"
                            />
                            <label :for="`checkEdit`"></label>
                          </fieldset>
                          <span>{{ edit.FaqState ? "開啟" : "關閉" }}</span>
                        </div>
                      </div>

                      <div class="form-group">
                        <label class="col-sm-3 control-label" for="inputPlatform">
                          <span class="text-red">*</span> 顯示平台(可複選)：
                        </label>
                        <div class="col-sm-9 mt-7">
                          Platforms input 
                          <!-- <div class="checkbox checkbox-inline" v-for="(platform, i) in def.Platforms">
                            <label>
                              <input type="checkbox" v-model="data.platforms[i]" @change="updatePlatforms()"
                                style="position:relative; width:1.5em; height:1.5em; vertical-align:sub; margin-top:-.2em; accent-color:#3498db; cursor:pointer;"
                              >
                              &nbsp;{{ platform }}
                            </label>
                          </div> -->
                        </div>
                      </div>

                      <div class="form-group">
                        <label class="col-sm-3 control-label" for="inputSort">
                          <span class="text-red">*</span> 排序：
                        </label>
                        <div class="col-sm-9">
                          <input type="number" class="form-control" style="max-width:320px" min="1" max="999" required 
                            v-model="data.Sort" />
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="box-footer">
              <div class="btns">
                <button
                  class="btn btn-primary btn-lg"
                  type="button"
                  @click="Save(false)"
                >
                  確認送出
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
/* eslint-disable */
import api from "@/assets/js/app-api";
import utils from "@/assets/js/app-utils";
import { mapState, mapActions } from "vuex";
import tinymce from "@/components/Tinymce";

export default {
  components: {
    tinymce,
  },
  delimiters: ["${", "}"],
  name: "app",
  props: {
    id: "",
  },
  data() {
    return {
      isEdit: false,
      isCreate: false,
      projectId: "omp_xinstars",
      lang: "zh-tw",

      typeEdit: {
          "CId": 1,
          "CName": "分類一",
          "Disabled": 0,
          "Sort": 1
      },

      edit: [
        {
          CId: 1,
          Question: "Question 1",
          Sort: 1,
          FaqState: 1,
          Platforms: "PC,GOOGLE",
          Answers: [
            {
              Type: 1,
              Contents: "Question 1, Answer 1",
              Sort: 1,
            },
          ],
          
        },
      ],

      pcImg: null,
      mobileImg: null,
    };
  },
  created() {},
  mounted() {
    if (this.$route.params.id > 0) {
      this.isEdit = true;
      // this.GetOperation();
    } else {
      this.isCreate = true;
      // this.edit.gid = utils.getTimeId();
    }
  },
  computed: {
    ...mapState({
      response: (state) => state.xinGameIntro.response,
    }),

  },
  watch: {
    response(value) {},
  },
  methods: {
    //取得該遊戲操作說明資料
    GetOperation() {
      let _id = this.isCreate ? this.edit.gid : this.$route.params.id;

      // console.log('isCreate:', this.isCreate ," ,$route.params.id:",this.$route.params.id
      //   ," ,edit.gid:",this.edit.gid);
      // console.log(_id);

      this.$store
        .dispatch("xinGameIntro/GameOperationQuery", { gid: _id })
        .then((result) => {
          console.log(result);
          this.edit = result;
          this.getInitImg(); //files 裡的圖片分類
        });
    },

    updatePlatforms(){
      let arr = [];
      for (let i = 0, len = this.data.platforms.length; i < len; i++){
        if (!!this.data.platforms[i] == true) arr.push(i);
      }
      this.data.顯示平台 = arr.join(",");
      // console.log(arr, this.data.顯示平台)
    },

    getImgs() {
      // let _id = this.isCreate ? this.edit.gid : this.$route.params.id;
      this.$store
        .dispatch("xinGameIntro/GameOperationQuery", { gid: this.edit.gid })
        .then((result) => {
          this.edit.files = result.files;
          this.getInitImg();
        });
    },

    getInitImg() {
     
    },

    onFileChange(evt, item) {
      var files = evt.target.files;

      if (files.length > 0) {
        var isCheckType = true; //是否檢查圖片副檔名
        var fileSize = files[0].size;
        var re = /\.(jpg|png|gif|jpeg|JPG|PNG|GIF|JPEG)$/i; //允許的副檔名
        var fileName = files[0].name;

        if (isCheckType && !re.test(fileName)) {
          this.$eventBus.$emit(
            "showAlert",
            "您選擇的檔案格式不符，請重新選擇。"
          );
          return;
        }

        if (fileSize > 300000) {
          this.$eventBus.$emit(
            "showAlert",
            "您選擇的檔案超過上限 300KB，請重新選擇。"
          );
          return;
        }
      }

      let AdPic = document.getElementById(item);
      let Info = item; //Info: pc, mobile
      let folderName = "info"; //Module

      if (!this.isEdit) {
        //新增 creat
        // console.log('run creat ');
        // console.log(this.edit);
        this.$store
          .dispatch("xinGameIntro/GameOperationCreat", this.edit)
          .then((res) => {
            this.isEdit = true;
            this.uploadFile(AdPic.files[0], this.edit.gid, Info, folderName);
          });
      } else {
        //編輯
        // console.log('run Edit');
        // console.log(this.edit);
        this.uploadFile(AdPic.files[0], this.edit.gid, Info, folderName);
      }
    },

    uploadFile(files, id, info, folderName, multi = false) {
      let data = new FormData();

      data.append("files", files);
      data.append("moduleId", id);
      data.append("Info", info);
      data.append("module", folderName);
      //data.append("multi", multi);

      this.$store.dispatch("xinGameIntro/xinUploadImg", data).then((res) => {
        if (res.status != "1") {
          this.$eventBus.$emit("showAlert", "圖片上傳失敗!!");
        } else {
          this.getImgs();
          // this.GetOperation()
        }
      });
    },

    DeletePic(item) {
      let delOb = { id: item };
      console.log(delOb);

      this.$store.dispatch("xinGameIntro/xinDelImg", delOb).then(() => {
        this.GetOperation();
      });
    },

    //送出
    async Save() {
      this.edit.sort = parseInt(this.edit.sort);
      // if (isNaN(this.edit.sort) || this.edit.sort == 0) this.edit.sort = 1;

      if (!this.edit.version) {
        this.$eventBus.$emit("showAlert", "請輸入版本名稱");
        return;
      }

      // if (!this.pcImg) {
      //   this.$eventBus.$emit("showAlert", "請上傳桌機版圖片!!");
      //   return;
      // }

      if (!this.edit.content) {
        this.$eventBus.$emit("showAlert", "請輸入桌機版操作說明");
        return;
      }

      // if (!this.mobileImg) {
      //   this.$eventBus.$emit("showAlert", "請上傳手機版圖片!!");
      //   return;
      // }

      if (!this.edit.content_mobile) {
        this.$eventBus.$emit("showAlert", "請輸入手機版操作說明");
        return;
      }

      console.log(this.edit);

      if (this.isEdit) {
        this.$store
          .dispatch("xinGameIntro/GameOperationUpdate", this.edit)
          .then((result) => {
            this.backList();
          });
      } else {
        this.$store
          .dispatch("xinGameIntro/GameOperationCreat", this.edit)
          .then((result) => {
            this.backList();
          });
      }
    },

    //*---- 返回
    backList() {
      this.$router.push({
        name: "XinGameOperationList",
        params: { searchData: this.$route.params.searchData },
      });
    },
  },
};
</script>
<style scoped>
.chooseBox .img-close {
  right: -10px !important;
}
</style>