<template>
  <div id="AnnouncementList">
    <section class="content-header">
      <h1><i class="fa fa-fw fa-home"></i>首頁</h1>
      <ol class="breadcrumb">
        <li><router-link to="/">Home</router-link></li>
        <li><router-link :to="{name:'AWH5WebHome'}">官網管理</router-link></li>
        <li class="active">首頁</li>
      </ol>
    </section>
    <section class="content">
      <div class="nav-tabs-custom">
        <ul class="nav nav-tabs">
          <template v-for="(item, index) in tabs">
            <li v-if="item.permission" :class="{active:item.component == tab}" :key="index">
              <!-- <a @click="!tab.active && changeTab(menuIndex, index)"></a> -->
              <router-link :to="{params:{tab:item.component}}">{{ item.name }}</router-link>
            </li>
          </template>
        </ul>
        <div class="tab-content">
          <KeepAlive><component :is="tab" module="banner"></component></KeepAlive>
        </div>
      </div>
    </section>
  </div>
    <!-- <pre>{{ datas }}</pre> -->
</template>

<style lang="scss" scoped>
/* eslint-disable */
.nav-tabs{ background:linear-gradient(transparent,rgba(0,0,0,.05)) }
.nav-tabs li{ cursor:pointer;}
</style>

<script>
/* eslint-disable */
import dfn from "@/views/projects/XSW/_Define";
import WebHomeBanner from "./components/WebHomeBanner";
export default {
  components: {
    Banner: WebHomeBanner,
    // Welcome: WebHomeBanner,
    // Champion: WebHomeBanner,
  },
  props: {
    tab: {type:String},
  },
  data() {
    return {
      tabs: [
        { name:"大圖輪播 (Banner)", component:"Banner", permission:this.$user.hasPermission(dfn.permission.官網Banner列表查詢) },
        // { name:"最搶手的冠軍遊戲", component:"Champion", permission:this.$user.hasPermission(dfn.permission.官網Banner列表查詢) },
        // { name:"歡迎來到 ApexWin", component:"Welcome", permission:this.$user.hasPermission(dfn.permission.官網Banner列表查詢) },
      ],
      tabIndex: 0,
    };
  },
  async created() {
    this.changeTab();
  },
  mounted() {
  },
  computed: {
  },
  methods: {
    changeTab(tabIndex){
      if (tabIndex == undefined){
        let tab = this.tabs.find(tab => tab.permission);
        if (tab.component != this.tab) this.$router.replace({params:{tab:tab.component}});
      } 

      for (let i = 0; i < this.tabs.length; i++){
        this.$set(this.tabs[i], 'active', tabIndex == i);
      }

      this.tabIndex = tabIndex;
      // console.log("\ntabIndex", tabIndex);
    },
  },
};
</script>
