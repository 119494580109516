<template>
  <div id="AnnouncementList">
    <section class="content-header">
      <h1>{{ $t('最新消息列表') }}</h1>
      <ol class="breadcrumb">
        <li><router-link to="/">{{ $t('首頁') }}</router-link></li>
        <li v-if="id" class="active"><router-link :to="{params:{ id:null, searchData:searchData }}">{{ $t('最新消息列表') }}</router-link></li>
        <li v-else class="active">{{ $t('最新消息列表') }}</li>
        <li v-if="id" class="active">{{ $t(id != 'New' ? "編輯內容" : "新增內容") }}</li>
      </ol>
    </section>
    <section v-show="!id" class="content">
      <div class="box box-primary">
        <div class="box-body">
          <form class="top-box" @submit.prevent="clickSearch()">
            <router-link :to="{params:{id:'New', searchData:searchData}}" class="btn btn-block btn-warning btn-add fa fa-plus mb-10">
              {{ $t('新增訊息') }}
            </router-link>
            <!-- <button @click="addNew()" type="button" class="btn btn-block btn-warning btn-add fa fa-plus">
              新增訊息
            </button> -->
            <div class="select-group">
              <select class="form-control" v-model="searchData.類別" @change="clickSearch()">
                <option value="">{{ $t('全部類別') }}</option>
                <option v-for="(name, key) in newsType" :key="key" :value="name">{{ $t(name) }}</option>
              </select>
            </div>
            <div class="select-group">
              <select class="form-control" v-model="searchData.是否開啟" @change="clickSearch()">
                <option :value="-1">{{ $t('全部狀態') }}</option>
                <option :value="1">{{ $t('開啟') }}</option>
                <option :value="0">{{ $t('關閉') }}</option>
              </select>
            </div>
            <div class="input-group pull-left mb-10 mr-10">
              <input type="text" v-model="searchData.公告標題" class="form-control" :placeholder="$t('搜尋標題')">
            </div>
            <div class="form-group pull-left m-0">
              <label class="control-label pull-left mt-7">{{$t('查詢區間')}}：</label>
              <div class="input-group date pull-left mb-10 mr-10">
                <div class="input-group-addon fa fa-calendar"></div>
                <DateRangePicker :propStartDate.sync="searchData.開始時間" :propEndDate.sync="searchData.結束時間" :propTimePicker="false" />
              </div>
              <button type="submit" class="btn btn-primary pull-left mb-10">{{$t('查詢')}}</button>
              <div class="text data-num">
                <span>{{ $t('資料總筆數') }}：</span>
                <span id="total">{{ total }}</span>
              </div>
            </div>
          </form>
          <div class="table-responsive mt-10 text-center">
            <table class="table table-hover table-bordered">
              <tbody>
                <tr class="bg-gray">
                  <th style="min-width:100px; width:10%">{{$t('圖片')}}</th>
                  <th style="min-width:150px; width:15%">{{$t('標題')}}</th>
                  <th style="min-width:100px; width: 5%">{{$t('類別')}}</th>
                  <th style="min-width: 75px; width: 5%">{{$t('狀態')}}</th>
                  <th style="min-width: 55px; width: 5%">{{$t('置頂')}}</th>
                  <th style="min-width: 75px; width: 5%">{{$t('排序')}}</th>
                  <th style="min-width: 92px; width:10%">{{$t('建立日期')}}</th>
                  <th style="min-width: 80px; width: 5%">{{$t('異動人員')}}</th>
                  <th style="min-width: 92px; width:10%">{{$t('異動日期')}}</th>
                  <!-- <th style="min-width: 92px; width:10%">{{$t('發布時間')}}</th> -->
                  <th style="min-width:190px; width: 5%">
                    {{$t('功能')}}
                  </th>
                </tr>
                <tr v-if="loading"><td colspan="10">{{$t('查詢中')}}</td></tr>
                <tr v-else-if="message"><td colspan="10">{{ $t(message) }}</td></tr>
                <tr v-else-if="total <= 0"><td colspan="10">{{$t('沒有資料')}}</td></tr>
                <tr v-else v-for="(item, index) in datas" :key="item.newsId">
                  <td><img style="max-height:80px;" :src="getImageUrl(item, '1')" :alt="getImageUrl(item, '1')"></td>
                  <td>{{ item.公告標題 }}</td>
                  <td>{{ $t(item.類別) }}</td>
                  <td>
                    <fieldset class="ckbx-style-8 ckbx toggleInput" :disabled="!can修改" :style="!can修改?'opacity:.5;':false">
                      <input type="checkbox" :true-value="1" :false-value="0" :id="`check${index}`" v-model.number="item.是否開啟" @change="saveItem(item, false)" name="ckbx-style-8" />
                      <label :for="`check${index}`"></label>
                    </fieldset>
                    <p>{{ $t(item.是否開啟 ? '開啟' : '關閉') }}</p>
                  </td>
                  <td>
                    <!-- {{ item.是否置頂 === 1 ? '是':'否' }} -->
                    <i v-show="item.是否置頂 === 1" class="fa fa-thumb-tack fa-lg"></i>
                  </td>
                  <td>
                    <fieldset style="display:flex; flex-direction:column; gap:5px">
                      <input type="number" class="form-control p-5" v-model.number="item.排序" min="1" max="" style="padding-right:0; text-align:center;" />
                      <button type="button" class="btn btn-warning btn-add btn-upDate" @click="saveItem(item, false)">
                        <i class="fa fa-fw fa-arrows-v"></i>{{$t('更新')}}
                      </button>
                    </fieldset>
                  </td>
                  <td>{{ FormatTime(item.建立時間) }}</td>
                  <td>{{ item.異動人員 || item.建立者 }}</td>
                  <td>{{ FormatTime(item.異動時間) }}</td>
                  <!-- <td>{{ FormatTime(item.發佈時間) }}</td> -->
                  <td :style="/^en/.test($i18n.locale)?'min-width:250px':''">
                    <button @click="previewItem(item)" class="btn btn-success">{{$t('預覽')}}</button>
                    <button @click="editorItem(item)" class="btn btn-primary ml-5">{{$t('修改')}}</button>
                    <button @click="deleteItem(item)" class="btn btn-danger ml-5">{{$t('刪除')}}</button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="box-footer text-center no-border" v-show="total > searchData.PageSize">
          <Pagination
            ref="pagination"
            :total="total"
            :pageLimitCount="searchData.PageSize"
            v-on:pageMethod="onPageChange"
          />
        </div>
      </div>
      <!-- <pre style="line-height:1.1;">{{ datas }}</pre> -->
    </section>

    <section v-show="id" class="content" :key="edit.newsId">
      <div class="box box-primary">
        <div class="box-body">
          <div class="top-box with-border">
            <h4 class="pull-left" id="title">{{ $t(id != "New" ? "編輯內容" : "新增內容") }}</h4>
            <router-link :to="{params:{ id:null, searchData:searchData }}" class="btn btn-block btn-default btn-cog fa fa-reply">
              {{ $t('返回列表') }}
            </router-link>
            <!-- <button type="button" @click="backList()" class="btn btn-block btn-default btn-cog fa fa-reply">
              返回列表
            </button> -->
          </div>
          <form class="form-horizontal" name="announcementForm" @submit.prevent="saveItem(edit, $event.submitter.value == 'preview')">
            <div class="box-body">
              <div class="form-group">
                <label class="col-sm-2 control-label">
                  {{ $t('公告編號') }}：
                </label>
                <div class="col-sm-10 mt-7">
                  {{ this.edit.newsId }}
                </div>
              </div>

              <div class="form-group">
                <label class="col-sm-2 control-label">
                  <span class="text-red">*</span> {{ $t('類別') }}：
                </label>
                <div class="col-sm-10">
                  <select class="form-control" v-model="edit.類別" required>
                    <option disabled="disabled" value="">{{ $t('請選擇分類') }}</option>
                    <option v-for="(name, key) in newsType" :key="key" :value="name">{{ $t(name) }}</option>
                  </select>
                </div>
              </div>

              <div class="form-group">
                <label class="col-sm-2 control-label" for="inputTitle">
                  <span class="text-red">*</span> {{ $t('公告標題') }}：
                </label>
                <div class="col-sm-10">
                  <input type="text" id="inputTitle" class="form-control" required
                    v-model="edit.公告標題" maxlength="20" :placeholder="$t('上限X字',['20'])"
                  />
                  <p class="help-block">{{ edit.公告標題.length }}/20</p>
                </div>
              </div>

              <div class="form-group">
                <label class="col-sm-2 control-label" for="inputOrderNum">
                  <span class="text-red">*</span> {{ $t('排序') }}：
                </label>
                <div class="col-sm-10">
                  <input type="number" id="inputOrderNum" class="form-control" v-model.number="edit.排序" min="0" required />
                </div>
              </div>

              <div class="form-group">
                <label class="col-sm-2 control-label">
                  <span class="text-red">*</span> {{ $t('置頂') }}：
                </label>
                <div class="col-sm-10">
                  <div class="radio-inline">
                    <iCheckRedioButton checkValue="0" v-model.number="edit.是否置頂" :message="$t('否')" name="radioMode" />
                  </div>
                  <div class="radio-inline">
                    <iCheckRedioButton checkValue="1" v-model.number="edit.是否置頂" :message="$t('是')" name="radioMode" />
                  </div>
                </div>
              </div>
              
              <div class="form-group">
                <label class="col-sm-2 control-label">
                  <span class="text-red">*</span> {{ $t('是否開啟') }}：
                </label>
                <div class="col-sm-10 mt-7">
                  <fieldset class="ckbx-style-8 ckbx toggleInput mr-10">
                    <input type="checkbox" :true-value="1" :false-value="0" :id="`checkEdit`" v-model.number="edit.是否開啟" @change="changeStatus(item)" name="ckbx-style-8" />
                    <label :for="`checkEdit`"></label>
                  </fieldset>
                  <span>{{ $t(edit.是否開啟 ? '開啟' : '關閉') }}</span>
                </div>
              </div>

              <div class="form-group">
                <label class="col-sm-2 control-label">
                  <span class="text-red">*</span> {{ $t('發佈時間') }}：
                </label>
                <div class="col-sm-10">
                  <DateAndTimePicker v-model="edit.發佈時間" format="YYYY-MM-DD HH:mm:ss" />
                </div>
              </div>
              
              <div class="form-group image-part">
                <label class="col-sm-2 control-label" for="AdPic">
                  <span v-if="edit.類別 == '活動'" class="text-red">*</span> {{ $t('圖片') }}：
                </label>
                <div class="col-sm-10">
                  <ImageSelect id="AdPic2" ref="imageSelect"
                    :gid="edit.newsId" info="1" module="ActivityNews"
                    :maxsize="500*1024" accept="image/webp, image/png, image/jpeg, image/gif"
                    :required="edit.類別 == '活動'"
                    v-model="edit.files">
                    <p class="help-block">
                      {{$t('圖片尺寸')}} <span class=text-red>1130&times;665</span>
                      {{$t('檔案格式')}} webp, jpg, png, gif
                      {{$t('大小上限')}} 500KB
                    </p>
                  </ImageSelect>
                </div>
              </div>

              <div class="form-group">
                <label class="col-sm-2 control-label" for="editor">
                  <span class="text-red">*</span> {{$t('內文')}}：
                </label>
                <div class="col-sm-10">
                  <div class="editor">
                    <tinymce v-model="edit.內文" height="600" />
                  </div>
                </div>
              </div>
            </div>
            <div class="box-footer">
              <div class="btns">
                <button type="submit" value="preview" class="btn btn-success btn-lg fa fa-eye" style="margin-right: 10px;">
                  {{$t('預覽')}}
                </button>
                <button type="submit" class="btn btn-primary btn-lg fa fa-save">
                  {{$t('確認送出')}}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
      <!-- <pre>{{ edit }}</pre> -->
    </section>
  </div>
</template>

<style lang="scss" scoped>
  .control-label{
    padding-right:0;
  }
</style>

<script>
/* eslint-disable */
//import CKEditor from "@/components/CKEditor";
import api from "@/assets/js/app-api";
import dfn from "@/views/projects/XSW/_Define";
import types from "@/views/projects/XSG/_Define";
import Pagination from "@/components/Pagination";
import DateRangePicker from "@/components/DateRangePicker";
import DateAndTimePicker from "@/components/DateAndTimePicker";
import iCheckRedioButton from "@/components/iCheckRedioButton";
import ImageSelect from "@/views/projects/XSG/components/ImageSelect";
import tinymce from "@/components/Tinymce";
import utils from "@/assets/js/app-utils";
import config from "@/assets/js/app-config";
import moment from "moment";
import { mapState, mapActions } from "vuex";
import stylus from "stylus";

const defaultEdit = () => { return {
  "newsId": utils.getTimeId(), //公告Id
  "類別": "",
  "公告標題": "",
  "是否置頂": 0,
  "是否開啟": 1,
  "排序": 1,
  "內文": "",
  "發佈時間": new moment().add(1, "day").format("YYYY-MM-DD 10:00:00"),
  files: [],
}}

export default {
  components: {
    Pagination,
    DateRangePicker,
    DateAndTimePicker,
    iCheckRedioButton,
    ImageSelect,
    tinymce
  },
  props: {
    id: String,
  },
  data() {
    return {
      dfn: dfn,
      types: types,
      newsType: types.webNewsType,
      searchData: {
        "NewsId": "",
        "類別": "",
        "公告標題": "",
        "是否置頂": -1, // 若不按置頂排序則帶-1
        "是否開啟": -1, // -1為全部查詢
        "開始時間": new moment().subtract(12, "month").format("YYYY-MM-DD 00:00:00"),
        "結束時間": new moment().add(2, "month").format("YYYY-MM-DD 23:59:59"),
        "PageNum": 1,
        "PageSize": 10,
      },

      datas: [],
      total: 0, //總筆數
      message: "",
      loading: false,

      edit: defaultEdit(),

      can新增: this.$user.hasPermission(dfn.permission.官網News新增),
      can修改: this.$user.hasPermission(dfn.permission.官網News修改),
      can刪除: this.$user.hasPermission(dfn.permission.官網News刪除),
    };
  },
  async created() {
    if (this.$route.params.searchData != undefined) {
      this.searchData = this.$route.params.searchData;
    }

    // this.getNewsQueryAll();
  },
  mounted() {
    window.v = this;
  },
  computed: {
    getImageUrl() {
      return (item, info) => {
        const logo1File = item.files.find((file) => file.info === info);
        return logo1File ? logo1File.filesUrl : "";
      };
    },
  },
  watch: {
    id: {
      handler(value){
        if (!value || value == "New"){
          // this.searchData.NewsId = ""
          this.edit = defaultEdit();
        } else if (value) {
          // this.searchData.NewsId = value;
          this.getNews(value);
        }
        this.getNewsQueryAll();
      },
      immediate: true,
    }
  },
  methods: {
    async onPageChange(pageIdx, pageSize) {
      this.searchData.PageNum = pageIdx;
      this.searchData.PageSize = pageSize;
      this.getNewsQueryAll();
    },

    getNewsQueryAll() {
      this.loading = true;
      this.$store.dispatch("xsw/官網News列表查詢", this.searchData).then( res => {
        this.datas = res.datas || [];
        this.total = res.total || 0;
        this.message = "";
        this.loading = false;
      }, err => {
        this.datas = [];
        this.total = 0;
        this.message = err;
        this.loading = false;
      });
      // console.log(this.$refs);
      if (this.$refs.pagination) this.$refs.pagination.setPage(this.searchData.PageNum, this.total);
    },
    getNews(id){
      this.$store.dispatch("xsw/官網News查詢", {NewsId: id}).then( res => {
        this.edit = res || defaultEdit();
      }, err => {

      });
    },

    clickSearch() {
      this.searchData.PageNum = 1;
      this.getNewsQueryAll();
    },

    //*---- 新增內容
    addNew() {
      this.$router.push({
        name: 'XsgWebNews',
        params: {
          id: "New",
          searchData: this.searchData,
        },
      });
      return;
    },

    //*---- 修改內容
    async editorItem(item) {
      this.$router.push({
        name: 'XsgWebNews',
        params: {
          id: item.newsId,
          searchData: this.searchData,
        },
      });
      return;
    },

    async saveItem(item, isPreview){
      if (this.id){
        let err = [];
        if (this.edit.類別 == "") err.push("請選擇分類");
        if (this.edit.公告標題 == "") err.push("請填寫公告標題");
        if (this.edit.排序 == "") err.push("請填寫排序");
        if (this.edit.發布時間 == "") err.push("請填寫發布時間");
        if (this.edit.內文 == "") err.push("請填寫內文");
        if (err.length > 0){
          this.$eventBus.$emit("showAlert", err.join('<br>'));
          return;
        }
      }

      let sendEdit = this.objToPascalCase(Object.assign({}, item, {
        files:undefined,
        建立時間:undefined,
        建立者:undefined,
        建立者UID:undefined,
        異動人員:undefined,
        異動人員UID:undefined,
        異動時間:undefined,
      }));
      // console.log(sendEdit);
      let api = (this.id == "New") ? "新增" : "修改";
      this.$store.dispatch("xsw/官網News" + api, sendEdit).then(result => {
        if (!this.id){
          // this.$eventBus.$emit("showAlert", api + "成功");
          this.getNewsQueryAll();
          return;
        }
        this.$refs.imageSelect.upload().then( res => {
          if (isPreview){
            this.previewItem(item);
            if(this.id == "New") this.editorItem(item);
            else this.getNews(item.newsId);
            return;
          }
          this.$eventBus.$emit("showAlert", api + "成功");
          this.backList();
        }, err => {
          this.$eventBus.$emit("showAlert", err );
        });
      }, error => {
        this.$eventBus.$emit("showAlert", error);
      });
    },

    backList(){
      this.$router.push({
        name: 'XsgWebNews',
        params: {
          id: null,
          searchData: this.searchData,
        },
      });
    },

    //*----預覽
    previewItem(item) {
      let width = 1000;
      let height = 840;
      let left = (screen.width - width) / 2;
      let top = (screen.height - height) / 2;
      // console.log(width, height, left, top)

      window.open(
        `${process.env.VUE_APP_XSGWEB_PATH}/News/${getNewsType(item)}/${item.newsId}?preview=1`,
        "XinstarAppPreview",
        `popup,top=${top},left=${left},width=${width},height=${height}`
      );

      function getNewsType(item){
        switch (item.類別){
          case "系統": return "System";
          case "活動": return "Event";
        }
      }
    },

    //*---- 刪除
    deleteItem(item) {
      this.$eventBus.$emit("showConfirm", "確定要刪除嗎?", (isOK) => {
        if (isOK) {
          this.$store.dispatch("xsw/官網News刪除", { "NewsId": item.newsId }).then( res => {
            this.$eventBus.$emit("showAlert", "刪除成功");
            this.getNewsQueryAll();
          }, err => {
            this.$eventBus.$emit("showAlert", err);
          });
        }
      });
    },

    objToPascalCase(obj){
      let upperObj = Array.isArray(obj) ? [] : {};
      for (let [key, prop] of Object.entries(obj) ){
        if (typeof prop == "object"){
          prop = this.objToPascalCase(prop);
        }
        let upperKey = key.replace(/^([a-z])/i, m => m.toUpperCase());
        upperObj[upperKey] = prop;
      }
      return upperObj;
    },

    FormatTime(time) {
      if (time == undefined) return "";
      if (/^0/.test(time)) return "-";
      return new moment(time).format("YYYY/MM/DD HH:mm:ss");
    },
  },
};
</script>