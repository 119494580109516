@@ -1,292 +0,0 @@
<template>
  <div id="AnnouncementList">
    <section class="content-header">
      <h1>{{$t('圖片管理')}}</h1>
      <ol class="breadcrumb">
        <li><router-link to="/">{{$t('首頁')}}</router-link></li>
        <li class="active">{{$t('圖片管理')}}</li>
        <li class="active">{{ id }}</li>
      </ol>
    </section>
    <section class="content">
      <div class="box">
        <div class="box-body">
          <div class="top-box with-border">
            <h4 class="pull-left" id="title">{{ $t(id == "New" ? "新增內容" : "編輯內容")}}</h4>
            <router-link :to="{name:'XsgWebBanner', params:{ searchData: $route.params.searchData }}" class="btn btn-block btn-default btn-cog">
              <i class="fa fa-reply"></i> {{$t('返回列表')}}
            </router-link>
          </div>
          <form class="form-horizontal" name="announcementForm" @submit.prevent="Save()">
            <div class="box-body">
              <div class="form-group">
                <label class="col-sm-3 control-label required" for="inputTitle">{{$t('類別')}}：</label>
                <div class="col-sm-9">
                  <select class="form-control" id="webBannerType" style="max-width:320px" required v-model="edit.類別">
                    <option v-for="(name, key) in types.webBannerType" :key="key" :value="name">{{ $t(name) }}</option>
                  </select>
                </div>
              </div>
              <div class="form-group">
                <label class="col-sm-3 control-label required" for="inputTitle">{{$t('標題')}}：</label>
                <div class="col-sm-9">
                  <input type="text" id="inputTitle" class="form-control" v-model="edit.標題" required placeholder="" />
                  <label class="mt-7" v-show="edit.類別 == '首頁看版管理'">
                    <input type="checkbox" :checked="!!edit.是否顯示標題" @change="edit.是否顯示標題 = +$event.currentTarget.checked">
                    {{$t('顯示標題')}}
                  </label>
                </div>
              </div>
              <div class="form-group">
                <label class="col-sm-3 control-label" for="inputContent">{{$t('內容')}}：</label>
                <div class="col-sm-9">
                  <textarea id="inputContent" class="form-control" v-model="edit.內容" rows="3" placeholder=""></textarea>
                </div>
              </div>

              <div class="form-group image-part">
                <label class="col-sm-3 control-label required" for="AdPic">{{$t('顯示圖片')}}：</label>
                <div class="col-sm-9">
                  <ImageSelect id="AdPic1" ref="imageSelect1"
                    :gid="edit.id" info="1" module="WebBanner"
                    :maxsize="500*1024" accept="image/png, image/jpeg, image/webp, image/gif" required
                    v-model="edit.files">
                    <p v-if="edit.類別 == types.webBannerType[0]" class="help-block">
                      {{$t('圖片尺寸')}} <span class=text-red>1920&times;720</span>
                      {{$t('檔案格式')}} webp, jpg, png, gif
                      {{$t('大小上限')}} 500KB
                    </p>
                    <p v-if="edit.類別 == types.webBannerType[1]" class="help-block">
                      {{$t('圖片尺寸')}} <span class=text-red>265&times;355</span>
                      {{$t('檔案格式')}} webp, jpg, png, gif
                      {{$t('大小上限')}} 500KB
                    </p>
                  </ImageSelect>
                </div>
              </div>

              <div class="form-group bannerlink-part">
                <label class="col-sm-3 control-label required" for="inputLink">{{$t('連結')}}：</label>
                <div class="col-sm-9">
                  <input type="url" id="inputLink" class="form-control" v-model="edit.連結" required />
                  <label class="mt-7">
                    <input type="checkbox" :checked="!!edit.是否另開視窗" @change="edit.是否另開視窗 = +$event.currentTarget.checked">
                    {{$t('另開視窗')}}
                  </label>
                </div>
              </div>

              <div class="form-group">
                <label class="col-sm-3 control-label required" for="inputOrderNum">{{$t('排序')}}：</label>
                <div class="col-sm-9">
                  <input type="number" id="inputOrderNum" class="form-control" v-model.number="edit.排序" min="0" />
                </div>
              </div>

              <div class="form-group">
                <label class="col-sm-3 control-label required">{{$t('開始時間')}}：</label>
                <div class="col-sm-9">
                  <DateAndTimePicker v-model="edit.開始時間" format="YYYY-MM-DD HH:mm:ss" />
                </div>
              </div>

              <div class="form-group">
                <label class="col-sm-3 control-label required">{{$t('結束時間')}}：</label>
                <div class="col-sm-9">
                  <DateAndTimePicker v-model="edit.結束時間" format="YYYY-MM-DD HH:mm:ss" />
                </div>
              </div>

            </div>
            <div class="box-footer">
              <div class="btns">
                <button type="submit" class="btn btn-primary btn-lg fa fa-save">
                  {{$t('確認送出')}}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </section>
    <!-- <pre>{{ edit }}</pre> -->
  </div>
</template>
<style lang="scss" scoped>
  input[type="checkbox"]{
    width: 1.5em;
    height: 1.5em;
    vertical-align: sub;
    margin-top: -.2em;
    accent-color: #3498db;
    cursor: pointer;
  }
  label.required::before{content: "* "; color: #dd4b39 }
  .form-control.sort{
    display: inline-block;
    width: 54px;
    padding: 5px;
    vertical-align: middle;
    border-radius: 3px;
    text-align: center;
  }
</style>

<script>
/* eslint-disable */
//import CKEditor from "@/components/CKEditor";
import api from "@/assets/js/app-api";
import types from "@/views/projects/XSG/_Define";
import Pagination from "@/components/Pagination";
import DateAndTimePicker from "@/components/DateAndTimePicker";
import iCheckCheckBox from "@/components/iCheck_CheckBox";
import iCheckRedioButton from "@/components/iCheckRedioButton";
import ImageSelect from "@/views/projects/XSG/components/ImageSelect";
import tinymce from "@/components/Tinymce";
import utils from "@/assets/js/app-utils";
import config from "@/assets/js/app-config";
import { mapState, mapActions } from "vuex";
import moment from "moment";

export default {
  components: {
    Pagination,
    tinymce,
    ImageSelect,
    DateAndTimePicker,
    iCheckCheckBox,
    iCheckRedioButton
  },
  props: {
    id: "",
    searchData: {},
  },
  data() {
    return {
      types: types,
      // bannerType: types.gameBannerType,
      // def: define,

      edit: {
        id: utils.getTimeId(),
        類別: this.searchData && this.searchData.類別 ? this.searchData.類別 : types.webBannerType[0],
        標題: "",
        內容: "",
        是否顯示標題: true,
        連結: "",
        是否另開視窗: false,
        排序: 1,
        開始時間: new moment().add(1, "days").format("YYYY-MM-DDT00:00:00"),
        結束時間: new moment().add(8, "days").format("YYYY-MM-DDT23:59:59"),
        是否常駐顯示: false,
        備註: ""
      },

      platforms: new Array(types.gamePlatform.length).fill(false),
      regions: new Array(types.gameRegion.length).fill(false),
      isOptLevel: false,
      isForever: true,
    };
  },
  created() {
    if (this.$route.params.searchData != undefined) {
      this.searchData = this.$route.params.searchData;
    }
    this.getData();
  },
  mounted() {
    window.v = this;
  },
  computed: {
  },
  // watch: {
  // },
  methods: {
    getData(){
      if (this.id == 'New') return;
      this.$store.dispatch("xsw/官網Banner查詢", { Id: this.id }).then(res => {
        Object.assign(this.edit, res);
        if (this.edit.顯示平台 != null){
          let arrIn = this.edit.顯示平台.split(',');
          let arrOut = [];
          for (let [i, value] of this.types.gamePlatform.entries()){
            arrOut[i] = arrIn.includes(value);
          }
          this.platforms = arrOut;
          // console.log(arrIn, arrOut)
        }
        if (this.edit.地區 != null){
          let arrIn = this.edit.地區.split(',');
          let arrOut = [];
          for (let [i, value] of this.types.gameRegion.entries()){
            arrOut[i] = arrIn.includes(value);
          }
          this.regions = arrOut;
          // console.log(arrIn, arrOut)
        }
      }, err => {
        this.$eventBus.$emit("showAlert", err);
      });
    },

    //*---- 返回
    backList() {
      this.$router.push({
        name: "XsgWebBanner",
        params: { searchData: this.$route.params.searchData },
      });
    },
    //送出
    async Save() {

      let api = this.id == 'New' ? "新增" : "修改";

      let sendData = this.objToPascalCase(Object.assign({}, this.edit, {
        files:undefined,
        建立時間:undefined,
        建立者:undefined,
        建立者UID:undefined,
        異動人員:undefined,
        異動人員UID:undefined,
        異動時間:undefined,
      }));

      this.$store.dispatch("xsw/官網Banner" + api, sendData).then(result => {
        Promise.all([
          this.$refs.imageSelect1.upload(),
        ]).then( res => {
          this.backList();
        }, err => {
          this.$eventBus.$emit("showAlert", err );
        });
      }, error => {
        this.$eventBus.$emit("showAlert", error );
      });
    },

    objToPascalCase(obj){
      let upperObj = Array.isArray(obj) ? [] : {};
      for (let [key, prop] of Object.entries(obj) ){
        if (typeof prop == "object"){
          prop = this.objToPascalCase(prop);
        }
        let upperKey = key.replace(/^([a-z])/i, m => m.toUpperCase());
        upperObj[upperKey] = prop;
      }
      return upperObj;
    },
  },
};
</script>