<template>
  <div>
    <fieldset class="top-box" :disabled="isEditing">
      <button @click="add()" class="btn btn-block btn-warning btn-add" type="button">
        <i class="fa fa-plus"></i> 新增
      </button>
      <div class="select-group">
        <select v-show="!module" class="form-control" id="selectUnit" v-model="viewModule">
          <option v-for="item in projectData.modules" :value="item.id" :key="item.id">{{item.name}}</option>
        </select>
      </div>
    </fieldset>
    <form class="table-responsive mt-10" @submit.prevent="save()">
      <table class="table table-bordered classTypeTable" id="classTypes">
        <tbody>
          <tr class="bg-gray">
            <th style="min-width: 150px; width:10%">分類ID</th>
            <th style="min-width: 150px; width:50%">分類名稱</th>
            <th style="min-width: 100px; width:10%">module</th>
            <th style="min-width: 100px; width:10%;">排序</th>
            <th style="min-width: 100px; width:10%;">啟用狀態</th>
            <th style="min-width: 140px; width:10%;">功能</th>
          </tr>
          <tr :key="item.classTypeId" v-for="(item, index) in datas" :class="{'bg-gray-light': isEditing && !item.isEdit}">
            <td>
              <p v-if="!item.isEdit" class="m-0">{{ item.classId }}</p>
              <input v-else type="text" class="form-control" v-model="item.classId" required />
            </td>
            <td>
              <p v-if="!item.isEdit" class="m-0">{{ getClassTypeName(item, index) }}</p>
              <MultilangInput v-else :langs="projectData.langs" v-model="details[index]" prop="className"></MultilangInput>
            </td>
            <td>
              <select :disabled="!item.isEdit" v-model="item.module" class="form-control" style="padding:0;">
                <option v-for="(item, index) in projectData.modules" :value="item.id" :key="index">{{item.name}}</option>
              </select>
            </td>
            <td align="center">
              <p v-show="!item.isEdit" class="m-0">{{ item.sortId }}</p>
              <input v-show="item.isEdit" type="number" class="form-control text-center" v-model.number="item.sortId" min="0" />
            </td>
            <td class="text-center" align="center">
              <div class="ckbx-style-8 ckbx toggleInput">
                <input type="checkbox" v-model="item.isStop" :false-value="1" :true-value="0" :disabled="isEditing && !item.isEdit" :id="'check'+index" name="ckbx-style-8"
                  @change="changeStatus(item)" />
                <label :for="'check'+index"></label>
              </div>
            </td>
            <td class="text-center">
              <fieldset :disabled="isEditing && !item.isEdit">
                <button type="button" v-show="!item.isEdit" @click="edit(item)" class="btn btn-primary">
                  修改
                </button>
                <button type="submit" v-show="item.isEdit" class="btn btn-success">
                  儲存
                </button>
                <button type="button" v-show="!item.isEdit" @click="deleteType(item, index)" class="btn btn-danger ml-5">
                  刪除
                </button>
                <button type="button" v-show="item.isEdit" @click="cancel(item, index)" class="btn btn-default ml-5">
                  取消
                </button>
              </fieldset>
            </td>
          </tr>
        </tbody>
      </table>
    </form>
  </div>
</template>

<style scoped>
  .ckbx-style-8 input:disabled + label{
    opacity: .6;
    cursor: default;
  }
  input[type="number"]{
    padding-right: 0;
  }
</style>

<script>
/* eslint-disable */
import api from "@/assets/js/app-api";
import utils from "@/assets/js/app-utils";
import MultilangInput from "./MultilangInput.vue"
import dfn from "../_Define";
// import { mapState, mapActions } from "vuex";

export default {
  components: {
    MultilangInput,
  },
  props:{
    module: { type:String, default:null,}
  },
  data() {
    return {
      projectData: dfn.projectData,
      viewModule: this.module || dfn.projectData.modules[0].id,

      datas:[],
      details:[],

      isEditing: false,
    };
  },
  created() {
    this.getData();
  },
  watch: {
    module(value){
      this.viewModule = value;
    },
    viewModule(value){
      this.getData();

      // $router.replace({params:{module:$event.target.value}})
    },
  },
  computed:{
    getClassTypeName() {
      return (item, index) => {
        const type = this.details[index].find(type => type.lang == 'zh-TW');
        return type ? type.className : item.className;
      };
    },
  },
  methods: {
    // 顯示列表
    getData(){
      this.$store.dispatch("xsw/分類列表明細查詢", {module:this.viewModule, isDelete:0}).then(res => {
        this.datas = Array.isArray(res) ? res.map(x => x.master_model) : [];
        this.details = Array.isArray(res) ? res.map(x => x.detail_model) : [];
      }, err => {
        this.$eventBus.$emit("showAlert", err);
      })
    },

    // 新增類別
    add() {
       if(this.isEditing){
        this.$eventBus.$emit("showAlert", "請先儲存上一次欲修改/添加的項目!");
         return;
      }
      this.datas.unshift({
        classTypeId: "",
        projectId: this.$store.state.projectId,
        className: "",
        module: this.module,
        isStop: 0,
        sortId: 0,
        editor: "1",
        isEdit: true,
        isNew: true
      });
      this.details.unshift([]);
      this.isEditing = true;
    },
    edit(item){
      if(this.isEditing){
        return this.$eventBus.$emit("showAlert", "請先儲存上一次欲修改/添加的項目!");
      }
      this.isEditing = true;
      item.isEdit = true;
    },

    // 儲存
    save() {
      let itemIndex = this.datas.findIndex(x => x.isEdit);
      if (itemIndex < 0) return;
      let item = this.datas[itemIndex];
      let detail = this.details[itemIndex];

      if (item.module == "") {
        return this.$eventBus.$emit("showAlert", "請選擇module!!");
      }

      let api = item.classTypeId == "" ? "新增" : "修改";
      this.$store.dispatch("xsw/分類" + api, {
        master_model: item,
        detail_model: detail
      }).then(res => {
        this.$eventBus.$emit("showAlert", api + "成功");
        this.isEditing = false;
        this.getData();
      }, err => {
        this.$eventBus.$emit("showAlert", err);
      });
    },

    cancel(item) {
      this.isEditing = false;
      this.getData();
    },

    // 改變狀態
    changeStatus(item) {
      if (item.isEdit) return;
      this.$store.dispatch("xsw/分類修改", {
        master_model: item
      }).then(res => {
        this.$eventBus.$emit("showAlert", "修改成功");
      }, err => {
        this.$eventBus.$emit("showAlert", err);
      });
    },

    // 刪除
    deleteType(item) {
      this.$eventBus.$emit("showConfirm", "確定要刪除嗎?", isOK => {
        if (isOK) {
          this.$store.dispatch("xsw/分類刪除", {classTypeId: item.classTypeId}).then(res => {
            this.$eventBus.$emit("showAlert", "刪除成功");
            this.getData();
          }, err => {
            this.$eventBus.$emit("showAlert", err);
          });
        }
      });
    },
  }
};
</script>
