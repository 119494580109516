<template>
  <div id="AnnouncementList">
    <section class="content-header">
      <h1>常見問題查詢</h1>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li class="active">常見問題列表</li>
      </ol>
    </section>

    <section class="content">
      <div class="box box-primary">
        <div class="box-body">
          <div class="top-box">
            <button
              @click="addNew()"
              class="btn btn-block btn-warning btn-add"
              type="button"
            >
              <i class="fa fa-plus"></i> 新增分類
            </button>

            <div class="input-group">
              <input
                class="form-control pull-right"
                name="table_search"
                placeholder="搜尋關鍵字"
                type="text"
                v-model="searchData.keywords"
              />
              <div class="input-group-btn">
                <button v-on:click="search()" class="btn btn-default" type="button">
                  <i class="fa fa-search"></i>
                </button>
              </div>
            </div>
          </div>

          <div class="table-responsive mt-10">
            <table class="table table-hover table-bordered AdListTable box max850">
              <tbody>
                <tr class="bg-gray">
                  <th style="min-width: 40px;width: 5%">編號</th>
                  <th style="min-width: 90px; width: 15%">客服分類</th>
                  <th class="text-nowrap" style="width: 10%">排序</th>
                  <th style="min-width: 90px; width: 6%">狀態</th>
                  <th class="text-nowrap" style="width: 5%">功能</th>
                </tr>
                <template v-if="total > 0">
                  <tr :key="item.id" v-for="(item, index) in datas">
                    <td align="center">{{ index + 1 }}</td>

                    <td align="center">
                      {{ item.CName }}
                    </td>

                    <td class="td-sort text-nowrap">
                    
                      <input class="t-c"
                        type="number"
                        v-model="item.Sort"
                        min="1"
                        :disabled="item.Disabled == 1"
                      />
                      <button
                        style="width: 70px"
                        @click="updateOrder(item)"
                        class="btn btn-warning btn-add btn-upDate"
                        type="button"
                        v-if="item.Disabled == 0"
                      >
                        <i class="fa fa-arrow-up"></i> 更新
                      </button>
                    </td>

                    <td align="center">
                      <div class="ckbx-style-8 ckbx toggleInput">
                        <input
                          type="checkbox"
                          name="ckbx-style-8"
                          :id="`check${index}`"
                          :false-value="1"
                          :true-value="0"
                          v-model="item.Disabled"
                          @change="toggle(item)"
                        />
                        <label :for="`check${index}`"></label>
                      </div>
                    </td>
                    
                    
                    <td class="text-center">
                      <button @click="editorItem(item)" class="btn btn-primary">
                        <i class="fa fa-pencil"></i> 修改
                      </button>
                      <br>
                      <!-- <button @click="deleteItem(item)" class="btn btn-danger mt-5">
                        <i class="fa fa-close"></i> 刪除
                      </button> -->
                    </td>
                  </tr>
                </template>
                <template v-else>
                  <tr>
                    <td class="text-center" colspan="10">查詢資料</td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
        </div>

        <div class="box-footer text-center no-border" v-show="total > searchData.pageSize">
          <Pagination
            ref="pagination"
            :total="total"
            :pageLimitCount="searchData.pageSize"
            v-on:pageMethod="onPageChange"
            :type2="true"
          />
        </div>

      </div>
    </section>
  </div>
</template>

<script>
/* eslint-disable */
import moment from "moment";
import Pagination from "@/components/Pagination";

export default {
  components: {
    moment,
    Pagination,
  },
  delimiters: ["${", "}"],
  name: "app",
  props: {
    id: "",
  },
  data() {
    return {
      total: 3, //總筆數
      projectId: this.$store.state.projectId,
      searchData: {
        keywords:  "",
        pageNum: 1,
        pageSize: 10, //每頁顯示筆數
      },
      datas: [
        {
          CId:'001',
          CName:'角色相關',
          Sort:'1',
          Disabled: 0, 
        },
        {
          CId:'002',
          CName:'儲值相關',
          Sort:'1',
          Disabled: 0, 
        },
        {
          CId:'003',
          CName:'遊戲相關',
          Sort:'1',
          Disabled: 1, 
        }
      ],
    };
  },
  mounted() {
    // this.getDataList();
  },
  methods: {
    search(){
      this.searchData.pageNum = 1;
      this.getDataList();
    },
    getDataList() {
      this.searchData.date = this.date;
      console.log(this.searchData);
      this.$store
        .dispatch("xinQuestion/QueryFAQCategories", this.searchData)
        .then((result) => {
          if (result) {
            this.datas = result.datas || [];
            // this.datas = this.datas.concat(this.fake);
            this.total = result.total;
            this.$refs.pagination.setPage(this.searchData.pageNum, this.total);
          } else {
            this.datas = [];
            this.total = 0;
          }
        });
    },

    async onPageChange(pageIdx, pageSize) {
      this.searchData.pageNum = pageIdx;
      this.searchData.pageSize = pageSize;
      this.getDataList();
    },

    updateOrder(item) {
      let edit = item;
      edit.sort = parseInt(edit.sort);

      this.$store
          .dispatch("xinQuestion/UpdateFAQCategory", edit)
          .then((result) => {
            this.$eventBus.$emit("showAlert", "排序更新成功！");
            this.getDataList();
          });
    },

    toggle(item) {
      const action = item.上架 ? "上架" : "下架";

      console.log(item);
      this.$eventBus.$emit("showConfirm", `確定要${action}嗎?`, (isOK) => {
        if (isOK) {
          this.$store
            .dispatch("xinQuestion/UpdateFAQCategory", {
              cid: item.cid,
              上架: item.上架
            })
            .then((result) => {
              this.$eventBus.$emit("showAlert", `${action}更新成功！`);
              this.getDataList();
            });
        } else {
          item.上架 = item.上架 ? false : true;
        }
      });
    },

    //*---- 修改內容
    addNew() {
      this.$router.push({
        name: "XinQuestionEdit",
      });
      return;
    },
    
    editorItem(item) {
      // console.log(item.CId, this.searchData );

      this.$router.push({
        name: "XinQuestionEdit",
        params: { id: item.CId, searchData: this.searchData },
      });
      return;
    },


    
  },
};
</script>
<style lang="scss" scoped>


</style>