<template>
  <div class="image-select">
    <input type="file" ref="input"
      :id="id"
      :accept="accept"
      :multiple="multiple"
      :disabled="disabled"
      :required="required && !this.files.find(item => item.info === this.info && item.filesUrl != '')"
      @change="onChange($event)"
    />
    <slot></slot>
    <div class="image-container" :key="modelValue.toString()">
      <template v-for="(image, index) in files">
        <figure class="image-box" v-if="image.info == info && image.filesUrl != ''" :key="image.fileUrl">
          <div class="image-close fa fa-fw fa-close" title="刪除檔案" @click="removeImage(index)"></div>
          <img draggable="false" :src="image.filesUrl" style="width:auto; height:auto" />
          <!-- <figcaption>
            <span v-if="image.id ">ID: {{ image.id }}</span>
            <span v-if="image.sort">順序:{{ item.sort }}</span>
          </figcaption> -->
        </figure>
      </template>
    </div>
    <!-- TODO多選排序功能預覽 -->
    <!-- <div class="row gameGallery mt-10">
      <draggable animation="500" :list="infos" :disabled="disabled" class="list-group gameGallery" ghost-class="ghost"
            @start="dragging = true"
            @end="dragging = false; getGalleryOrder();"
        >
        <div class="col-sm-4 col-xs-6 chooseBox" v-for="(item, index) in modelValue" :key="index">
          <div class="img-close fa fa-fw fa-close" :data-id="item.id" title="刪除檔案" @click="DeletePic(item.id)"></div>
          <img :src="item.filesUrl" :data-id="item.id" alt="" />
          <p>id:{{ item.id }} 順序:{{ item.info }}</p>
        </div>
      </draggable>
    </div> -->

    <!-- <pre>{{ modelValue }}</pre> -->
  </div>
</template>

<style scoped>
.image-container{
  display: flex;
  flex-wrap: wrap;
  gap: 1em;
  margin: 10px 0 0;
}
.image-box {
  margin: 0;
  position: relative;
  width: max-content;
  /* max-width: calc(50% - .5em); */
}
.image-box img {
  background: repeating-conic-gradient(transparent 0deg 90deg,rgba(0,0,0,.05) 90deg 180deg) center/20px 20px round;
  box-shadow: inset 0 0 0 1px #eee;
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 200px;
}
.image-close {
  position: absolute;
  z-index: 2;
  top: -.5em;
  right: -.5em;
  width: 1.35em;
  height: 1.35em;
  font-size: 15px;
  line-height: 1;
  text-align: center;
  color: #333;
  background-color: rgba(255, 255, 255, 0.6);
  border: 2px solid;
  border-radius: 1em;
  cursor: pointer;
}
input[type="file"]{
  display: inline-block;
}
</style>

<script>
/* eslint-disable */
// import define from "@/views/projects/XSG/_Define";
import draggable from "vuedraggable";

class Image {
  constructor(info, fileUrl = '', file = null){
    this.id = 0;
    this.info = info;
    this.filesUrl = fileUrl;
    this.file = file;
  }
}

export default {
  components: {
    draggable
  },
  emits: ['input', 'submit', 'done', 'error'],
  props: {
    gid: {type:String, required:true},
    module: {type:String, required:true},
    info: {type:String, required:true},
    value: {type:Array, default:()=>[]},
    accept: {type:String, default:"image/*"},
    maxsize: {type:Number, default: 500*1024},
    disabled: {type:Boolean, default:false},
    multiple: {type:Boolean, default:false},
    required: {type:Boolean, default:false},
    id: {type:String},
  },
  data() {
    return {
      files: [],

      infos: [],
      infosOld: [],
      infosChange: false, //圖片是否被修改
      dragging: false,
      enabled: true,
    };
  },
  mounted(){
    this.files = this.modelValue;
  },
  computed: {
    modelValue: {
      get() { this.files = this.value; return this.value },
      set(value) { this.$emit('input', value); }
    },
    // editImage: {
    //   get(){
    //     let img = this.modelValue.find(item => item.info === this.info);
    //     if (img) return img.filesUrl;
    //     else {
    //       this.modelValue.unshift(new Image(this.info));
    //       return this.modelValue[0].filesUrl;
    //     }
    //   },
    //   set(value){
    //     let img = this.modelValue.find(item => item.info === this.info);
    //     if (img && (value != "" || img.id == 0)) this.$set(img, "filesUrl", value);
    //     else {
    //       this.modelValue.unshift(new Image(this.info));
    //       this.$set(this.modelValue[0], "filesUrl", value);
    //     }
    //   }
    // },
  },
  methods:{
    bytes(number){
      if (number >= 1<<30) return (number>>30) + "GB";
      if (number >= 1<<20) return (number>>20) + "MB";
      if (number >= 1<<10) return (number>>10) + "KB";
      return number + "B";
    },
    onChange(event){
      let files = event.target.files;

      let err = [];
      for (let file of files){
        if (file.size > this.maxsize) {
          err.push(`您選擇的檔案「${file.name}」超過上限 <b class="text-red">${this.bytes(this.maxsize)}</b>，請重新選擇。`);
        }
      }
      if (err.length > 0){
        this.$eventBus.$emit("showAlert", err.join('<br>'));
        return;
      }

      for (let i = 0; i < files.length; i++){
        let reader = new FileReader();
        reader.onload = () => {
          let img = this.files.find(item => item.info === this.info);
          if (!this.multiple && img) {
            this.$set(img, 'filesUrl', reader.result);
            this.$set(img, 'file', files[i]);
          } else {
            this.$set(this.files, this.files.length, new Image(this.info, reader.result, files[i]));
          }
          // console.log(this.files, reader)
        };
        reader.readAsDataURL(files[i]);
      }
      this.modelValue = this.files;
    },

    removeImage(index){
      let img = this.files[index];
      if (img.id == 0){
        this.files.splice(index, 1);
      } else {
        this.$set(img, 'filesUrl', '');
      }
      this.$refs.input.value = '';
      this.modelValue = this.files;
    },

    async upload() {
      let images = this.files.filter(item => item.info === this.info);
      let promiseArr = [];
      // console.log("Uploading Images:", images);
      for (let image of images){
        if (image.id != 0 && /^data:|^$/.test(image.filesUrl)){
          promiseArr.push(this.$store.dispatch("xsw/檔案刪除", {id: image.id }));
          // console.log("Image Delete:", image.id);
        }
        if (image.file && /^data:/.test(image.filesUrl)){
          let data = new FormData();
          data.append("Files", image.file);
          data.append("Info", this.info);
          data.append("Module", this.module);
          data.append("ModuleId", this.gid);
          data.append("projectId", this.$store.state.projectId);
          promiseArr.push(this.$store.dispatch("xsw/檔案上傳", data));
          // console.log("Image Upload:", image.file.name);
        }
      }
      return Promise.all(promiseArr);
    },
  }
};
</script>
