<template>
  <div id="AnnouncementEdit">
    <section class="content-header">
      <h1>遊戲操作說明{{ isEdit ? '編輯' : '新增' }}</h1>
      <ol class="breadcrumb">
        <li><router-link to="/">Home</router-link></li>
        <li><router-link :to="goBack">遊戲操作說明{{ isEdit ? '編輯' : '新增' }}</router-link></li>
        <li class="active">{{ id }}</li>
      </ol>
    </section>

    <section class="content">
      <div class="box box-primary">
        <div class="box-body">
          <div class="top-box with-border">
            <h4 class="pull-left" id="title">{{ isEdit ? '編輯' : '新增' }}內容</h4>
            <router-link :to="goBack" class="btn btn-block btn-default btn-cog pull-right">
              <i class="fa fa-reply"></i> 返回列表
            </router-link>
          </div>

          <form class="form-horizontal" name="announcementForm">
            <div class="box-body" style="max-width:1000px;">
              <div class="form-group">
                <label class="col-sm-2 control-label" for="inputTitle">
                  <span class="text-red">*</span> 版本名稱：
                </label>
                <div class="col-sm-10">
                  <input type="text" class="form-control" v-model="edit.version" id="inputTitle" required />
                </div>
              </div>

              <div class="form-group">
                <label class="col-sm-2 control-label">
                  <span class="text-red" v-show="type.id == langs[0].id">*</span> 語系版本：
                </label>
                <div class="col-sm-10 btns" style="display:flex; flex-wrap:wrap; gap:5px;">
                  <button v-for="lang in langs" type="button" class="btn"
                    :class="{ 'btn-primary': type.id == lang.id }" @click="type = lang"
                  >{{ lang.name }}</button>
                </div>
              </div>

              <div class="form-group">
                <label class="col-sm-2 control-label">
                  <span class="text-red" v-show="type.id == langs[0].id">*</span> {{type.name}}說明：
                </label>
                <div class="col-sm-10">
                  <div class="editor" ><tinymce v-model="toggleContent" height="600" /></div>
                </div>
              </div>

              <div class="form-group">
                <label class="col-sm-2 control-label" for="inputSort">
                  <span class="text-red">*</span> 排序：
                </label>
                <div class="col-sm-10">
                  <input type="number" class="form-control" id="inputSort" v-model.number="edit.sort" min="0" />
                </div>
              </div>
            </div>

            <div class="box-footer">
              <div class="btns">
                <button class="btn btn-warning btn-lg mr-10" type="button" @click="preview()">
                  <i class="fa fa-fw fa-eye"></i> 預覽
                </button>

                <button class="btn btn-success btn-lg mr-10" type="button" @click="Save(true)">
                  <i class="fa fa-fw fa-save"></i> 儲存
                </button>

                <button class="btn btn-primary btn-lg" type="button" @click="Save(false)">
                  <i class="fa fa-fw fa-check"></i> 確認送出
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
      <!-- <pre>{{ edit }}</pre> -->
    </section>
  </div>
</template>

<script>
/* eslint-disable */
import api from "@/assets/js/app-api";
import utils from "@/assets/js/app-utils";
import dfn from "./_Define";
import tinymce from "@/components/Tinymce";

class Lang {
  constructor(lang){
    this.id = 0;
    this.language_type = lang;
    this.name = "";
    this.content = "";
  }
}

export default {
  components: {
    tinymce,
  },
  props: {
    id: "",
  },
  data() {
    return {
      langs: dfn.langs,
      type: dfn.langs[0],

      edit:{
        gid: utils.getTimeId(),
        version: "",
        sort: 1,
        languageList: [...dfn.langs].map(type => new Lang(type.id)),
      },

      checkInterval: 0,
    };
  },
  created() {
    if (this.isEdit) {
      this.GetOperation();
    }
    this.checkInterval = setInterval(() => {
      this.$store.dispatch("news/newsCheck")
    }, 5*60*1000 );
  },
  beforeDestroy(){
    clearInterval(this.checkInterval);
  },
  mounted() {

  },
  computed: {
    isEdit() {
      return this.id > 0 && this.id != "New";
    },
    goBack(){
      return {name:'AWH5GameOperationList'}
    },

    toggleContent: {
      get() {
        let lang = this.edit.languageList.find(item => item.language_type === this.type.id);
        if (lang){ 
          return lang.content;
        } else {
          this.edit.languageList.push(new Lang(this.type.id));
          return this.edit.languageList[this.edit.languageList.length - 1].content;
        }
      },
      set(value) {
        let item = this.edit.languageList.find(item => item.language_type === this.type.id);
        this.$set(item, "content", value);
        // console.log("SET", item.content, value);
      }
    },
  },
  watch: {

  },
  methods: {
    //取得該遊戲操作說明資料
    GetOperation() {
      this.$store.dispatch("xsw/遊戲操作說明查詢", { gid: this.id }).then((result) => {
        this.edit = result;
      }, err => {
        this.$eventBus.$emit("showAlert", err);
      });
    },

    //送出
    async Save(isPreview = false) {
      let err = [];

      if (this.edit.version == "") err.push("請填寫版本名稱");

      let defaultContent = this.edit.languageList.find(item => item.language_type == this.langs[0].id);
      if (!defaultContent || defaultContent.content == ""){
        err.push(`請填寫${this.langs[0].name}說明`)
      } 

      if (err.length > 0){
        this.$eventBus.$emit("showAlert", err.join('<br>'));
        return;
      }

      let api = this.isEdit ? "修改" : "新增";
      this.$store.dispatch("xsw/遊戲操作說明" + api, this.edit).then((res) => {
        if (isPreview){
          this.$eventBus.$emit("showAlert", api + "成功");
          if (this.isEdit) { this.GetOperation(); } // 拉新files路徑，避免重複上傳
          else this.$router.replace({params:{id:this.edit.gid}}).then(r => {
            this.GetOperation(); // 拉新files路徑，避免重複上傳
          })
        } else {
          this.$eventBus.$emit("showAlert", api + "成功");
          this.backList();
        }
      }, (err) => {
        this.$eventBus.$emit("showAlert", err);
      });
    },
    
    //*----預覽
    preview() {
      let width = 500;
      let height = 840;
      let left = (screen.width - width) / 2;
      let top = (screen.height - height) / 2;
      // console.log(width, height, left, top)

      window.open(
        `${process.env.VUE_APP_APEXGameAPI_PATH}/ApexWin`,
        "ApexWinPreview",
        `popup,top=${top},left=${left},width=${width},height=${height}`
      );
      
      let previewFile = new File([this.toggleContent], '1', {type:'text/html'});
      let dataTransfer = new DataTransfer();
      dataTransfer.items.add(previewFile);
      
      let form = document.createElement("form");
      let input = document.createElement('input');
      form.action = `${process.env.VUE_APP_APEXGameAPI_PATH}/ApexWin`;
      form.method = "POST";
      form.enctype = "multipart/form-data";
      form.target = "ApexWinPreview";
      form.hidden = true;
      input.name = "preview";
      input.type = "file";
      input.files = dataTransfer.files;
      form.appendChild(input);
      document.body.appendChild(form);
      form.submit();
      form.remove();
    },

     //*---- 返回
    backList() {
      this.$router.push({
        name: "AWH5GameOperationList"
      });
    },
  },
};
</script>

<style scoped>
.editor .tox-tinymce {
    height: 800px !important;
}
.chooseBox .img-close {
  right: -10px !important;
}
</style>