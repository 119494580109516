export default class {
  static permission = {
    // 會員管理
    會員列表查詢: '會員列表查詢',
    會員查詢: '會員查詢',
    會員轉正: '會員轉正',
    會員配點: '會員配點',
    會員配點記錄: '會員配點記錄',
    會員配發回饋金: '會員配發回饋金',
    會員星幣異動: '會員星幣異動',
    會員控管: '會員控管',
    會員控管記錄: '會員控管記錄',
    會員星幣禁止轉出: '會員星幣禁止轉出',
    會員服務記錄查詢: '會員服務記錄查詢',
    會員服務記錄新增: '會員服務記錄新增',
    會員服務記錄修改: '會員服務記錄修改',
    會員服務記錄刪除: '會員服務記錄刪除',
    會員榮譽值記錄: '會員榮譽值記錄',
    會員榮譽值異動: '會員榮譽值異動',
    // 信件管理
    信件發送: '信件發送',
    信件歷程查詢: '信件歷程查詢',
    持有信件查詢: '持有信件查詢',
    語言檔查詢: '語言檔查詢',
    // 公會管理
    公會查詢: '公會查詢',
    公會明細: '公會明細',
    公會建立: '公會建立',
    公會解散: '公會解散',
    公會解散歷程查詢: '公會解散歷程查詢',
    公會成員列表查詢: '公會成員列表查詢',
    PlayerRebateQuery: 'PlayerRebateQuery',

    // 公會審核 (APP)
    公會審核清單查詢: "公會審核清單查詢",
    公會審核歷程查詢: "GuildReviewHistoryQuery",
    公會審核通過: "公會審核通過",
    公會審核駁回: "公會審核駁回",
    // 金流管理
    金流儲值查詢: '金流儲值查詢',
    // 系統管理
    操作歷程查詢: '操作歷程查詢',
    服務類別新增: '服務類別新增',
    服務類別修改: '服務類別修改',
    服務類別查詢: '服務類別查詢',
    // 遊戲歷程
    遊戲歷程查詢: '遊戲歷程查詢',
    勝負分歷程查詢: '勝負分歷程查詢',

    // 遊戲介紹 (新H5)
    遊戲介紹列表查詢: "遊戲介紹列表查詢",
    遊戲介紹查詢: "遊戲介紹查詢",
    遊戲介紹新增: "遊戲介紹新增",
    遊戲介紹修改: "遊戲介紹修改",
    遊戲介紹刪除: "遊戲介紹刪除",
    遊戲介紹複製: "遊戲介紹複製",
    遊戲介紹清暫存: "遊戲介紹清暫存",
    // 遊戲操作說 (新H5)
    遊戲操作說明列表查詢: "遊戲操作說明列表查詢",
    遊戲操作說明查詢: "遊戲操作說明查詢",
    遊戲操作說明新增: "遊戲操作說明新增",
    遊戲操作說明修改: "遊戲操作說明修改",
    遊戲操作說明刪除: "遊戲操作說明刪除",
    遊戲操作說明複製: "遊戲操作說明複製",
    遊戲操作說明清暫存: "遊戲操作說明清暫存",
    // 分類管理 (新H5)
    分類列表查詢: "分類列表查詢",
    分類列表明細查詢: "分類列表明細查詢",
    分類查詢: "分類查詢",
    分類新增: "分類新增",
    分類修改: "分類修改",
    分類刪除: "分類刪除",
    // 官網Banner (新H5)
    官網Banner列表查詢: "官網Banner列表查詢",
    官網Banner查詢: "官網Banner查詢",
    官網Banner新增: "官網Banner新增",
    官網Banner修改: "官網Banner修改",
    官網Banner刪除: "官網Banner刪除",
    官網Banner複製: "WebBannerCopy",
    // 遊戲清單 (新H5)
    遊戲清單列表查詢: "遊戲清單列表查詢",
    遊戲清單查詢: "遊戲清單查詢",
    遊戲清單新增: "遊戲清單新增",
    遊戲清單修改: "遊戲清單修改",
    遊戲清單刪除: "遊戲清單刪除",
    遊戲清單複製: "GameListCopy",
    // 消息 (新H5)
    官網News列表查詢: "官網News列表查詢",
    官網News查詢: "官網News查詢",
    官網News新增: "官網News新增",
    官網News修改: "官網News修改",
    官網News刪除: "官網News刪除",
    官網News複製: "WebNewsCopy",

    // 類別與標籤新增 (APP)
    類別與標籤查詢: "類別與標籤查詢",
    類別與標籤新增: "類別與標籤新增",
    類別與標籤修改: "類別與標籤修改",
    // 遊戲館 (APP)
    遊戲館列表查詢: "遊戲館列表查詢",
    遊戲館查詢: "遊戲館查詢",
    遊戲館新增: "遊戲館新增",
    遊戲館修改: "遊戲館修改",
    遊戲館刪除: "遊戲館刪除",
    // 活動總覽 (APP)
    活動總覽列表查詢: "活動總覽列表查詢",
    活動總覽查詢: "活動總覽查詢",
    活動總覽新增: "活動總覽新增",
    活動總覽修改: "活動總覽修改",
    活動總覽刪除: "活動總覽刪除",
    // 金流儲值 (APP)
    Google帳單查詢: "Google帳單查詢",
    Google帳單批次查詢: "Google帳單批次查詢",
    Google每日點數合計查詢: "Google每日點數合計查詢",
    iOS帳單查詢: "iOS帳單查詢",
    iOS帳單批次查詢: "iOS帳單批次查詢",
    iOS每日點數合計查詢: "iOS每日點數合計查詢",
    OrderDetailListQuery: "OrderDetailListQuery",
    QueryFunOneBillList: "QueryFunOneBillList",
    
    // 序號兌換 (APP)
    序號兌換活動列表查詢: "序號兌換活動列表查詢",
    序號兌換狀態列表查詢: "序號兌換狀態列表查詢",
    玩家序號兌換記錄查詢: "玩家序號兌換記錄查詢",
    // 聊天室公告 (APP)
    聊天室公告查詢: "聊天室公告查詢",
    聊天室公告異動: "聊天室公告異動",

    // 共用功能
    檔案上傳: "檔案上傳",
    檔案刪除: "檔案刪除",
  };
  static gameItem = {
    1: '星幣'
  };
}