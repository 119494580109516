<template>
    <div>
        <section class="content-header">
            <h1>兌換查詢統計</h1>
            <ol class="breadcrumb">
                <li><router-link to="/">{{ $t('首頁') }}</router-link></li>
                <li class="active">Yoegames兌換查詢統計</li>
            </ol>
        </section>
        <section class="content">
            <div class="box box-primary">
                <div class="box-body">
                    <div class="top-box">
                        <div class="form-group">
                            <label class="control-label">{{ $t('產品') }}：</label>
                            <select class="selPaymentType form-control" v-model.trim="searchData.vendorId">
                                <option value="">{{ $t('全部') }}</option>
                                <option :value="item.vendorId" v-for="item of companyList" :key="item.vendorId">{{ item.vendorName }}</option>
                            </select>
                        </div>
                        <div class="form-group">
                            <label class="control-label">{{ $t('金流') }}：</label>
                            <select class="selPaymentType form-control" v-model="searchData.paymentType">
                                <option value="">{{ $t('全部') }}</option>
                                <option :value="item.paymentId" v-for="item of paymentOptList" :key="item.paymentId">{{ item.paymentName }}</option>
                            </select>
                        </div>
                        <br />
                        <div class="form-group">
                            <label class="control-label">{{ $t('交易日期') }}：</label>
                            <div class="input-group date">
                                <div class="input-group-addon"><i class="fa fa-calendar"></i></div>
                                <div class="pull-right">
                                    <DateRangePicker v-bind:propStartDate.sync="searchData.startTime"
                                        v-bind:propEndDate.sync="searchData.endTime" ref="dateRangePicker" />
                                </div>
                            </div>
                        </div>
                        <div class="form-group">
                            <button @click="onSearchBtnClick()" class="btn btn-primary" type="button">
                                {{ $t('查詢') }}
                            </button>
                        </div>
                        <div class="form-group">
                            <button @click="onExportStatisticsReportBtnClick" class="btn btn-success"
                                :class="{ disabled: !this.lastQueryCommand }" type="button">
                                匯出統計結果
                            </button>
                        </div>
                        <div class="form-group">
                            <button @click="onExportReportBtnClick" class="btn btn-success"
                                :class="{ disabled: !this.lastQueryCommand }" type="button">
                                匯出明細
                            </button>
                        </div>
                        <div class="form-group">
                            <button @click="onOpenIDPageBtnClick" class="btn btn-warning" type="button">
                                {{ $t('識別值設定') }}
                            </button>
                        </div>
                        <hr />
                    </div>
                    <div class="table-responsive mt-10">
                        <table class="table table-hover table-bordered table-condensed table">
                            <tbody>
                                <tr class="bg-gray">
                                    <th>產品</th>
                                    <th>金流</th>
                                    <th>數量</th>
                                    <th>幣別</th>
                                    <th>總金額</th>
                                    <th>未配點</th>
                                    <th>已配點</th>
                                </tr>
                                <template v-if="list.length > 0">
                                    <tr v-for="item in list" :key="item.vendorName+item.payDetailType">
                                        <td>{{item.vendorName}}</td>
                                        <td>{{item.payDetailType}}</td>
                                        <td style="text-align: right;">{{formatNumber(item.totalCount)}}</td>
                                        <td>{{item.currency}}</td>
                                        <td style="text-align: right;">{{formatNumber(item.amount)}}</td>
                                        <td style="text-align: right;">{{formatNumber(item.consumptionState0)}}</td>
                                        <td style="text-align: right;">{{formatNumber(item.consumptionState1)}}</td>
                                    </tr>

                                    <tr v-for="(value, currency) in sum" :key="currency">
                                        <th>總計</th>
                                        <th>&nbsp;</th>
                                        <th style="text-align: right;">{{formatNumber(value.totalCount)}}</th>
                                        <th>{{currency}}</th>
                                        <th style="text-align: right;">{{formatNumber(value.amount)}}</th>
                                        <th style="text-align: right;">{{formatNumber(value.consumptionState0)}}</th>
                                        <th style="text-align: right;">{{formatNumber(value.consumptionState1)}}</th>
                                    </tr>
                                </template>
                                <template v-else>
                                    <tr>
                                        <td class="text-center" colspan="7">{{ $t('沒有資料') }}</td>
                                    </tr>
                                </template>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="overlay" ref="loading">
                    <i class="fa fa-refresh fa-spin"></i>
                    <div class="tip">{{ tip }}</div>
                </div>
            </div>
        </section>
        <FMSIDPage ref="FMSIDPage"  @updateIdList="updateIdList"></FMSIDPage>
    </div>
</template>
<script>
import moment from "moment";
import DateRangePicker from "@/components/DateRangePicker";
import api from "@/assets/js/app-api";
import FMSIDPage from "@/views/projects/ips/FMS/FMSIDPage";
import {  exportCSV } from '@/views/projects/ips/IPSTool2';
function delay(ms) {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve();
    }, ms);
  });
}
function isDateRangeShortThenDays(start, end, days) {
    const momentStart = moment(start);
    const momentEnd = moment(end);
    const rangeLength = momentEnd.diff(momentStart, 'days');
    return rangeLength < days;
}
export default {
    components: {
        DateRangePicker,
        FMSIDPage,
    },
    data() {
        return {
            seachLimitDay:31,
            searchData: {
                vendorId:"",
                paymentType:"",
                startTime: new moment().add(-7, "days").format("YYYY-MM-DD 00:00:00"),
                endTime: new moment().add(-1, "days").format("YYYY-MM-DD 23:59:59"),
            },
            companyList:[],
            paymentList:[],
            list: [],
            sum:{},
            lastQueryCommand: null,
            tip: "",
        };
    },
    computed: {
        paymentOptList: function() {
            return this.paymentList.filter((item)=>{return !item.paymentId.includes("_")})
        }
    },
    watch: {
    },
    mounted() {
        this.init();
    },
    methods: {
        updateIdList(res){
            this.companyList=res.companyList;
            this.paymentList=res.paymentList;
        },
        formatNumber(value) {
            return Intl.NumberFormat(this.$i18n.locale).format(value);
        },
        async init() {
            $(this.$refs.loading).show();
            try{
                this.companyList = (await api.IPSSetting.fms.SearchCompany({})).data.result;
                this.paymentList = (await api.IPSSetting.fms.SearchPaymentInfo({})).data.result;
            }catch(e){
                this.$eventBus.$emit("showAlert", `發生錯誤：${ (e && e.data && e.data.errorDetail) || e}`);
                this.companyList=[];
                this.paymentList=[];
            }
            $(this.$refs.loading).fadeOut(400);
        },
        onSearchBtnClick() {
            let send = JSON.parse(JSON.stringify(this.searchData));
            this.queryAll(send);
        },
        async queryAll(send) {
            if (!isDateRangeShortThenDays(send.startTime, send.endTime, this.seachLimitDay)) {
                this.$eventBus.$emit("showAlert", `查詢時間區間需小於${this.seachLimitDay}天`);
                return;
            }
            if (!send) {
                return;
            }
            $(this.$refs.loading).show();
            this.tip = "";
            try {
                this.list = (await api.IPSSetting.fms.GetStatistics(send)).data.result;
                this.sum={};
                for(let i of this.list){
                    let currency = i.currency;
                    if(!this.sum[currency]){
                        this.sum[currency]={
                            totalCount: 0,
                            consumptionState0: 0,
                            consumptionState1: 0,
                            amount: 0
                        };
                    }
                    this.sum[currency].totalCount+=i.totalCount;
                    this.sum[currency].consumptionState0+=i.consumptionState0;
                    this.sum[currency].consumptionState1+=i.consumptionState1;
                    this.sum[currency].amount+=i.amount;
                }
            } catch (e) {
                this.list=[];
                this.sum={};
                this.lastQueryCommand = null;
            }
            if (this.list.length > 0 ) {
                this.lastQueryCommand = { cmd: send };
            } else {
                this.lastQueryCommand = null;
            }
            $(this.$refs.loading).fadeOut(400);
        },
        onExportStatisticsReportBtnClick(){
            if (!(this.list.length>0)) {
                this.$eventBus.$emit("showAlert", `無資料可匯出，請重新查詢`);
                return;
            }
            // let parse = this.list.ma
            let transformedData = this.list.map(item => ({
                產品: item.vendorName,
                金流: item.payDetailType,
                數量: this.formatNumber(item.totalCount),
                幣別: item.currency,
                總金額: this.formatNumber(item.amount),
                未配點: this.formatNumber(item.consumptionState0),
                已配點: this.formatNumber(item.consumptionState1),                
            }));
            let keys = Object.keys(this.sum);
            for(let currency of keys){
                let value = this.sum[currency];
                transformedData.push({
                    產品: "總計",
                    金流: "",
                    數量: this.formatNumber(value.totalCount),
                    幣別: currency,
                    總金額: this.formatNumber(value.amount),
                    未配點: this.formatNumber(value.consumptionState0),
                    已配點: this.formatNumber(value.consumptionState1),       
                });
            }
            exportCSV(new Date().getTime(), transformedData);
        },
        async onExportReportBtnClick() {
            if (!this.lastQueryCommand) {
                this.$eventBus.$emit("showAlert", `無資料可匯出，請重新查詢`);
                return;
            }
            this.tip = `資料匯出中，請勿離開`;
            $(this.$refs.loading).show();
            try{
                let fileToken = (await api.IPSSetting.fms.GetDetail(this.lastQueryCommand.cmd)).data.result.fileToken;
                let downloadFileRes=null;
                const checkTimes = 120;
                let leftCheckTimes = checkTimes;
                do{
                    this.tip = `資料匯出中，請勿離開(${(checkTimes-leftCheckTimes)}/${checkTimes})`;
                    leftCheckTimes--;
                    downloadFileRes = (await api.IPSSetting.fms.GetDetailFileSchedule({
                        fileToken
                    })).data.result;
                    if(downloadFileRes.status=="waiting"){
                        await delay(5000);//繼續等                        
                    }else{
                        break;//跳出迴圈
                    }
                }while(leftCheckTimes>0);

                if(downloadFileRes.status=="success"){
                    let url = api.IPSSetting.fms.FMSDownloadData();
                    let fileUrl = `${url}${downloadFileRes.downloadPath}`;
                    const link = document.createElement('a');
                    link.href = fileUrl;
                    // link.download = ''; // 可选，设置下载文件名
                    document.body.appendChild(link); // 必须将链接添加到 DOM
                    link.click(); // 触发下载
                    document.body.removeChild(link); // 下载后移除链接

                }else{
                    throw `請後端確認產檔時間太久，檢查超過${checkTimes}次`;
                }
            }catch(e){
                this.$eventBus.$emit("showAlert", `發生錯誤：${ (e && e.data && e.data.errorDetail) || e}`);
            }

            this.tip = "";
            $(this.$refs.loading).fadeOut(400);
        },
        async onOpenIDPageBtnClick(){
            this.$refs.FMSIDPage.show();
        }
    },
};
</script>

<style lang='scss' scoped>
.overlay>.tip {
    text-align: center;
    position: relative;
    top: calc(50% + 3em);
    margin-left: -15px;
    margin-top: -15px;
}

.mb-5 {
    margin-bottom: 5px;
}

.mr-5 {
    margin-right: 5px;
}

.table th {
    word-wrap: break-word;
    word-break: keep-all;
    letter-spacing: -.05em;
    min-width: 50px;
}

.table td {
    padding-block: .5em;
    word-wrap: break-word;
    overflow-wrap: break-word;
}

.d-flex {
    display: flex;
    align-items: center;
}

.top-box .form-group {
    display: inline-block;
    vertical-align: top;
    margin: 0 10px 10px 0;
}

.top-box .form-group .control-label {
    padding-top: 7px;
    float: left;
}

.top-box .input-group {
    min-width: 250px;
}

.selPaymentType {
    width: 150px;
}

.table-card-box {
    .px-10 {
        padding: 0 10px;
    }

    margin: 0 auto;
    background: #fff;
    border-radius: 5px;
    overflow: hidden;
    position: relative;
    border: 1px solid #f4f4f4;

    @media(max-width: 991px) {
        width: 100% !important;
        max-width: 280px;
    }

    &:after {
        content: '';
        width: 2px;
        background: #337ab7;
        height: 100%;
        display: list-item;
        position: absolute;
        top: 0;
    }

}

.textAreas {
    display: flex;
    flex-wrap: nowrap;
}

.textAreas>textarea {
    margin-right: 10px;
    margin-bottom: 10px;
}

@media(max-width: 991px) {
    .textAreas {
        display: block;
    }

    .textAreas {
        margin: 0;
    }
}

</style>