<template>
<div class="modal fade" ref="DisputedImport">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <button aria-label="Close" class="close" data-dismiss="modal" type="button">
          <span aria-hidden="true">×</span>
        </button>
        <h4 class="modal-title">新增訂單</h4>
      </div>
      <div class="modal-body">
        <div class="table-responsive mt-10">
          <table class="table table-bordered table-striped" ref="uploadForm">
            <tbody>
              <!-- <tr>
                <td>
                  <div class="form-group">
                    <select class="form-control" required="required" v-model="importDataInfo.查詢目標" disabled="disabled">
                      <option  value>
                        請選訂單編號
                      </option>
                      <option value="1">
                        訂單編號
                      </option>
                       <option value="2">
                        金流訂單編號
                      </option>
                      <option value="3">
                        廠商訂單編號
                      </option> 
                    </select>
                  </div>
                </td>
              </tr> -->
              <tr>
                <td style="background: #f4f4f4">
                  <div class="form-group">
                    <textarea 
                      v-model="importDataInfo.訂單編號" 
                      class="form-control" 
                      rows="5" 
                      :placeholder="placeholder.msg">
                    </textarea>
                  </div>
                  <br />
                  <div class="form-group">
                      <textarea 
                        v-model="importDataInfo.備註" 
                        class="form-control" 
                        rows="3" 
                        placeholder="備註"></textarea>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                    <Button 
                      btnClass="btn-danger w-100" 
                      :name="$t('確定匯入')" 
                      :spinner="spinner.import" 
                      :disabled="importDataInfo.訂單編號 == '' || importDataInfo.查詢目標 == ''"
                      @click="importData(importDataInfo)" 
                      />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
  <!-- /.modal-content -->
</div>
<!-- /.modal-dialog -->
</template>

<script>
/* eslint-disable */
import utils from "@/assets/js/app-utils";
import xlsx from "xlsx";
import api from "@/assets/js/app-api.js";
import Button from "./Button";

export default {
  components: {
    Button,
  },
  props: {
    searchData: Object
  },
  data() {
    return {
      spinner: {
        import: false,
      },
      importDataInfo: {
        訂單編號: "",
        查詢目標: "1",
        備註: "",
      },
      placeholder: {
        msg: '格式範例: 1001320240816900023,1001320240816900022,1001320240816900021',
        tips: [
          '格式範例: 1001320240816900023,1001320240816900022,1001320240816900021',
          '格式範例: EI000003D1B5BAE21800,00000000766,2408191608314281',
          '格式範例: A20240820000003,A20240820000003,A20240820000003'
        ]
      },
    };
  },
  watch: {
    'importDataInfo.查詢目標' (value) {
      if (value) this.placeholder.msg = this.placeholder.tips[Number(this.importDataInfo.查詢目標) - 1]
    }
  },
  methods: {
    openDisputedModal(status) {
      this.$refs.DisputedImport.value = "";
      $(this.$refs.DisputedImport).modal(status);
      this.importDataInfo = {
        訂單編號: "",
        查詢目標: "1",
        備註: "",
      }
    },
    importData(data) {
      if (!data) return this.$eventBus.$emit("showAlert", '無攜帶相關資料');
      if (!data.查詢目標) return this.$eventBus.$emit("showAlert", '請選擇訂單編號');
      if (!data.訂單編號) return this.$eventBus.$emit('showAlert', '請輸入訂單編號');

      try {
        this.spinner.import = true;
        api.IPSSetting.DisputeOrderImport(data).then(async (res) => {
          if (res.data.status == "1") {
            this.openDisputedModal('hide')
            this.$emit("onPageChange", 1);
            if (res.data.content.status == '0') {
              const msg = res.data.content.message.replaceAll('\r\n', '<br>')
              this.$eventBus.$emit("showAlert", `${msg}`);
            } else if (res.data.content.status == '2') {
              const msg = res.data.content.errorDetail.errorMsg.replaceAll('\r\n', '<br>')
              this.$eventBus.$emit("showAlert", `${msg}`);
            } else {
              this.$eventBus.$emit("showAlert", '寫入成功');
            } 
            
          } else {
            this.$eventBus.$emit("showAlert", res.data.errorDetail[0].errorMsg);
          }
          this.spinner.import = false;
        }).catch((e) => {
            console.error(`匯入異常:`, e);
            this.spinner.import = false;
        });
      } catch (e) {
        console.error("匯入資料異常:", e);
        this.spinner.import = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.w-100 {
    width: 100% !important;
}

</style>
