<template>
  <div id="AnnouncementEdit">
    <section class="content-header">
      <h1>遊戲介紹{{ isEdit ? '編輯' : '新增' }}</h1>
      <ol class="breadcrumb">
        <li><router-link to="/">Home</router-link></li>
        <li><router-link :to="goBack">遊戲介紹{{ isEdit ? '編輯' : '新增' }}</router-link></li>
        <li class="active">{{ id }}</li>
      </ol>
    </section>
    <section class="content">
      <div class="box box-primary">
        <div class="box-body">
          <div class="top-box with-border">
            <h4 class="pull-left" id="title">{{ isEdit ? '編輯' : '新增' }}內容</h4>
            <!-- <p class="pull-left color-red ml-5 mt-10">*請依序編輯內容</p> -->
            <router-link :to="goBack" class="btn btn-block btn-default btn-cog pull-right">
              <i class="fa fa-reply"></i> 返回列表
            </router-link>
          </div>

          <form class="form-horizontal" name="announcementForm">
            <div class="box-body" style="max-width:1000px;">
              <div class="form-group">
                <label class="col-sm-2 control-label">
                  Gid：
                </label>
                <div class="col-sm-10">
                  <label class="control-label">{{ edit.gid }}</label>
                </div>
              </div>

              <div class="form-group">
                <label class="col-sm-2 control-label" for="inputTitle">
                  <span class="text-red">*</span> 遊戲編號：
                </label>
                <div class="col-sm-10">
                  <input type="text" class="form-control" id="inputTitle" v-model="edit.遊戲館" />
                </div>
              </div>

              <div class="form-group">
                <label class="col-sm-2 control-label" for="inputType">
                  <span class="text-red">*</span> 遊戲分類：
                </label>
                <div class="col-sm-10">
                  <select class="form-control select-part" id="inputType" v-model="edit.遊戲分類">
                    <option disabled hidden value>請選擇分類</option>
                    <option v-for="(item, index) in classTypes" :key="index" :value="item.classTypeId">
                      {{ item.className }}
                    </option>
                  </select>
                </div>
              </div>

              <div class="form-group">
                  <label class="col-sm-2 control-label" for="summary">
                      <span class="text-red">*</span> 遊戲簡介：
                  </label>
                  <div class="col-sm-10">
                      <textarea id="summary" class="form-control" v-model="edit.遊戲簡介" placeholder="內容建議100字內"></textarea>
                  </div>
              </div>

              <!-- <div class="form-group">
                <label class="col-sm-2 control-label" for="summary">
                  <span class="text-red">*</span>遊戲簡介：
                </label>
                <div class="col-sm-10">
                  <textarea class="form-control" id="summary" v-model="edit.遊戲簡介" placeholder="內容建議100字內"></textarea>
                </div>
              </div> -->

              <div>
                <div class="form-group image-part">
                  <label class="col-sm-2 control-label" for="imgLogo">
                    <span class="text-red">*</span> 遊戲館圖：
                  </label>
                  <div class="col-sm-10">
                    <ImageSelect id="imgLogo" ref="imageSelect"
                      :gid="edit.gid" info="logo" module="GameGlobal"
                      :maxsize="200*1024" accept="image/png"
                      v-model="edit.files">
                      <p class="help-block"> 小於200kb,背景透明png </p>
                    </ImageSelect>
                  </div>
                </div>
              </div>
              
              <hr>

              <div class="form-group">
                <label class="col-sm-2 control-label" for="inputTitle">
                  <span class="text-red" v-if="type.id == langs[0].id">*</span> 版本類型：
                </label>
                <div class="col-sm-10 btns" style="display:flex; flex-wrap:wrap; gap:5px;">
                  <button v-for="lang in langs" type="button" class="btn"
                    :class="{ 'btn-primary': type.id == lang.id }" @click="type = lang"
                  >{{ lang.name }}</button>
                </div>
              </div>

              <div class="form-group">
                <label class="col-sm-2 control-label" for="inputTitle">
                  <span class="text-red" v-if="type.id == langs[0].id">*</span> {{type.name}}名稱：
                </label>
                <div class="col-sm-10">
                  <input type="text" class="form-control" id="inputTitle" v-model="toggleName" />
                </div>
              </div>

              <div class="form-group">
                <label class="col-sm-2 control-label" for="Limits">
                  <span class="text-red" v-if="type.id == langs[0].id">*</span> {{type.name}}說明：
                </label>
                <div class="col-sm-10">
                  <div class="Limits editor"><tinymce v-model="toggleContent" height="600" /></div>
                </div>
              </div>

              <hr>

              <!-- 遊戲操作說明 (應該 value 是 id)-->
              <div class="form-group">
                <label class="col-sm-2 control-label">
                  <span class="text-red">*</span> 操作說明：
                </label>
                <div class="col-sm-10">
                  <select class="form-control select-part" v-model="edit.遊戲操作說明ID">
                    <option disabled="disabled" value>
                      請選擇操作說明版本
                    </option>
                    <option :key="item.gid" :value="item.gid" v-for="item in operationTypes">
                      {{ item.version }}
                    </option>
                  </select>
                  <div class="help-block">
                    *進入
                    <router-link :to="{name:'AWH5GameOperationList'}" target="_blank">遊戲操作說明列表</router-link>
                  </div>
                </div>
              </div>

              <div class="form-group">
                <label class="col-sm-2 control-label" for="inputOrderNum">
                  <span class="text-red">*</span> 排序：
                </label>
                <div class="col-sm-10">
                  <input class="form-control" id="inputOrderNum" type="number" v-model.number="edit.排序" />
                </div>
              </div>

              <div class="form-group">
                <label class="col-sm-2 control-label">
                  <span class="text-red">*</span> 發佈：
                </label>
                <div class="col-sm-10">
                  <div class="radio radio-inline">
                    <iCheckRedioButton :checkValue="true" message="上架" name="radioMode" v-model="edit.是否發佈" />
                  </div>
                  <div class="radio radio-inline">
                    <iCheckRedioButton :checkValue="false" message="下架" name="radioMode" v-model="edit.是否發佈" />
                  </div>
                </div>
              </div>

            </div>
            <div class="box-footer">
              <div class="btns">
                <button class="btn btn-warning btn-lg mr-10" type="button" @click="preview()">
                  <i class="fa fa-fw fa-eye"></i> 預覽
                </button>

                <button class="btn btn-success btn-lg mr-10" type="button" @click="Save(true)">
                  <i class="fa fa-fw fa-save"></i> 儲存
                </button>

                <button class="btn btn-primary btn-lg" type="button" @click="Save(false)">
                  <i class="fa fa-fw fa-check"></i> 確認送出
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>

      <!-- <pre>
        {{ classTypes }}
        {{ edit }}
      </pre> -->
    </section>
  </div>
</template>

<script>
/* eslint-disable */
import api from "@/assets/js/app-api";
import dfn from "./_Define";
import DatePicker from "@/components/DatePicker";
import iCheckCheckBox from "@/components/iCheck_CheckBox";
import iCheckRedioButton from "@/components/iCheckRedioButton";
import ImageSelect from "../XSG/components/ImageSelect";
import moment from "moment";
import utils from "@/assets/js/app-utils";
import tinymce from "@/components/Tinymce";
import config from "@/assets/js/app-config";
import draggable from "vuedraggable";

class Lang {
  constructor(lang){
    this.id = 0;
    this.language_type = lang;
    this.name = "";
    this.content = "";
  }
}
class Image {
  constructor(info){
    this.id = 0;
    this.info = info;
    this.filesUrl = "";
  }
}

export default {
  components: {
    iCheckCheckBox,
    iCheckRedioButton: iCheckRedioButton,
    DatePicker,
    moment,
    tinymce,
    ImageSelect
  },
  props: {
    id: ""
  },
  data() {
    return {
      langs: dfn.langs,
      type: dfn.langs[0],

      edit: {
        gid: utils.getTimeId(),
        遊戲分類: "",
        遊戲簡介: "",
        遊戲操作說明ID: "",
        遊戲館: "",
        排序: 1,
        是否發佈: true,
        files: [],
        languageList: [...dfn.langs].map(type => new Lang(type.id)),
      },
      imgFile: null,
      newTypeClass: [],
      typeClassID: "",

      classTypes: [],

      //Operation
      gameOperationSearch: {
        pageNum: 1,
        pageSize: 100 //每頁顯示筆數
      },
      operationTypes: [],

      checkInterval: 0,
    };
  },
  created() {
    this.getClassTypes();
    this.getGameOperationList();
    if (this.isEdit) {
      this.GetGameIntro();
    }

    this.checkInterval = setInterval(() => {
      this.$store.dispatch("news/newsCheck")
    }, 2*60*1000 );
  },
  beforeDestroy(){
    clearInterval(this.checkInterval);
  },
  mounted() {
    // this.clearCatch();
  },
  computed: {
    isEdit() {
      return this.id > 0 && this.id != "New";
    },
    goBack(){
      return {name:'AWH5GameIntroList'}
    },

    imgLogo: {
      get(){
        let img = this.edit.files.find(item => item.info === "logo");
        if (img) return img.filesUrl;
        else {
          this.edit.files.unshift(new Image("logo"));
          return this.edit.files[0].filesUrl;
        }
      },
      set(value){
        let img = this.edit.files.find(item => item.info === "logo");
        if (img && (value != "" || img.id == 0)) this.$set(img, "filesUrl", value);
        else {
          this.edit.files.unshift(new Image("logo"));
          this.$set(this.edit.files[0], "filesUrl", value);
        }
      }
    },

    toggleContent: {
      get() {
        let lang = this.edit.languageList.find(item => item.language_type === this.type.id);
        if (lang){ 
          return lang.content;
        } else {
          this.edit.languageList.push(new Lang(this.type.id));
          return this.edit.languageList[this.edit.languageList.length - 1].content;
        }
      },
      set(value) {
        let item = this.edit.languageList.find(item => item.language_type === this.type.id);
        this.$set(item, "content", value);
        // console.log("SET", item.content, value);
      }
    },
    toggleName: {
      get() {
        let lang = this.edit.languageList.find(item => item.language_type === this.type.id);
        if (lang){ 
          return lang.name;
        } else {
          this.edit.languageList.push(new Lang(this.type.id));
          return this.edit.languageList[this.edit.languageList.length - 1].name;
        }
      },
      set(value) {
        let item = this.edit.languageList.find(item => item.language_type === this.type.id);
        this.$set(item, "name", value);
        // console.log("SET", item.name, value);
      }
    },
  },
  watch: {
  },
  methods: {
    getClassTypes(){
      this.$store.dispatch("xsw/分類列表明細查詢", {module:"game", isDelete:0, isStop:0}).then(res => {
        this.classTypes = Array.isArray(res) ? res.map(x => Array.isArray(x.detail_model) ? x.detail_model.find(y => y.lang == "zh-TW") : {}) : [];
      });
    },

    //取得該遊戲介紹資料
    GetGameIntro() {
      this.$store.dispatch("xsw/遊戲介紹查詢", {
        gid: this.id
      }).then((result) => {
        let data = result;
        data.files.sort((a, b) => b.id - a.id);
        // console.log(data.files);
        this.edit = data;
      }, err => {
        this.$eventBus.$emit("showAlert", err);
      });
    },

    //取得 遊戲操作說明 資料
    getGameOperationList() {
      this.$store.dispatch("xsw/遊戲操作說明列表查詢", this.gameOperationSearch).then(result => {
        this.operationTypes = result.datas || [];
      });
    },

    // clear
    clearCatch() {
      this.$store.dispatch("xsw/GameIntroClear").then(result => {
        console.log("已清除Catch");
      }, err => {
        this.$eventBus.$emit("showAlert", err);
      });
    },

    //送出
    async Save(isPreview = false) {
      let err = [];

      if (this.edit.遊戲館 == "") err.push("請填寫 遊戲編號");
      if (this.edit.遊戲分類 == "") err.push("請選擇 遊戲分類");
      if (this.edit.遊戲簡介 == "") err.push("請填寫 遊戲簡介");
      if (this.edit.files[0].filesUrl == "") err.push(`請上傳 遊戲館圖`);

      let defaultContent = this.edit.languageList.find(item => item.language_type == this.langs[0].id);
      if (!defaultContent || defaultContent.name == ""){
        err.push(`請填寫 ${this.langs[0].name} 名稱`);
      } 
      if (!defaultContent || defaultContent.content == ""){
        err.push(`請填寫 ${this.langs[0].name} 說明`);
      } 

      if (err.length > 0){
        this.$eventBus.$emit("showAlert", err.join('<br>'));
        return;
      }

      let api = this.isEdit ? "修改" : "新增";
      let sendEdit = Object.assign({}, this.edit, {files:undefined});

      this.$store.dispatch("xsw/遊戲介紹" + api, sendEdit).then(result => {
        Promise.all([
          this.$refs.imageSelect.upload(),
        ]).then(res => {
          if (isPreview){
            this.$eventBus.$emit("showAlert", api + "成功");
            if (this.isEdit){ this.GetGameIntro() }  // 拉新files路徑，避免重複上傳
            else this.$router.replace({params:{id:this.edit.gid}}).then(r => {
              this.GetGameIntro(); // 拉新files路徑，避免重複上傳
            })
          } else {
            this.$eventBus.$emit("showAlert", api + "成功");
            this.backList();
          }
        }, err => {
          this.$eventBus.$emit("showAlert", err);
        });
      }, error => {
        this.$eventBus.$emit("showAlert", error);
      });
      
    },

    //*----預覽
    preview() {
      let width = 500;
      let height = 840;
      let left = (screen.width - width) / 2;
      let top = (screen.height - height) / 2;
      // console.log(width, height, left, top)

      window.open(
        `${process.env.VUE_APP_APEXGameAPI_PATH}/ApexWin`,
        "ApexWinPreview",
        `popup,top=${top},left=${left},width=${width},height=${height}`
      );
      
      let previewFile = new File([this.toggleContent], '1', {type:'text/html'});
      let dataTransfer = new DataTransfer();
      dataTransfer.items.add(previewFile);
      
      let form = document.createElement("form");
      let input = document.createElement('input');
      form.action = `${process.env.VUE_APP_APEXGameAPI_PATH}/ApexWin`;
      form.method = "POST";
      form.enctype = "multipart/form-data";
      form.target = "ApexWinPreview";
      form.hidden = true;
      input.name = "preview";
      input.type = "file";
      input.files = dataTransfer.files;
      form.appendChild(input);
      document.body.appendChild(form);
      form.submit();
      form.remove();
    },

    //*---- 返回
    backList() {
      this.$router.push({
        name: "AWH5GameIntroList",
        params: { searchData: this.$route.params.searchData }
      });
    },
  }
};
</script>

<style scoped>
fieldset:required label::before{
    content: "*";
    color: #dd4b39;
}

.d-inline {
  display: inline-block;
}

/* .chooseBox .img-close {
  right: -10px !important;
  z-index: 9 !important;
} */

.gameGallery {
  display: flex;
  flex-wrap: wrap;
}

.gameGallery .chooseBox {
  margin-bottom: 10px;
}

.gameGallery .chooseBox img {
  background: #ccc;
}

.chooseBox .img-close {
  right: 5px !important;
}

#DisconnectionRules .disconContnet {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  border-bottom: 1px solid #f4f4f4;
  padding-top: 10px;
  position: relative;
}


#DisconnectionRules .disconContnet .form-control {
  margin-right: 10px;
  margin-bottom: 10px;
  width: auto;
  min-width: 140px;
}

#DisconnectionRules .disconContnet .d-c {
  width: 80%;
}

#DisconnectionRules .disconContnet .d-c {
  width: 80%;
}

#DisconnectionRules .disconContnet button {
  position: absolute;
  right: 0;
  width: 80px;
  bottom: 0;
}
</style>
