<template>
  <div id="MemberData">
    <section class="content-header">
      <h1>{{$t('公會明細查詢')}}</h1>
      <ol class="breadcrumb">
        <li><router-link to="/">{{$t('首頁')}}</router-link></li>
        <li class="active">{{$t('公會明細查詢')}}</li>
      </ol>
    </section>
    <section class="content" style="min-height:auto;">
      <div class="box tab-warning">
        <div class="box-body">
          <form class="top-box clearfix" @submit.prevent="getData()">
            <div class="pull-left mb-10 mr-10">
              <button type="button" class="btn btn-primary" @click="exportMembers()" v-if="can成員" :disabled="!details.主要公會">
                {{$t('匯出成員列表')}}
              </button>
            </div>
            <router-link :to="{name:'XswGuildData'}" class="btn btn-default pull-right mb-10 ml-10">
              <i class="fa fa-reply"></i> {{$t('返回列表')}}
            </router-link>
          </form>
          <p v-if="loading" class="text-center">{{$t('查詢中')}}</p>
          <p v-else-if="message" class="text-center">{{ $t(message) }}</p>
          <p v-else-if="!details.主要公會" class="text-center">{{ $t('查無資料') }}</p>
          <div v-else>
            <template v-for="items, name in details">
              <h3 class="mt-0">{{ $t( name ) }}</h3>
              <!-- <p v-if="items.length == 0">{{ $t('無') }}</p> -->
              <div class="table-responsive">
                <table class="table table-condensed table-bordered text-center mb-20">
                  <thead>
                    <tr class="bg-gray">
                      <th style="min-width:120px; width: 25%">{{$t('公會名稱')}}</th>
                      <th style="min-width:120px; width: 25%">{{$t('會長')}}</th>
                      <th style="min-width:120px; width: 25%">{{$t('金庫')}}</th>
                      <th style="min-width:120px; width: 25%">{{$t('回饋歷程')}}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-if="items.length <= 0 || !items[0].公會名稱">
                      <td colspan="10" class="bg-gray-light">{{ $t('無') }}</td>
                    </tr>
                    <tr v-else v-for="item in items" :key="item.公會名稱">
                      <td>{{ item.公會名稱 || '-' }}</td>
                      <td>
                        <span v-if="projectId == 'omp_xin_my'">{{ item.公會會長 || '-' }}</span>
                        <router-link v-else :to="{name:'XswMemberData', query:{nick:item.公會會長}, params:{back:$route}}">{{ item.公會會長 || '-' }}</router-link>
                      </td>
                      <td>{{ Number(item.金庫).toLocaleString(displayLang) }}</td>
                      <td>
                        <button type="button" @click="showReview(item.公會會長);" class="btn btn-danger">{{$t('回饋歷程')}}</button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </template>
          </div>
        </div>
      </div>
    </section>


    <!-- <pre>{{ details }}</pre> -->
     
    <FormDialog ref="reviewDialog" :title="$t('回饋歷程')">
      <form class="top-box clearfix" @submit.prevent=" searchReview.pageNum = 1; getReview();">
        <div class="form-group m-0">
          <label class="control-label pull-left mt-7">{{$t('查詢日期')}}：</label>
          <div class="input-group date pull-left mb-10 mr-10">
            <div class="input-group-addon fa fa-calendar"></div>
            <DatePicker :propValue.sync="searchReview.日期" />
          </div>
          <button type="submit" class="btn btn-primary pull-left mb-10">{{$t('查詢')}}</button>
        </div>
      </form>
      <!-- <p v-show="reviewTotal == 0" class="text-center">{{ reviewDate }} {{ $t('無回饋') }}</p> -->
      <table class="table table-bordered table-condensed table-striped">
        <tbody>
          <tr class="bg-gray">
            <th style="width:50%;">{{$t('會員')}}</th>
            <th style="width:50%;">{{$t('回饋金額')}}</th>
          </tr>
          <tr v-if="pagedReview.length == 0"><td colspan="2" class="text-center">{{ reviewDate }} {{ $t('無回饋') }}</td></tr>
          <tr v-else v-for="item in pagedReview">
            <td>{{ item.分潤暱稱 }}</td>
            <td>{{ Number(item.金額).toLocaleString(displayLang) }}</td>
          </tr>
          <tr class="bg-gray-light">
            <th>{{$t('總計')}}</th>
            <th>{{ Number(review.金額).toLocaleString(displayLang) }}</th>
          </tr>
        </tbody>
      </table>
      <div v-show="reviewTotal > reviewPageSize" class="text-center mt-10">
        <Pagination :pageLimitCount="reviewPageSize" :total="reviewTotal" ref="pagination"
          v-on:pageMethod="onPageChange" />
      </div>
      <template slot="footer">
        <button class="btn btn-primary" style="min-width:8em" data-dismiss="modal">{{ $t('確定') }}</button>
      </template>
    </FormDialog>
    <!-- <FormDialog ref="addDialog" :title="$t('創建公會')" @submit="addGuild" @hide="edit = {會長:''}">
      <table class="table table-bordered">
        <tbody>
          <tr>
            <th v-if="projectId == 'omp_xin_my'">{{ $t('玩家UID') }}</th>
            <th v-else>{{$t('玩家暱稱')}}</th>
            <td><input class="form-control" type="text" v-model="edit.會長" :placeholder="projectId == 'omp_xin_my' ? $t('輸入玩家UID') : $t('輸入玩家暱稱')" required></td>
          </tr>
        </tbody>
      </table>
    </FormDialog> -->
    <!-- <FormDialog ref="removeDialog" :title="$t('解散公會')" @submit="removeGuild" @hide="editRemove = {}">
      <table class="table table-bordered">
        <tbody>
          <tr>
            <th>{{$t('會長')}}</th>
            <td>{{ editRemove.會長 }}</td>
          </tr>
          <tr>
            <th>{{$t('公會名稱')}}</th>
            <td>{{ editRemove.名稱 }}</td>
          </tr>
          <tr>
            <th>{{$t('解散原因')}}</th>
            <td><textarea class="form-control" type="text" v-model="editRemove.解散原因" :placeholder="$t('原因')" rows="3" required></textarea></td>
          </tr>
        </tbody>
      </table>
    </FormDialog> -->
  </div>
</template>

<style lang="scss" scoped>
  table.table>tbody>tr {
    word-break: break-word;
  }
  table.table>thead>tr>th {
    word-break: keep-all;
  }
</style>
<style scoped>
  .table>>>mark{
    padding: 0;
    background: #ff8;
  }
</style>

<script>
/* eslint-disable */
import dfn from "@/views/projects/XSW/_Define";
import moment from "moment";
import DatePicker from "@/components/DatePicker";
import FormDialog from "./components/FormDialog.vue";
import Pagination from "@/components/Pagination";
import router from "../../../router";
import utils from "@/assets/js/app-utils";
import { RouterLink } from "vue-router";

export default {
  components: {
    DatePicker,
    FormDialog,
    Pagination,
    router
  },
  props: {
    id: {required:true},
  },
  data() {
    return {
      projectId: this.$store.state.projectId,

      loading: false,
      message: "",
      details: {},
      review: { 項目:[] },
      reviewDate: "",
      reviewPageSize: 1,
      reviewPageNum: 1,
      reviewTotal: 0,

      searchReview: {
        "暱稱": "",
        "日期": new moment().add(-1, "days").format("YYYY-MM-DD 00:00:00"),
      },

      // 權限
      // can建立: this.$user.hasPermission(dfn.permission.公會建立),
      // can解散: this.$user.hasPermission(dfn.permission.公會解散),
      can回饋: this.$user.hasPermission(dfn.permission.公會回饋歷程查詢),
      can成員: this.$user.hasPermission(dfn.permission.公會成員列表查詢),
    };
  },
  async created() {
    this.getData();
  },
  watch: {
  },
  computed:{
    displayLang(){
      return this.$root.$options.i18n.locale;
    },
    pagedReview: function(){
      return this.review.項目.slice((this.reviewPageNum - 1) * this.reviewPageSize, (this.reviewPageNum) * this.reviewPageSize);
    }
  },
  methods: {
    async onPageChange(pageIdx, pageSize) {
      this.reviewPageNum = pageIdx;
      this.reviewPageSize = pageSize;
    },
    getData() {
      this.loading = true;
      this.$store.dispatch("xsw/公會明細", { 公會ID: this.id.toString() }).then(res => {
        this.message = "";
        let data = {
          "主要公會": res.主要公會 ? [res.主要公會] : [],
          "上級公會": res.上級公會 ? [res.上級公會] : [],
          "下級公會": res.下級公會 ? res.下級公會 : []
        };
        this.details = data;
        this.loading = false;
      }, err => {
        this.message = err;
        this.details = {};
        this.loading = false;
      });
    },
    showReview(nickname){
      this.getReview(nickname);
    },
    getReview(nickname){
      this.searchReview.暱稱 = nickname ? nickname : this.searchReview.暱稱;
      this.searchReview.日期 = new moment(this.searchReview.日期).format("YYYY-MM-DD");
      this.$store.dispatch("xsw/PlayerRebateQuery", this.searchReview)
      .then(res => {
        this.review = {
          "暱稱": res && res.暱稱 ? res.暱稱 : "",
          "金額": res && res.金額 ? res.金額 : 0,
          "項目": res && res.項目 ? res.項目 : [],
        };
        this.reviewDate = this.searchReview.日期;
        this.reviewTotal = this.review.項目.length;
        this.$refs.reviewDialog.show();
      }, err => {
        this.$eventBus.$emit("showAlert", err);
      });
    },
    exportMembers(){
      this.$store.dispatch("xsw/公會成員列表查詢", {公會ID: this.id.toString() })
      .then(res => {
        let arr = res || [];
        arr = arr.map(x => { return {'玩家暱稱': x}});
        utils.exportData(
          arr,
          `${this.details.主要公會[0].公會名稱}_${utils.formatTime(new Date(), 'YYYYMMDD')}_公會成員列表.xlsx`,
          `${this.details.主要公會[0].公會名稱}_公會成員`
        );
      }, err => {
        this.$eventBus.$emit("showAlert", err);
      });
    },
    exportDetails() {
        let data = utils.deepCloneObj(this.details);
        let items = [];
        data.forEach(item => {
          // console.log(item);
            items.push({
                '公會ID': item.id.toString(),
                '公會名稱': item.名稱,
                '會長': item.會長,
                '會員數': item.會員數,
                '戰力(積分)': item.積分,
                '建立時間': this.uiDate(new Date(item.id).toISOString().replace('Z','')),
            });
        });
        utils.exportData(
            items,
            `${utils.formatTime(new Date(), 'YYYYMMDD')}_公會列表.xlsx`,
            '公會列表'
        );
    },
    uiDate(date) {
      let d = new Date(date);
      if (d != "Invalid Date") return d.toLocaleString('zh-TW', {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        hourCycle: "h23",
      });
      else return date;
    }
  },
};
</script>