<template>
  <div id="MemberModerate" class="tab-content">
    <form class="top-box clearfix" @submit.prevent="searchData.pageNum = 1; getData()">
      <div class="form-group m-0">
        <label class="control-label pull-left mt-7">{{$t('查詢區間')}}：</label>
        <div class="input-group date pull-left mb-10 mr-10">
          <div class="input-group-addon fa fa-calendar"></div>
          <DateRangePicker :propStartDate.sync="searchData.開始時間" :propEndDate.sync="searchData.結束時間" :propTimePicker="false" />
        </div>
        <button type="submit" class="btn btn-primary pull-left mb-10">{{$t('查詢')}}</button>
      </div>
      <div v-if="can控管" class="pull-right" style="width:max-content; margin:0 auto;">
        <button type="button" class="btn btn-info" @click="$refs.dialog.show(user)">
          <i class="fa fa-gavel"></i> {{$t('控管')}}
        </button>
        <button type="button" class="btn btn-danger ml-5" @click="$refs.dialog.show(user, true)"
          v-show="banStatus() != banStatus(user)"><i class="fa fa-unlock"></i> {{$t('解鎖')}}
        </button>
      </div>
    </form>
    <div class="table-responsive mb-10">
      <table class="table table-bordered text-center">
        <thead>
          <tr class="bg-gray">
            <th style="min-width: 65px; width: 5%">{{$t('編號')}}</th>
            <!-- <th style="min-width: 80px; width: 5%">{{$t('控管類型')}}</th> -->
            <th style="min-width:100px; width: 5%">{{$t('控管資訊')}}</th>
            <th style="min-width:100px; width: 5%">{{$t('狀態')}}</th>
            <th style="min-width:100px; width: 5%">{{$t('限制鎖定')}}</th>
            <th style="min-width:100px; width:10%">{{$t('期限')}}</th>
            <th style="min-width:100px; width:10%">{{$t('服務類型')}}</th>
            <th style="min-width:100px; width:15%">{{$t('原因')}}</th>
            <th style="min-width:100px; width:10%">{{$t('操作時間')}}</th>
            <th style="min-width: 80px; width: 5%">{{$t('操作人員')}}</th>
            <!-- <th style="min-width: 80px; width: 5%">{{$t('功能')}}</th> -->
          </tr>
        </thead>
        <tbody>
          <tr v-if="loading"><td colspan="11">{{$t('查詢中')}}</td></tr>
          <tr v-else-if="message"><td colspan="11">{{ $t(message) }}</td></tr>
          <tr v-else-if="total === 0"><td colspan="11">{{$t('查無紀錄')}}</td></tr>
          <tr v-else v-for="(item, index) in datas" :key="item.玩家異動紀錄ID">
            <td>{{ item.玩家異動紀錄ID }}</td>
            <!-- <td>{{ item.門號 ? "門號" : "暱稱" }}</td> -->
            <td>{{ /*item.門號 ||*/ user.暱稱 }}</td>
            <td>{{ /雙平台鎖交易|星幣鎖交易/.test(item.異動動作) ? '' : $t(item.異動動作) }}</td>
            <td>{{ /雙平台鎖交易|星幣鎖交易/.test(item.異動動作) ? $t(item.異動動作) : '' }}</td>
            <td>{{ uiDate(item.新內容.控管期限) || uiDate(item.新內容.期限) }}</td>
            <td>{{ item.新內容.服務類型 }}</td>
            <td>{{ item.新內容.服務原因 || item.新內容.原因 }}</td>
            <td>{{ uiDate(item.異動時間) }}</td>
            <td>{{ item.異動者 }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div v-if="total > searchData.pageSize" class="text-center mt-10">
      <Pagination :pageLimitCount="searchData.pageSize" :total="total" @pageMethod="onPageChange" />
    </div>
    
    <!-- <pre style="line-height:1.1;">{{ datas }}</pre> -->

    <MemberModerateDialog ref="dialog" :types="types" @update="getData(); $emit('update')"></MemberModerateDialog>
  </div>
</template>

<style lang="scss" scoped>
/* eslint-disable */

</style>

<script>
/* eslint-disable */
import dfn from "@/views/projects/XSW/_Define";
import moment from "moment";
import DateRangePicker from "@/components/DateRangePicker";
import MemberModerateDialog from "./MemberModerateDialog";
import Pagination from "@/components/Pagination";
import utils from "@/assets/js/app-utils";

export default {
  components: {
    DateRangePicker,
    MemberModerateDialog,
    Pagination,
  },
  emits: ["update"],
  props:{
    user: {type:Object, required:true},
    types: {type:Object, required:true},
  },
  data() {
    return {
      searchData: {
        玩家識別值: "",
        開始時間: new moment().subtract(1, "month").format("YYYY-MM-DD 00:00:00"),
        結束時間: new moment().format("YYYY-MM-DD 23:59:59"),
        pageNum: 1,
        pageSize: 10,
      },
      message: "", //錯誤訊息
      loading: false,
      total: 0, //總筆數
      datas: [],

      // 權限
      can控管: this.$user.hasPermission(dfn.permission.會員控管),
    };
  },
  async created() {
    // this.getData();
    // this.getTypes();
    // window.v = this;
  },
  watch: {
    "user.玩家識別值": {
      handler(value){
        this.searchData.玩家識別值 = value;
        this.searchData.pageNum = 1;
        this.getData();
      },
      immediate: true,
    }
  },
  computed:{
    
  },
  methods: {
    async onPageChange(pageIdx, pageSize) {
        this.searchData.pageNum = pageIdx;
        this.searchData.pageSize = pageSize;
        this.getData();
    },
    getData() {
      this.loading = true;
      this.$store.dispatch("xsw/會員控管記錄", this.searchData)
      .then( res => {
        let data = res.datas || [];
        data.map(x => {
          try { x.新內容 = JSON.parse(x.新內容); }
          catch { x.新內容 = {}; }
        });
        this.datas = data;
        this.total = res.total || 0;
        this.message = "";
        this.loading = false;
      }, err => {
        this.datas = [];
        this.total = 0;
        this.message = err;
        this.loading = false;
      });
    },
    banStatus(user){
      let OK = this.$t('正常');
      if (user === undefined) return `<p class="text-green">${ OK }</p>`;
      let banText = getStatus.call(this, 0, user.停權狀態, user.停權截止);
      let muteText = getStatus.call(this, 1, user.禁言狀態, user.禁言截止);
      let htmlString = "";
      if (banText != OK) htmlString += `<p class="text-red">${ banText }</p>`;
      if (muteText != OK) htmlString += `<p class="text-red">${ muteText }</p>`;
      if (user.星幣鎖交易) htmlString += `<p class="text-orange">${ this.$t('星幣鎖交易') }</p>`;
      if (user.雙平台鎖購買) htmlString += `<p class="text-orange">${ this.$t('雙平台鎖購買') }</p>`;
      if (htmlString == "") htmlString = `<p class="text-green">${ OK }</p>`;
      return htmlString;

      function getStatus(banType = 0, banState = 0, endTime = 0){
        banState = banState.toString();
        if (banState == 1 && Date.now() > new Date(endTime).valueOf()) return OK;
        if (banType == 0) switch(banState){
          case "1": return this.$t("停權至x", [new moment(endTime).format('M/D H:mm')]);
          case "2": return this.$t("停權(終止契約)");
          case "3": return this.$t("停權(暫時凍結)");
          default: return OK;
        }
        if (banType == 1) switch(banState){
          case "1": return this.$t("禁言至x", [new moment(endTime).format('M/D H:mm')]);
          case "2": return this.$t("永久禁言");
          default: return OK;
        }
        return "";
      }
    },
    get內容(item){
      if (!item.新內容) return "";
      if (!/^{.*}$/.test(item.新內容)) return item.新內容;

      let obj = JSON.parse(item.新內容);
      if (obj) return obj.內容 || obj.備註 || obj.原因 || obj.服務原因 || item.新內容;
    },
    uiDate(date) {
      let d = new Date(date);
      if (d == "Invalid Date") return date;
      else return d.toLocaleString('zh-TW', {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        hourCycle: "h23",
      });
    },
  },
};
</script>