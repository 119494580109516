<template>
    <div>
        <section class="content-header">
            <h1>{{ $t('爭議訂單紀錄') }}</h1>
            <ol class="breadcrumb">
                <li><router-link to="/">{{ $t('首頁') }}</router-link></li>
                <li class="active">{{ $t('爭議訂單紀錄') }}</li>
            </ol>
        </section>
        <section class="content">
            <div class="box box-primary">
                <div class="box-body">
                    <div class="top-box">
                        <div class='form-type'>
                            <div class="form-group">
                                <select class="form-control" v-model.trim="searchData.服務編號">
                                    <option value="">{{ $t('選擇全部廠商') }}</option>
                                    <option v-for="item in serverList" :key="item.廠商編號" :value="item.廠商編號">{{ item.名稱 }}
                                    </option>
                                </select>
                            </div>
                            <div class="form-group">
                                <select class="form-control" v-model="searchData.交易方式">
                                    <option value="">{{ $t('選擇全部金流') }}</option>
                                    <option v-for="item in IPSList" :key="item.金流代碼" :value="item.金流代碼">
                                        {{ item.金流名稱 }}
                                    </option>
                                </select>
                            </div>
                            <div class="form-group">
                                <input type="text" class="form-control" :title="$t('玩家識別值')"
                                    :placeholder="$t('玩家識別值')" v-model.trim="searchData.玩家識別值" 
                                    />
                            </div>
                        </div>
                        <div class='form-type'>
                            <div class="form-group">
                                <input type="text" class="form-control" :title="$t('請輸入訂單編號')"
                                    :placeholder="$t('請輸入訂單編號')" v-model.trim="searchData.訂單編號"
                                    :disabled="searchData.廠商訂單編號 != '' || searchData.上游廠商訂單編號 != ''" />
                            </div>
                            <div class="form-group">
                                <input type="text" class="form-control" :title="$t('請輸入金流訂單編號')"
                                    :placeholder="$t('請輸入金流訂單編號')" v-model.trim="searchData.廠商訂單編號"
                                    :disabled="searchData.訂單編號 != '' || searchData.上游廠商訂單編號 != ''"/>
                            </div>
                            <div class="form-group">
                                <input type="text" class="form-control" :title="$t('請輸入廠商訂單編號')"
                                    :placeholder="$t('請輸入廠商訂單編號')" v-model.trim="searchData.上游廠商訂單編號"
                                    :disabled="searchData.訂單編號 != '' || searchData.廠商訂單編號 != ''"/>
                            </div>
                        </div>
                        <hr class='mt-0 mb-0'/>
                        <div class='form-type'>
                            <div class="form-group">
                                <label for="trade" class='w-100'>
                                    <div class='d-flex justify-content-between'>
                                        <small class='mb-5'>{{ $t('交易日期') }}：</small>
                                        <input style='width: 20px' class='pt-0 mb-5' type="radio" id="trade" value="trade" v-model="dateSearchType">
                                    </div>

                                    <div class="input-group date w-100">
                                        <div class="input-group-addon"><i class="fa fa-calendar"></i></div>
                                        <div class="pull-right w-100" >
                                            <DateRangePicker v-bind:propStartDate.sync="searchData.交易開始時間"
                                                v-bind:propEndDate.sync="searchData.交易結束時間" ref="tradeDateRangePicker" />
                                        </div>
                                    </div>
                                </label>
                            </div>
                            <div class="form-group">
                                <label class="control-label import pt-0 w-100">
                                    <div class='d-flex justify-content-between'>
                                        <small class="control-label import pt-0 mb-5">{{ $t('匯入日期') }}：</small>
                                        <input style='width: 20px' class='pt-0 mb-5' type="radio" id="import" value="import" v-model="dateSearchType">
                                    </div>
                                    <div class="input-group date w-100">
                                        <div class="input-group-addon"><i class="fa fa-calendar"></i></div>
                                        <div class="pull-right w-100">
                                            <DateRangePicker v-bind:propStartDate.sync="searchData.建立開始時間"
                                                v-bind:propEndDate.sync="searchData.建立結束時間" ref="importDateRangePicker" />
                                        </div>
                                    </div>
                                </label>
                            </div>
                            <div class='form-group'></div>
                        </div>
                        <div class='form-type'>
                            <div class='form-group'>
                                <div class='btn-group'> 
                                    <Button 
                                        btnClass='btn-primary'
                                        icon="fa fa-fw fa-search" 
                                        :name="$t('查詢')"
                                        :spinner="spinner.search"
                                        @click="onPageChange(1)"
                                    />

                                    <router-link class='btn btn-info' to="/IPS/HistoryManage#DisputedOrder">
                                        <i class="fa fa-fw fa-file-text"></i>
                                        {{$t('操作歷程')}}
                                    </router-link>

                                    <Button 
                                        btnClass='btn-success'
                                        icon="glyphicon glyphicon-export" 
                                        :name="$t('匯出')"
                                        :spinner="spinner.export"
                                        :disabled="spinner.export || list.length == 0"
                                        @click="exportReport"
                                    />

                                    <Button 
                                        btnClass='btn-warning'
                                        icon="glyphicon glyphicon-import" 
                                        :name="$t('新增')"
                                        :spinner="spinner.import"
                                        :disabled="spinner.import"
                                        @click="openDisputedModal"
                                    />
                                </div>
                            </div>
                        </div>
                        <hr class='mt-5 mb-5'/>
                        <span class='mr-20'>筆數：{{ total }}</span><span>{{ sum }}</span>
                    </div>
                    <!-- 表單 -->
                    <div class="table-responsive mt-10">
                        <table class="table table-hover table-bordered table-condensed table min-1600">
                            <tbody>
                                <tr class="bg-gray">
                                    <th>{{ $t('廠商') }}</th>
                                    <th>{{ $t('編號') }}</th>
                                    <th>{{ $t('使用者資訊') }}</th>
                                    <th>{{ $t('交易資訊') }}</th>
                                    <th>{{ $t('金額') }}</th>
                                    <th>{{ $t('時間') }}</th>
                                    <th>{{ $t('備註') }}</th>
                                    <th>{{ $t('功能') }}</th>
                                </tr>
                                <template v-if="list.length > 0">
                                    <tr v-for="item in list" class="text-center" :key="item.訂單編號">
                                        <td>
                                            <div class='table-card-box text-left'>
                                                <div class='px-10'>
                                                    <b>{{$t('服務編號')}}：</b>{{ item.服務編號 }} 
                                                </div>
                                                <div class='px-10'>
                                                    <b>{{$t('廠商名稱')}}：</b>{{serverList.filter(a => a.廠商編號 == item.服務編號)[0].名稱}}
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <div class='table-card-box text-left'>
                                                <div class='px-10'>
                                                    <b>{{$t('訂單編號')}}：</b>{{ item.訂單編號 ? item.訂單編號 : '-' }} 
                                                </div>
                                                <div class='px-10'>
                                                    <b>{{$t('金流訂單編號')}}：</b>{{ item.廠商訂單編號 ? item.廠商訂單編號 : '-' }} 
                                                </div>
                                                <div class='px-10'>
                                                    <b>{{$t('廠商訂單編號')}}：</b>{{ item.上游廠商訂單編號 ? item.上游廠商訂單編號 : '-' }} 
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <div class='table-card-box text-left'>
                                                <div class='px-10'>
                                                    <b>{{$t('玩家識別值')}}：</b>{{ item.玩家識別值 ? item.玩家識別值 : '-' }} 
                                                </div>
                                                <div class='px-10'>
                                                    <b>{{$t('使用者IP')}}：</b>{{ item.使用者IP ? item.使用者IP : '-' }} 
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <div class="table-card-box text-left">
                                                <div class="px-10">
                                                    <b>{{$t('訂單狀態')}}：</b>{{item.訂單狀態 ? item.訂單狀態 : '-'}}
                                                </div>
                                                <div class="px-10">
                                                    <b>{{$t('商品代碼')}}：</b>{{ item.商品代碼 ? 格式化商品代碼(item) : '-' }}
                                                </div>
                                                <div class="px-10">
                                                    <b>{{$t('交易方式')}}：</b>{{ item.交易方式 ? item.交易方式 : '-' }}
                                                </div>
                                                <div class="px-10">
                                                    <b>{{$t('儲值類型')}}：</b>{{ item.儲值類型 ? item.儲值類型 : '-'}}
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <div class="table-card-box text-left">
                                                <div class='px-10'>
                                                    <b>{{$t('幣別')}}：</b>{{ item.幣別 ? item.幣別 : '-' }}
                                                </div>
                                                <div class='px-10'>
                                                    <b>{{$t('原始金額')}}：</b> {{item.原始金額 ? item.原始金額 : '-' }}
                                                </div>
                                                <div class='px-10'>
                                                    <b>{{$t('交易金額')}}：</b> {{item.交易金額 ? item.交易金額 : '-' }}
                                                </div>
                                                <div class='px-10'>
                                                    <b>{{$t('配點金額')}}：</b> {{item.配點金額 ? item.配點金額 : '-' }}
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <div class="table-card-box text-left">
                                                <div class='px-10'>
                                                    <b>{{$t('交易時間')}}：</b>{{ item.交易時間 ? $utils.formatTime(item.交易時間, "YYYY/&ZeroWidthSpace;MM/DD HH:mm:ss") : '-' }}
                                                </div>
                                                <div class='px-10'>
                                                    <b>{{$t('匯入時間')}}：</b> {{ item.建立日期 ? $utils.formatTime(item.建立日期, "YYYY/&ZeroWidthSpace;MM/DD HH:mm:ss") : '-' }}
                                                </div>
                                            </div>
                                        </td>
                                        <td class="text-center">{{ item.備註 }}</td>
                                        <td>
                                            <div>
                                                <Button 
                                                    btnClass="btn-danger" 
                                                    :name="$t('刪除')" 
                                                    :spinner="spinner.del"
                                                    @click="delOrder(item.訂單編號)" 
                                                />
                                            </div>
                                        </td>
                                    </tr>
                                </template>
                                <template v-else>
                                    <tr>
                                        <td class="text-center" colspan="20">{{ $t('沒有資料') }}</td>
                                    </tr>
                                </template>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="box-footer text-center no-border" v-show="total > 0">
                    <Pagination ref="pagination" :total="total" :pageLimitCount="pageSize"
                        v-on:pageMethod="onPageChange" />
                </div>
            </div>
        </section>
        <DisputedImport ref="DisputedImport" @onPageChange="onPageChange"/>
    </div>
</template>

<script>
    /* eslint-disable */
    import moment from "moment";
    import DateRangePicker from "@/components/DateRangePicker";
    import Pagination from "@/components/Pagination";
    import api from "@/assets/js/app-api";
    import { IPSTool, exportCSV, APILib } from './IPSTool2'
    import DisputedImport from '../ips/Disputed/Import'
    import Button from '../ips/Disputed/Button'

    let isDev = process.env.NODE_ENV

    export default {
        components: {
            Button,
            DateRangePicker,
            Pagination,
            DisputedImport
        },
        data() {
            let pageSize = 20;
            return {
                pageSize,
                total: 0,
                sum: 0,
                dateSearchType: 'trade', // trade | import
                spinner: {
                    search: false,
                    export: false,
                    import: false,
                    list: false,
                    del: false,
                },
                searchData: {
                    訂單編號: "", 
                    廠商訂單編號: "", 
                    上游廠商訂單編號: "", 
                    玩家識別值: "", 
                    服務編號: "" ,
                    交易方式: "",
                    交易開始時間: new moment().add(-7, "days").format("YYYY-MM-DD 00:00:00"), 
                    交易結束時間: new moment().format("YYYY-MM-DD 23:59:59"), 
                    建立開始時間: new moment().add(-7, "days").format("YYYY-MM-DD 00:00:00"),
                    建立結束時間: new moment().format("YYYY-MM-DD 23:59:59"), 
                    起始行: 0, 
                    結束行: 20
                },
                serverList: [],// 服務
                IPSList: [], // 金流列表
                list: [], // 列表
                lastQueryCommand: null,
            };
        },
        watch: {
            "searchData.服務編號"(value) {
                this.searchData.交易方式 = "";
            },
            "searchData.訂單編號"(value) {
                this.setDatepickerDisabled();
            },
            "searchData.廠商訂單編號"(value) {
                this.setDatepickerDisabled();
            },
            "searchData.上游廠商訂單編號"(value) {
                this.setDatepickerDisabled();
            },
        },
        mounted() {
            this.init().then(() => this.onPageChange(1));
        },
        methods: {
            async init() {
                this.serverList = await IPSTool.ConstServerList.call(this, this.$store.state.projectId, "爭議訂單");//廠商專案列表;

                let 廠商支付列表FromServer = APILib.IPS(await api.IPSSetting.廠商支付設定_查詢({})).content;

                let 支付列表FromServer = await IPSTool.callAPIList.call(this, api.IPSSetting.金流設定主檔_查詢, {});
                {
                    //依照各廠商有那些支付
                    for (let server of this.serverList) {
                        this.$set(server, "支付List", []);
                        for (let 廠商支付 of 廠商支付列表FromServer) {
                            if (廠商支付.廠商設定主檔編號 == server.DB編號) {
                                let find金流 = 支付列表FromServer.find((金流) => { return 金流.編號 == 廠商支付.金流設定主檔編號 });
                                if (find金流 && !(server.支付List.find((item) => { return find金流.編號 == item.編號 }))) {
                                    let { 編號, 金流名稱, 金流代碼 } = find金流;
                                    server.支付List.push({ 編號, 金流名稱, 金流代碼 });
                                }
                            }
                        }
                    }
                }
                this.IPSList = 支付列表FromServer;
            },
            onPageChange(pageIdx) {
                this.searchData.起始行 = (pageIdx - 1) * this.pageSize + 1;
                this.searchData.結束行 = 20;
                let send = JSON.parse(JSON.stringify(this.searchData));

                if (send.訂單編號 != "" || send.廠商訂單編號 != '' || send.上游廠商訂單編號 != '' ) {
                    delete send.交易開始時間;
                    delete send.交易結束時間;
                    delete send.建立開始時間;
                    delete send.建立結束時間;
                } else {
                    if (this.dateSearchType == 'import') {
                        send.建立開始時間 = this.$utils.formatTime(send.建立開始時間, "YYYY-MM-DD");
                        send.建立結束時間 = this.$utils.formatTime(send.建立結束時間, "YYYY-MM-DD");
                        delete send.交易開始時間
                        delete send.交易結束時間
                    } else {
                        send.交易開始時間 = this.$utils.formatTime(send.交易開始時間, "YYYY-MM-DD");
                        send.交易結束時間 = this.$utils.formatTime(send.交易結束時間, "YYYY-MM-DD");
                        delete send.建立開始時間
                        delete send.建立結束時間
                    }
                }
                this.queryAll(send, pageIdx);
            },
            async queryAll(send, pageIdx) {
                this.spinner.search = true

                if (!send) return;

                let res = {
                    orders: [],
                    total: 0,
                    sum: 0,
                };

                try {
                    res = await IPSTool.callAPIList.call(this, api.IPSSetting.DisputeOrderQuery, send);

                    this.list = res.list;
                    this.total = res.total;
                    this.sum = res.sumDesc;
                    this.spinner.search = false

                    // 預設匯出使用
                    if (this.total > 0) {
                        this.lastQueryCommand = { cmd: send, total: this.total };
                    } else {
                        this.lastQueryCommand = null;
                    }

                    if (pageIdx > 0) {
                        this.$refs.pagination.setPage(pageIdx, this.total);
                    } else {
                        this.$refs.pagination.setPage(1, 0);
                    }
                    this.spinner.search = false
                } catch (e) {
                    console.error('撈取資料列表異常', e)
                    this.spinner.search = false
                }
            },
            delOrder (orderId) {
                try {
                    this.$eventBus.$emit("showConfirm", "確定要刪除嗎?", (isOK) => {
                        if (isOK) {
                            this.spinner.del = true
                            api.IPSSetting.DisputeOrderDel({
                                訂單編號: orderId
                            }).then(async (res) => {
                                if (res.data.status == "1") {
                                    this.$eventBus.$emit("showAlert", '刪除成功');
                                    this.onPageChange(1)
                                } else {
                                    this.$eventBus.$emit("showAlert", res.data.message);
                                }
                                this.spinner.del = false
                            }).catch((e) => {
                                console.error("刪除訂單API異常", e);
                                this.spinner.del = false
                            });
                        }
                    });
                } catch (e) {
                    console.error('刪除訂單異常', e)
                }
            },
            // 匯出
            async exportReport() {
                try {
                    this.spinner.export = true
                    if (!this.lastQueryCommand) return;

                    let cloneLastQueryCommand = JSON.parse(JSON.stringify(this.lastQueryCommand));
                    let res = [];

                    for (let 起始行 = 1; 起始行 <= this.lastQueryCommand.total; 起始行 += this.pageSize) {
                        let 結束行 = 起始行 + this.pageSize - 1;
                        cloneLastQueryCommand.cmd.起始行 = 起始行;
                        cloneLastQueryCommand.cmd.結束行 = 20;
                        let tmp = await IPSTool.callAPIList.call(this, api.IPSSetting.DisputeOrderQuery, cloneLastQueryCommand.cmd);
                        for (let a of tmp.list) {
                            if (a.配點金額 == 0) a.配點金額 = '0'
                            a.廠商名稱 = this.serverList.filter(b => b.廠商編號 == a.服務編號)[0].名稱
                        }
                        res = [...res, ...tmp.list];
                    }
                    const name = `${moment().format("YYYYMMDD_HHmmss")}爭議訂單`
                    exportCSV(name, res/*JSON.parse(JSON.stringify(this.list))*/);
                    this.spinner.export = false
                } catch (e) {
                    console.error('匯出資料異常', e)
                }
            },
            格式化商品代碼: function ({ 商品代碼, 服務編號 }) {
                switch (服務編號) {
                    case "10014": {
                        switch (商品代碼) {
                            case "BindCard": return "綁卡";
                            case "YGPoint": return 商品代碼;//不變
                            default: return `禮包(${商品代碼})`;
                        }
                        break;
                    }
                    default: return 商品代碼;
                }
            },
            openDisputedModal() {
                this.$refs.DisputedImport.openDisputedModal('show');
            },
            setDatepickerDisabled() {
                this.$refs.tradeDateRangePicker.$refs.datepicker.disabled = (this.searchData.訂單編號 != "" || this.searchData.廠商訂單編號 != "" || this.searchData.上游廠商訂單編號 != "" ) ? true : false;
                this.$refs.importDateRangePicker.$refs.datepicker.disabled = (this.searchData.訂單編號 != "" || this.searchData.廠商訂單編號 != "" || this.searchData.上游廠商訂單編號 != "" ) ? true : false;
            },
        },
    };
</script>

<style lang='scss' scoped>
    .w-100 {
        width: 100%;
    }

    .justify-content-between {
        justify-content: space-between;
    }

    .min-1600 {
        min-width: 1600px;
    }
    .mb-0 {
        margin-bottom: 0px;
    }
    .mb-5 {
        margin-bottom: 5px;
    }

    .mr-5 {
        margin-right: 5px;
    }

    .table th {
        word-wrap: break-word;
        word-break: keep-all;
        letter-spacing: -.05em;
        min-width: 50px;
    }

    .table td {
        padding-block: .5em;
        word-wrap: break-word;
        overflow-wrap: break-word;
    }

    .d-flex {
        display: flex;
        align-items: center;
    }

    .top-box .form-group {
        display: inline-block;
        vertical-align: top;
        margin: 0 10px 10px 0;
    }

    .top-box .form-group .control-label {
        padding-top: 7px;
        float: left;
    }

    .top-box .input-group {
        min-width: 250px;
        width: 100%;
    }

    .form-type {
        display: flex;
        align-items: center;
        .form-group {
            width: 100%;
        }
        @media(max-width: 991px) {
            display: block;
            input {
                width: 100%;
            }
        }
    }

    .table-card-box {
        .px-10 {
            padding: 0 10px;
        }
        margin: 0 auto;
        background: #fff;
        border-radius: 5px;
        overflow: hidden;
        position: relative;
        border: 1px solid #f4f4f4;
        @media(max-width: 991px) {
            width: 100% !important;
            max-width: 280px;
        }
        &:after {
            content: '';
            width: 2px;
            background:#337ab7;
            height: 100%;
            display: list-item;
            position: absolute;
            top: 0;
        }
    }
</style>