<template>
  <div id="PlayerHistoryList">
    <section class="content-header">
      <h1>玩家歷程查詢 <small class="text-red">(僅能查詢最近三個月內的玩家歷程紀錄，請選擇符合範圍的日期。)</small></h1>
      <ol class="breadcrumb">
        <li><router-link to="/">Home</router-link></li>
        <li class="active">玩家歷程查詢</li>
      </ol>
    </section>
    <section class="content">
      <div class="box box-primary">
        <div class="box-body">
          <form class="top-box" @submit="$event.preventDefault(); getDataList()">
            <div class="form-group">
              <label class="control-label">操作時間：</label>
              <div class="input-group date">
                <div class="input-group-addon fa fa-calendar"></div>
                <DateRangePicker
                  :propTimePicker="true"
                  :propEndDate.sync="local.endTime"
                  :propStartDate.sync="local.startTime"
                />
              </div>
            </div>
            <div class="input-group">
              <input type="text" v-model.trim="searchData.暱稱" class="form-control" placeholder="玩家暱稱"/>
            </div>
            <div class="select-group">
              <select class="form-control" v-model.number="searchData.種類" @change="getDataList()">
                <option value="-1">全部種類</option>
                <option v-for="(option,i) in def.playerAction" :value="i">{{ option }}</option>
              </select>
            </div>
            <div class="btns mb-10">
              <button type="submit" class="btn btn-primary" @click="">
                查詢
              </button>
              <button type="button" class="btn btn-default" @click="exportOrders()" :disabled="datas.length === 0">
                匯出
              </button>
            </div>
            <button type="button" v-show="Object.keys($route.query).length > 0" @click="$router.go(-1)" class="btn btn-default pull-right fa fa-arrow-left"> 返回</button>
            <router-link v-show="datas.length > 0 && $route.params.nick" :to="{name:'NmjPlayerData', params:{nick:$route.params.nick}}" class="btn btn-warning btn-add fa fa-user-circle"> 玩家資料</router-link>
          </form>
          <div class="mt-0">
            <div class="table-responsive">
              <table class="table table-bordered table-hover text-center">
                <thead>
                  <tr class="bg-gray">
                    <th style="min-width: 92px;width:15%">時間</th>
                    <th style="min-width:105px;width:10%">玩家<wbr>暱稱</th>
                    <th style="min-width:105px;width: 5%">種類</th>
                    <th style="min-width:120px;width:15%">項目</th>
                    <th style="min-width: 90px;width: 5%">數量</th>
                    <th style="min-width: 90px;width: 5%">當下<wbr>經驗值</th>
                    <th style="min-width: 90px;width: 5%">當下<wbr>牌技</th>
                    <th style="min-width: 90px;width: 5%">當下<wbr>雀幣</th>
                    <th style="min-width: 90px;width: 5%">當下<wbr>雀銀</th>
                    <th style="min-width: 90px;width:10%">原因</th>
                    <th style="min-width: 90px;width: 5%">ID</th>
                    <th style="min-width: 90px;width:10%">備註</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-if="loading">
                    <td colspan="15">查詢中</td>
                  </tr>
                  <tr v-else-if="datas.length <= 0">
                    <td colspan="15">查詢無資料</td>
                  </tr>
                  <tr v-else v-for="(item, index) in datas" :key="index">
                    <td>{{ uiDate(item.時間) }}</td>
                    <td>{{ item.玩家暱稱 }}</td>
                    <td>{{ item.種類 }}</td>
                    <td>{{ item.項目 }}</td>
                    <td>{{ item.數量 }}</td>
                    <td>{{ item.當下經驗值 }}</td>
                    <td>{{ item.當下牌技 }}</td>
                    <td>{{ item.當下雀幣 || item.當下雀能 }}</td>
                    <td>{{ item.當下雀銀 }}</td>
                    <td>{{ item.原因 }}</td>
                    <td>
                      <span v-if="item.種類 == '閃電戰'">{{ item.ID || '-' }}</span>
                      <router-link v-else-if="/^\d+$/.test(item.ID)" :to="{name:'NmjGameRecord', query:{id: item.ID, nick: item.玩家暱稱}}">
                        {{ item.ID }}
                      </router-link>
                      <span v-else>-</span>
                    </td>
                    <td>{{ item.備註 }}</td>
                    <!-- <td><button class="btn btn-primary" @click="gameDataList(item)">牌局</button></td> -->
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="box-footer text-center no-border">
        </div>
        <!-- <pre>{{ datas }}</pre> -->
      </div>
    </section>
  </div>
</template>

<style lang="scss" scoped>
table.table > tbody > tr:nth-child(2n+1) td{
  background-color: rgba(0,0,0,.01);
}
  table.table > tbody > tr > td {
    word-break: break-word;
  }
  table.table > thead > tr > th{
    word-break: keep-all;
    font-weight: normal;
  }
</style>

<script>
/* eslint-disable */
import moment from "moment";
import DateRangePicker from "@/components/DateRangePicker";
import define from "@/views/projects/NMJ/_Define";

export default {
  components: {
    DateRangePicker,
  },
  data() {
    return {
      def: define,
      loading: false,
      backBtn: false,
      total: 0, //總筆數
      searchData: {
        暱稱: "",
        開始時間: "",
        結束時間: "",
        種類: -1,

        // pageNum: 1,
        // pageSize: 20, //每頁顯示筆數
      },
      local: {
        startTime: new moment().subtract(7, "days").format("YYYY-MM-DD 00:00:00"),
        endTime: new moment().format("YYYY-MM-DD 23:59:59"),
      },
      datas: [],
    };
  },
  async created() {
    if (this.$route.query.nick){
      this.searchData.暱稱 = this.$route.query.nick;
      this.getDataList();
    }
  },
  mounted() {
  },
  methods: {
    getDataList() {
      if (!this.searchData.暱稱) {
        this.$eventBus.$emit("showAlert", "請填寫玩家暱稱！");
        return;
      }
      this.searchData.開始時間 = new moment(this.local.startTime).format("YYYY/MM/DD HH:mm:ss");
      this.searchData.結束時間 = new moment(this.local.endTime).format("YYYY/MM/DD HH:mm:ss");
      this.loading = true;
      this.$store
        .dispatch("nmjPlayerData/history", this.searchData)
        .then((result) => {
          this.datas = result.playerHistoryList || [];
          this.datas;
          this.total = result.total;
          this.loading = false;
          if (this.$route.query.nick != this.searchData.暱稱){
            this.$router.replace({query:{ nick: this.searchData.暱稱}});
          }
        }, (res) => {
          this.datas = [];
          this.total = 0;
          this.loading = false;
        });
    },
    exportOrders() {
      this.$store.dispatch("nmjPlayerData/exportHistory", this.searchData);
    },
    uiDate(date){
      let d = new Date(date);
      if (d) return d.toLocaleString('zh-TW', {
        year:"numeric",
        month:"2-digit",
        day:"2-digit",
        hour:"2-digit",
        minute:"2-digit",
        second:"2-digit",
        hourCycle:"h23",
      });
      else return '';
    }
  },
};
</script>
