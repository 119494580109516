/* eslint-disable */
import api from '@/assets/js/app-api';
import dfn from "@/views/projects/xin/_GameQuestionDefine";

// actions
const actions = {
    CreateFAQCategory({commit},data){ return callAPI.call(this, dfn.permission.CreateFAQCategory, data) },
    UpdateFAQCategory({commit},data){ return callAPI.call(this, dfn.permission.UpdateFAQCategory, data) },
    DeleteFAQCategory({commit},data){ return callAPI.call(this, dfn.permission.DeleteFAQCategory, data) },
    QueryFAQCategories({commit},data){ return callAPI.call(this, dfn.permission.QueryFAQCategories, data) },
    CreateFAQ({commit},data){ return callAPI.call(this, dfn.permission.CreateFAQ, data) },
    UpdateFAQ({commit},data){ return callAPI.call(this, dfn.permission.UpdateFAQ, data) },
    DeleteFAQ({commit},data){ return callAPI.call(this, dfn.permission.DeleteFAQ, data) },
    QueryFAQs({commit},data){ return callAPI.call(this, dfn.permission.QueryFAQs, data) },
    DeleteAnswers({commit},data){ return callAPI.call(this, dfn.permission.DeleteAnswers, data) },
}

function callAPI(command, data = {}){
    return new Promise((resolve, reject) => {
        if (!this._vm.$user.hasPermission(command)){
            let msg = `使用者無開通「${command}」API權限`;
            this._vm.$eventBus.$emit('showAlert', msg);
            reject(msg);
        }
        api.XinStarsTw
            .post({permission:command,lang:"zh-TW", data:data})
            .then(res => {
            if (res.data.status === '1'){
                resolve(res.data.result);
            } else {
                reject(res.data.message);
            }
        }).catch( err => {
            this._vm.$eventBus.$emit('showAlert', err.message);
            reject(err.message);
        });
    });
}

export default {
    namespaced: true,
    actions,
}
