<template>
  <div class="radio-date">
    <div class="input-group date">
      <div class="input-group-addon fa fa-calendar"></div>
      <input type="text" class="form-control datepicker" ref="date" />
      <!-- <DatePicker ref="date" :propValue.sync="dateString" /> -->
    </div>
    <div class="input-group time">
      <div class="input-group-addon fa fa-clock-o"></div>
      <input type="text" class="form-control timepicker" ref="time" />
    </div>
    <slot></slot>
  </div>
</template>

<script>
/* eslint-disable */
// import DatePicker from "@/components/DatePicker";
import moment from "moment";

export default {
  components: {
    // DatePicker,
  },
  props: {
    value: String,
    format: {type:String, default:"YYYY/MM/DD HH:mm:ss"},
  },
  data() {
    return {
      date: new Date(this.value),
    };
  },
  computed: {
    modelValue: {
      get() { return this.value; },
      set(value) { this.$emit('input', value) }
    }
  },
  mounted(){
    if (this.date == "Invalid Date") this.date = new Date();

    $(this.$refs.date).datepicker({
      autoclose: true,
      language: "tw",
      todayHighlight: true,
      format: "yyyy/mm/dd",
    }).on("changeDate", (e) => {
      let d = new Date(e.date);
      this.date.setFullYear(d.getFullYear(), d.getMonth(), d.getDate());
      this.modelValue = new moment(this.date).format(this.format);
    }).datepicker("setDate", this.date);

    $(this.$refs.time).timepicker({
      minuteStep: 15,
      disableFocus: true,
      showInputs: false,
      showMeridian: false,
      defaultTime: this.date
    }).on('changeTime.timepicker', (e) => {
      let hour = e.time.hours;
      if (e.time.meridian){
        hour %= 12;
        hour += e.time.meridian == 'PM' ? 12 : 0;
      }
      // console.log(hour, e.time.minutes, e.time.meridian)
      this.date.setHours(hour, e.time.minutes, 0, 0);
      this.modelValue = new moment(this.date).format(this.format);
    })
  },
  watch:{
    value(){
      this.date = new Date(this.value);
      $(this.$refs.date).datepicker("setDate", this.date);
      $(this.$refs.time).timepicker("setTime", this.date);
    }
  }
};
</script>
