@@ -1,292 +0,0 @@
<template>
  <div id="AnnouncementList">
    <section class="content-header">
      <h1>{{$t('活動總覽')}}</h1>
      <ol class="breadcrumb">
        <li><router-link to="/">{{$t('首頁')}}</router-link></li>
        <li class="active">{{$t('活動總覽')}}</li>
      </ol>
    </section>

    <section class="content">
      <div class="box">
        <div class="box-body">
          <form class="top-box" @submit.prevent="clickSearch()">
            <router-link :to="{name:'XsgGameBannerEdit', params:{id:'New', searchData }}" class="btn btn-block btn-warning btn-add fa fa-plus">{{$t('新增')}}</router-link>
            <div class="select-group">
              <select class="form-control" v-model="searchData.類別" @change="clickSearch()" required>
                <option v-for="(name, key) in bannerType" :key="key" :value="name">{{ $t(name) }}</option>
              </select>
            </div>
            <div class="text"><p>{{$t('資料總筆數')}}：{{ total }}</p></div>
          </form>
          <div class="table-responsive mt-10 text-center">
            <table class="table table-hover table-bordered AdListTable">
              <tbody>
                <tr class="bg-gray text-nowrap">
                  <th style="min-width: 50px; width: 5%">{{$t('編號')}}</th>
                  <th style="min-width: 80px; width: 5%">{{$t('狀態')}}</th>
                  <th style="min-width:135px; width:10%">{{$t('標題')}}</th>
                  <th style="min-width:120px; width:10%">{{$t('縮圖')}}</th>
                  <th style="min-width: 50px; width: 5%">{{$t('置頂')}}</th>
                  <th style="min-width:100px; width:10%">{{$t('顯示開始時間')}}</th>
                  <th style="min-width:100px; width:10%">{{$t('顯示結束時間')}}</th>
                  <th style="min-width:100px; width: 5%">{{$t('排序')}}</th>
                  <th style="min-width:100px; width: 5%">{{$t('功能')}}</th>
                </tr>
                <tr v-if="loading"><td colspan="11">{{$t('查詢中')}}</td></tr>
                <tr v-else-if="message"><td colspan="11">{{ $t(message) }}</td></tr>
                <tr v-else-if="total === 0"><td colspan="11">{{$t('查無紀錄')}}</td></tr>
                <tr v-else v-for="(item, index) in datas" :key="item.id">
                  <td>{{ index+1 }}</td>
                  <td>
                    <fieldset class="ckbx-style-8 ckbx toggleInput" :disabled="!can修改" :style="!can修改?'opacity:.5;':false">
                      <input type="checkbox" :true-value="1" :false-value="0" :id="`check${index}`" v-model.number="item.狀態" @change="changeStatus(item)" name="ckbx-style-8" />
                      <label :for="`check${index}`"></label>
                    </fieldset>
                    <p>{{ $t(item.狀態 ? '開啟' : '關閉') }}</p>
                  </td>
                  <td>{{ item.標題 }}</td>
                  <td><div class="pic"><img :src="getImageUrl(item,'1')" draggable="false" alt="" /></div></td>
                  <td>{{ $t(item.isTop ? '是' : '') }}</td>
                  <td>{{ uiDate(item.顯示開始時間) }}</td>
                  <td>{{ uiDate(item.顯示結束時間) }}</td>
                  <td class="text-nowrap">
                    <fieldset style="display:flex; flex-direction:column; gap:5px" :disabled="!can修改">
                      <input type="number" class="form-control p-5" v-model.number="item.排序" min="1" max="" :disabled="!item.狀態" style="padding-right:0; text-align:center;" />
                      <button type="button" class="btn btn-warning btn-add" @click="changeStatus(item)" :disabled="!item.狀態">
                        <i class="fa fa-arrows-v"></i> {{$t('更新')}}
                      </button>
                    </fieldset>
                  </td>
                  <td>
                    <div style="display:flex; flex-direction:column; gap:5px">
                      <router-link class="btn btn-primary" v-if="can修改" :to="{name:'XsgGameBannerEdit', params:{id:item.公告ID, searchData}}">
                        <i class="fa fa-fw fa-pencil"></i> {{$t('修改')}}
                      </router-link>
                      <button class="btn btn-danger" v-if="can刪除" @click="deleteItem(item)">
                        <i class="fa fa-fw fa-close"></i> {{$t('刪除')}}
                      </button>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="box-footer text-center no-border">
            <Pagination ref="pagination" :total="total" :pageLimitCount="searchData.pageSize" v-on:pageMethod="onPageChange" />
          </div>
        </div>
      </div>
    </section>
    <!-- <pre>{{ datas }}</pre> -->
  </div>
</template>

<style lang="scss" scoped>
/*
  .input-group{
    width: max-content;
    margin: 0 auto;
    .form-control{
      padding-inline: .35em;
      text-align: center;
    }
  }
  */
  input[type="checkbox"]{
    width: 1.5em;
    height: 1.5em;
    vertical-align: sub;
    margin-top: -.2em;
    accent-color: #3498db;
    cursor: pointer;
  }
  label.required::before{content: "* "; color: #dd4b39 }
  .form-control.sort{
    display: inline-block;
    width: 54px;
    padding: 5px;
    vertical-align: middle;
    border-radius: 3px;
    text-align: center;
  }
</style>

<script>
/* eslint-disable */
//import CKEditor from "@/components/CKEditor";
import api from "@/assets/js/app-api";
import dfn from "@/views/projects/XSW/_Define";
import types from "@/views/projects/XSG/_Define";
import Pagination from "@/components/Pagination";
import DateAndTimePicker from "@/components/DateAndTimePicker";
import iCheckCheckBox from "@/components/iCheck_CheckBox";
import iCheckRedioButton from "@/components/iCheckRedioButton";
import utils from "@/assets/js/app-utils";
import config from "@/assets/js/app-config";
import { mapState, mapActions } from "vuex";
import moment from "moment";

export default {
  components: {
    Pagination,
    DateAndTimePicker,
    iCheckCheckBox,
    iCheckRedioButton
  },
  props: {
    id: "",
  },
  data() {
    return {
      dfn: dfn,
      bannerType: types.gameBannerType,
      // def: define,
      projectId: this.$store.state.projectId,

      searchData: {
        類別: types.gameBannerType[Object.keys(types.gameBannerType)[0]],
        標題: "",
        開始時間: "",
        結束時間: "",
        pageNum: 1,
        pageSize: 10, //每頁顯示筆數
      },
      loading: false,
      message: "",
      datas: [],
      total: 0, //總筆數


      
      // 活動總覽列表查詢: "活動總覽列表查詢",
      // 活動總覽查詢: "活動總覽查詢",
      // 活動總覽新增: "活動總覽新增",
      // 活動總覽修改: "活動總覽修改",
      // 活動總覽刪除: "活動總覽刪除",
      can新增: this.$user.hasPermission(dfn.permission.活動總覽新增),
      can修改: this.$user.hasPermission(dfn.permission.活動總覽修改),
      can刪除: this.$user.hasPermission(dfn.permission.活動總覽刪除),
    };
  },
  async created() {
    this.getData();
  },
  mounted() {
    window.v = this;
  },
  computed: {
    getImageUrl() {
      return (item, info) => {
        const logo1File = item.files.find((file) => file.info === info);
        return logo1File ? logo1File.filesUrl : "";
      };
    },
  },
  watch: {
    // response() {
    //   if (this.response.status == "1")
    //     this.$eventBus.$emit("showAlert", "更新成功!");
    //   else this.$eventBus.$emit("showAlert", "更新失敗!");

    //   this.GetBannerQueryAll();
    // },
    // banners() {
    //   this.$refs.pagination.setPage(
    //     this.searchData.pageNum,
    //     this.filteredBanners.length
    //   );
    // },
  },
  methods: {
    async onPageChange(pageIdx, pageSize) {
      this.searchData.pageNum = pageIdx;
      this.searchData.pageSize = pageSize;
      this.getData();
    },
    getData(){
      this.loading = true;
      this.$store.dispatch("xsw/活動總覽列表查詢", this.searchData).then(res => {
        this.message = "";
        this.datas = res.datas || [];
        this.total = res.total || 0;
        this.loading = false;
      }, err => {
        this.message = err;
        this.datas =  [];
        this.total = 0;
        this.loading = false;
      });
    },

    clickSearch() {
      this.searchData.pageNum = 1;
      this.getData();
    },

    changeStatus(item){
      this.$store.dispatch("xsw/活動總覽修改", item).then(()=>{
        this.$eventBus.$emit("showAlert", "修改成功");
        this.getData();
      });
    },

    //*---- 刪除
    deleteItem(item) {
      this.$eventBus.$emit("showConfirm", this.$t("確定要刪除嗎?"), (isOK) => {
        if (isOK) {
          this.$store.dispatch("xsw/活動總覽刪除", item).then(()=>{
            this.$eventBus.$emit("showAlert", "刪除成功");
            this.getData();
          });
        }
      });
    },
    uiDate(date){
      let d = new Date(date);
      if (date && d) return d.toLocaleString('zh-TW', {
        year:"numeric",
        month:"2-digit",
        day:"2-digit",
        hour:"2-digit",
        minute:"2-digit",
        second:"2-digit",
        hourCycle:"h23",
      });
      else return '';
    },
  },
};
</script>