<template>
  <div>
    <form class="top-box" @submit.prevent="clickSearch()">
      <button type="button" class="btn btn-block btn-warning btn-add mb-10" @click="add()" :disabled="isEditing">
        <i class="fa fa-plus"></i> 新增內容
      </button>
    </form>

    <div class="table-responsive">
      <table class="table table-bordered text-center">
        <tbody>
          <tr class="bg-gray">
            <th style="min-width: 80px; width: 5%">排序</th>
            <th style="min-width:240px; width:90%">內容</th>
            <th style="min-width: 80px; width: 5%">動作</th>
          </tr>
          <tr v-if="!isEditing && message"><td colspan="10">{{ message }}</td></tr>
          <tr v-else-if="!isEditing && total <= 0"><td colspan="10">查詢無任何資料</td></tr>
          <tr v-else v-for="(item, index) in datas" :key="item.gid" :class="{'bg-gray-light': isEditing && !item.isEdit }">
            <td class="text-nowrap">
              <fieldset style="display:flex; flex-direction:column; gap:5px" :disabled="isEditing && !item.isEdit">
                <input type="number" class="form-control p-5" v-model.number="item.排序" min="1" max="" style="padding-right:0; text-align:center;" />
                <button type="button" class="btn btn-warning btn-add btn-upDate" @click="save(item, index)">
                  <i class="fa fa-fw fa-arrows-v"></i>更新
                </button>
              </fieldset>
            </td>
            <td class="text-left">
              <div v-if="!item.isEdit" style="display:flex; gap:.5em; flex-wrap:wrap;">
                <span :title="item.連結參數" style="flex: 0 0 auto; max-width:100%;">
                  <img :src="getImage(item,'zh-TW_desktop')" :alt="item.連結參數" style="max-height:100px;">
                </span>
                <div v-if="item.選項 == 1">
                  <h4 style="font-weight: bold; font-size: 1.25em; margin:0 0 .25em">{{ getTitle(item) }}</h4>
                  <p>{{ getSubtitle(item) }}</p>
                </div>
                <div v-if="item.選項 == 2">
                  <a class="btn btn-default" :href="item.連結參數">{{ item.按鈕文字 }}</a>
                </div>
              </div>
              <form v-else class="form-horizontal box-body" id="editForm" @submit.prevent="save(item, index)">
                <fieldset style="grid-column: 1/-1;">
                  <legend>上傳 Banner：</legend>
                  <div class="nav-tabs-custom mb-0 mt-7">
                    <ul class="nav nav-tabs" style="background: linear-gradient(transparent, rgba(0, 0, 0, 0.05));">
                      <template v-for="(lang, index) in langs">
                        <li :class="{'active':lang.id == editLang}">
                          <a @click="editLang = lang.id" :title="lang.id" :class="{'required':lang.required}">
                            {{ lang.name }}
                          </a>
                        </li>
                      </template>
                    </ul>
                    <div class="tab-content" v-for="(lang, index) in langs" :class="{'sr-only': lang.id !== editLang}">
                      <fieldset class="form-horizontal">
                        <div>
                          <label class="pull-left control-label" :for="`bannerPc_${lang.id}`" :class="{required:lang.required}">
                            桌機版：
                          </label>
                          <ImageSelect :id="`bannerPc_${lang.id}`" ref="banner" v-model="item.files"
                            :gid="item.bannerId" module="WebBanner" :info="`${lang.id}_desktop`"
                            accept="image/webp, image/jpeg, image/png, image/gif" :maxsize="500*1024" :required="editLang == lang.id && lang.required"
                          >
                            <p class="help-block">※尺寸為 W1920&times;H800，檔案上限為 500KB。</p>
                          </ImageSelect>
                        </div>
                        <div>
                          <label class="pull-left control-label" :for="`bannerMo_${lang.id}`" :class="{required:lang.required}">
                            手機版：
                          </label>
                          <ImageSelect :id="`bannerMo_${lang.id}`" ref="banner" v-model="item.files"
                            :gid="item.bannerId" module="WebBanner" :info="`${lang.id}_mobile`"
                            accept="image/webp, image/jpeg, image/png, image/gif" :maxsize="500*1024" :required="editLang == lang.id && lang.required"
                          >
                            <p class="help-block">※尺寸為 W1000&times;H800，檔案上限為 500KB。</p>
                          </ImageSelect>
                        </div>
                      </fieldset>
                    </div>
                  </div>
                </fieldset>
                
                <fieldset class="form-group">
                  <legend class="mt-20">是否壓上標題或按鈕：</legend>
                  <div style="grid-column: 1/-1;">
                    <div class="mb-10">
                      <span class="radio-inline mr-10">
                        <iCheckRedioButton :checkValue="0" message="無" name="radio選項" v-model="item.選項" />
                      </span>
                      <span class="radio-inline mr-10">
                        <iCheckRedioButton :checkValue="1" message="標題" name="radio選項" v-model="item.選項" />
                      </span>
                      <span class="radio-inline mr-10">
                        <iCheckRedioButton :checkValue="2" message="按鈕" name="radio選項" v-model="item.選項" />
                      </span>
                    </div>
                    <div v-if="item.選項 == 1">
                      <MultilangInput label="大標題" class="mb-10" :langs="langs" v-model="item.大標題" prop="bannerTitle"></MultilangInput>
                      <MultilangInput label="小標題 (選填)" class="mb-10" :langs="langs" v-model="item.小標題" prop="bannerTitle" :required="false"></MultilangInput>
                    </div>
                    <div v-if="item.選項 == 2" style="display:flex; gap:.5em">
                      <label class="control-label required" for="btnLabel">按鈕文字：</label>
                      <input type="text" class="form-control" id="btnLabel" v-model="item.按鈕文字" maxlength="10" placeholder="統一使用英文 (限10個字元以內)">
                      <label class="control-label" for="btnLabel">{{ item.按鈕文字.length }} / 10</label>
                    </div>
                  </div>
                </fieldset>
                <fieldset class="form-group" v-show="item.選項 == 2">
                  <legend class="mt-20">指定連結：</legend>
                  <div class="radio-inline mr-10">
                    <iCheckRedioButton :checkValue="0" :message="`遊戲介紹頁：${getGame(item)}`" name="radio指定連結" v-model="item.指定連結" />
                    <input type="text" list="gameList" class="form-control mt-5" v-model="item.連結參數" placeholder="請輸入遊戲名稱">
                    <datalist id="gameList">
                      <option v-for="game in gameList" :value="game.id">{{ game.names }}</option>
                    </datalist>
                  </div>
                  <div class="radio-inline mr-10">
                    <iCheckRedioButton :checkValue="1" message="網址：" name="radio指定連結" v-model="item.指定連結" />
                    <input type="text" class="form-control mt-5" id="btnLabel" v-model="item.連結參數2" placeholder="https://">
                  </div>
                </fieldset>
              </form>
            </td>
            <td>
              <fieldset style="display:flex; flex-direction:column; gap:5px" :disabled="isEditing && !item.isEdit">
                <button v-show="!item.isEdit" @click="edit(item)" class="btn btn-primary">
                  <i class="fa fa-fw fa-pencil"></i>修改
                </button>
                <button v-show="!item.isEdit" @click="copyItem(item)" class="btn btn-warning">
                  <i class="fa fa-fw fa-copy"></i>複製
                </button>
                <button v-show="item.isEdit" type="submit" form="editForm" class="btn btn-success">
                  <i class="fa fa-fw fa-save"></i>儲存
                </button>
                <button v-show="!item.isEdit" @click="deleteItem(item, index)" class="btn btn-danger" :disabled="datas.length <= 1">
                  <i class="fa fa-fw fa-close"></i>刪除
                </button>
                <button v-show="item.isEdit" @click="cancel(item, index)" class="btn btn-default">
                  <i class="fa fa-fw fa-reply"></i>取消
                </button>
              </fieldset>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div v-show="total" class="box-footer text-center no-border" :style="isEditing ? 'pointer-events:none; opacity:.6':''">
      <Pagination
        ref="pagination"
        :total="total "
        :pageLimitCount="searchData.pageSize"
        v-on:pageMethod="onPageChange"
        :type2="true"
      />
    </div>

    <!-- <pre>{{ datas }}</pre> -->
    <!-- <pre>{{ gameList }}</pre> -->
  </div>
</template>

<style scoped>
.form-horizontal{
  display:grid;
  gap: 0 1em;
  grid-template-columns: repeat(auto-fit, minmax(min(100%, max(250px, 50% - 1em)), 1fr));
}
fieldset.form-group{
  display: contents;
}
.form-group::before, .form-horizontal::before, .form-group::after, .form-horizontal::after{
  content: none;
}
.nav-tabs-custom, .tab-content{
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05);
}
.nav-tabs-custom>.nav-tabs>li{
  margin-right: 0;
  cursor: pointer;
}
.table .form-group .help-block{
  display: block;
}
legend{
  font-weight: bold;
  font-size: larger;
  border: none;
  text-align: left;
  margin: 0;
  grid-column: 1 / -1;
}
[class|="col"]{
  padding-inline: 0;
}
label{
  flex: 1 0 auto;
}
.required::before{
  content: "* ";
  color: #dd4b39;
  position: absolute;
  transform: translate(-120%);
}
</style>

<script>
/* eslint-disable */
import api from "@/assets/js/app-api";
import dfn from "@/views/projects/XSW/_Define";
import types from "@/views/projects/AWH5/_Define";
import ImageSelect from "@/views/projects/XSG/components/ImageSelect";
import MultilangInput from "@/views/projects/AWH5/components/MultilangInput";
import iCheckRedioButton from "@/components/iCheckRedioButton";
import Pagination from "@/components/Pagination";
import utils from "@/assets/js/app-utils";
import { mapState, mapActions } from "vuex";
import moment from "moment";

export default {
  components: {
    Pagination,
    ImageSelect,
    MultilangInput,
    iCheckRedioButton,
  },
  props: {

  },
  data() {
    return {
      langs: types.projectData.langs,
      editLang: types.projectData.langs[0].id,
      projectId: this.$store.state.projectId,
    
      searchData: {
        BannerId: "",
        開始時間: "",//new moment("2024-01-01").format("YYYY/MM/DD 00:00:00"),
        結束時間: "",//new moment().format("YYYY/MM/DD 23:59:59"),
        pageNum: 1,
        pageSize: 10, //每頁顯示筆數
      },

      datas: [],
      total: 0, //總筆數
      message: "",
      
      can列表: this.$user.hasPermission(dfn.permission.官網Banner列表查詢),
      can查詢: this.$user.hasPermission(dfn.permission.官網Banner查詢),
      can新增: this.$user.hasPermission(dfn.permission.官網Banner新增),
      can修改: this.$user.hasPermission(dfn.permission.官網Banner修改),
      can刪除: this.$user.hasPermission(dfn.permission.官網Banner刪除),
      can複製: this.$user.hasPermission(dfn.permission.官網Banner複製),

      isEditing: false,
      editBackup: null,

      gameList: [],
    };
  },
  async created() {
    this.searchData = this.$route.params.searchData || this.searchData;
  },
  mounted() {
    this.getDataList();
  },
  computed: {

    getImage() {
      return (item, info) => {
        const image = item.files.find((file) => file.info === info);
        return image ? image.filesUrl : "";
      };
    },
    getTitle(){
      return (item) => {
        const title = item.大標題.find(x => x.lang == "zh-TW");
        return title ? title.bannerTitle : "";
      };
    },
    getSubtitle(){
      return (item) => {
        const title = item.小標題.find(x => x.lang == "zh-TW");
        return title ? title.bannerTitle : "";
      };
    },
    getGame(){
      return (item) => {
        const game = this.gameList.find(x => x.id == item.連結參數);
        return game ? game.name : "";
      }
    }
  },

  methods: {
    getDataList() {
      this.message = "查詢中";
      //列表
      this.$store.dispatch("xsw/官網Banner列表查詢", this.searchData).then((result) => {
        if (result) {
          this.datas = result.datas || [];
          // if (this.datas.length > 0) this.datas.forEach(item => item.files.sort((a, b) => b.id - a.id));
          this.total = result.total;
          this.message = "";
          this.$refs.pagination.setPage(this.searchData.pageNum, this.total);
        }
      }, (err) => {
          this.datas = [];
          this.total = 0;
          this.message = err;
      });
    },
    getGameList(){
      if (!this.$user.hasPermission(dfn.permission.官網Banner列表查詢)) return;

      this.$store.dispatch("xsw/遊戲清單列表查詢", {
          pageNum: 0,
          pageSize: 0
        }).then((result) => {
        if (result) {
          let data = result.datas || [];
          this.gameList = data.map(x => {
            let names = x.gameListLanguages.map(y=>y.gameName);
            let displayLang = x.gameListLanguages.find(y=>y.lang=="zh-TW") || {gameName:""};
            return {
              id: x.gid,
              name: displayLang.gameName,
              names: names.join(' | ')
            }
          });
        }
      });
    },

    add(){
      if (this.isEditing) return this.$eventBus.$emit("showAlert", "請先儲存上一次欲修改/添加的項目!");
      let newItem = {
        bannerId: utils.getTimeId(),
        排序: 1,
        選項: 0,
        按鈕文字: "",
        指定連結: 0,
        連結參數: "",
        連結參數2: "",
        大標題: [],
        小標題: [],
        files: [],
        isNew: true
      }
      this.datas.unshift(newItem);
      this.edit(this.datas[0]);
    },
    edit(item){
      if (this.isEditing) return this.$eventBus.$emit("showAlert", "請先儲存上一次欲修改/添加的項目!");
      if (this.gameList.length == 0) this.getGameList();
      this.editBackup = utils.deepCloneObj(item);
      if (item.指定連結 != 0){
        item.連結參數2 = item.連結參數;
        item.連結參數 = "";
      }
      this.editLang = this.langs[0].id;
      this.isEditing = true;
      item.isEdit = true;
    },
    cancel(item, index){
      if (item.isNew) this.datas.shift();
      else this.$set(this.datas, index, this.editBackup);
      this.isEditing = false;
    },
    save(item, index){
      let err = [];
      for (let lang of this.langs){
        if (lang.required){
          let files = item.files.filter(x => x.info.indexOf(lang.id) >= 0);
          if (files && files.length < 2 ) err.push("請上傳 "+ lang.name +" Banner");
        }
      }
      if (err.length > 0){
        this.$eventBus.$emit("showAlert", err.join('<br>'));
        return;
      }

      let api = item.isNew ? "新增" : "修改";

      let sendData = this.objToPascalCase(Object.assign({}, item, {
        連結參數:(item.指定連結 == 0 ? item.連結參數 : item.連結參數2 ),
        連結參數2: undefined,
        files: undefined,
        isEdit: undefined,
        isNew: undefined
      }));

      this.$store.dispatch("xsw/官網Banner" + api, sendData).then(async reulst => {
        if (!this.isEditing){
          this.$eventBus.$emit("showAlert", this.$t('排序' + api + '成功'));
          this.getDataList();
          return;
        }
        let uploadQueue = this.$refs.banner.map(x => x.upload);
        let total = uploadQueue.length;
        let count = 0;
        let errLog = "";
        while (uploadQueue.length > 0){
          try {
            let uploadImage = uploadQueue.shift();
            let result = await uploadImage();
            count++;
            this.$eventBus.$emit("showAlert", progressMsg());
          } catch (err) {
            errLog += `<div class="text-red">${err}</div>`;
            this.$eventBus.$emit("showAlert", progressMsg());
          }
        }
        this.isEditing = false;
        this.getDataList();
        function progressMsg(){
          return `
            ${count == total ? "圖檔上傳完成!" : "圖檔上傳中..."} ${ count } / ${ total }<br>
            <progress style="width:100%" value="${count}" max="${total}"></progress>
            ${errLog}
          `;
        }
      }, error => {
        this.$eventBus.$emit("showAlert", error );
      });
    },
    copyItem(item){
      this.$eventBus.$emit("showConfirm", `確定要複製嗎?`, (isOK) => {
        if (isOK) {
          this.$store.dispatch("xsw/官網Banner複製", { BannerId: item.bannerId }).then(res => {
            this.$eventBus.$emit("showAlert", this.$t('複製成功'));
            this.getDataList();
          }, err => {
            this.$eventBus.$emit("showAlert", err );
          });
        }
      });
    },
    deleteItem(item){
      let sendData = Object.assign({}, item);
      sendData.BannerId = sendData.bannerId;
      delete sendData.bannerId;
      
      this.$eventBus.$emit("showConfirm", "確定要刪除嗎?", (isOK) => {
        if (isOK) {
          this.$store.dispatch("xsw/官網Banner刪除", sendData).then(res => {
            this.$eventBus.$emit("showAlert", this.$t('刪除成功'));
            this.getDataList();
          }, err => {
            this.$eventBus.$emit("showAlert", err );
          });
        }
      });
    },
    async onPageChange(pageIdx, pageSize) {
      this.searchData.pageNum = pageIdx;
      this.searchData.pageSize = pageSize;
      this.getDataList();
    },
    objToPascalCase(obj){
      let upperObj = Array.isArray(obj) ? [] : {};
      for (let [key, prop] of Object.entries(obj) ){
        if (typeof prop == "object"){
          prop = this.objToPascalCase(prop);
        }
        let upperKey = key.replace(/^([a-z])/i, m => m.toUpperCase());
        upperObj[upperKey] = prop;
      }
      return upperObj;
    },
  },
};
</script>
