<template>
  <div id="RewardMessageEdit">
    <section class="content-header">
      <h1>{{ id != undefined ? "編輯" : "新增" }}推播</h1>
      <ol class="breadcrumb">
        <li><router-link to="/">Home</router-link></li>
        <li><router-link to="/NMJ/Notification">推播設定</router-link></li>
        <li class="active">{{ id != undefined ? "編輯" : "新增" }}內容</li>
      </ol>
    </section>

    <section class="content">
      <div class="box box-primary">
        <div class="box-body">
          <div class="top-box with-border">
            <h4 class="pull-left" id="title">{{ id != undefined ? "編輯" : "新增" }}內容</h4>
            <button type="button" @click="backList()" class="btn btn-block btn-default btn-cog fa fa-reply">
              返回列表
            </button>
          </div>
          <form @submit.prevent="submit" class="form-horizontal" name="rewardMessageForm" method="post">
            <div class="box-body">
              <fieldset class="form-group">
                <label class="col-sm-2 control-label" for="inputTitle">
                  <span class="text-red">*</span> 推播標題：
                </label>
                <div class="col-sm-10">
                  <input class="form-control" id="inputTitle" v-model="data.標題" required placeholder="建議16字內">
                  <p class="help-block">※發送內容、語系與填寫內容相同</p>
                </div>
              </fieldset>
              <fieldset class="form-group">
                <label class="col-sm-2 control-label" for="inputContent">
                  <span class="text-red">*</span> 推播內容：
                </label>
                <div class="col-sm-10">
                  <textarea class="form-control" id="inputContent" v-model="data.內容" required placeholder="建議160字內" rows="3"></textarea>
                  <p class="help-block">※發送內容、語系與填寫內容相同</p>
                </div>
              </fieldset>
              <fieldset class="form-group">
                <label class="col-sm-2 control-label" for="inputImage">
                  推播圖片：
                </label>
                <div class="col-sm-10">
                  <div class="input-group">
                    <input class="form-control" id="inputImage" v-model="data.推播圖片" placeholder="輸入圖片網址或瀏覽影片檔案">
                    <label class="input-group-addon bg-aqua btn btn-info">
                      <span class="">上傳圖片</span>
                      <input class="sr-only" type="file" accept=".png" @change="uploadFile($event)">
                    </label>
                  </div>
                  <p class="help-block">※檔案尺寸128&times;128，小於200KB，格式PNG</p>
                </div>
              </fieldset>
              <hr>
              <div class="form-group">
                <div class="col-sm-2"></div>
                <div class="col-sm-10">
                  <div style="font-size: 16px; max-width:400px; border-radius: 10px; padding: 8px; box-shadow: 2px 2px 5px rgba(0,0,0,.25), 0 0 1px 1px rgba(0,0,0,.1);">
                    <div style="font-size: 14px; margin-bottom:3px;">
                      <img style="width: 1.4em; height: 1.4em; border-radius:.25em; float:left; margin-right:5px;" src="https://play-lh.googleusercontent.com/9RA8MlV-4o-Ys1Pmj7rm_cxgW9KYfytlcB9SJxgFt31qDgIwABghjfgR0Pt5095VVw=w32-h32-rw" >
                      <strong>麻將之星</strong>
                      <time style="float:right; opacity:.5">{{ previewTime }}</time>
                    </div>
                    <div style="display:flex; justify-content:space-between; line-height:1.4;">
                      <div style="clear:both;">
                        <strong class="text-clamp">{{ data.標題 }}</strong>
                        <span class="text-clamp2">{{ data.內容 }}</span>
                      </div>
                      <img :src="data.推播圖片" style="max-height:64px; max-width:64px; height:auto; width:auto; object-fit:cover; border-radius:.25em;" >
                    </div>
                  </div>
                </div>
              </div>
              <hr>
              <fieldset class="form-group">
                <label class="col-sm-2 control-label" for="recipientRadio">
                  <span class="text-red">*</span> 發送對象：
                </label>
                <div class="radio col-sm-10">
                  <iCheckRedioButton name="recipientRadio" message="全部" class="mr-10"
                    checkValue="1" v-model.number="data.發送對象"
                  />
                  <iCheckRedioButton name="recipientRadio" message="iOS" class="mr-10"
                    checkValue="3" v-model.number="data.發送對象"
                  />
                  <iCheckRedioButton name="recipientRadio" message="Android" class="mr-10"
                    checkValue="4" v-model.number="data.發送對象"
                  />
                  <iCheckRedioButton name="recipientRadio" message="限定用戶" class="mr-10"
                    checkValue="2" v-model.number="data.發送對象"
                  />
                  <label>
                    <span class="btn btn-info">瀏覽.csv</span>
                    <span class="text ml-10">{{ fileName }}</span>
                    <input class="sr-only" type="file" accept=".csv" @change="onImportCSV($event)">
                    <a class="btn btn-success ml-10" href="/uploads/NMJ/example.csv">下載範例.csv檔</a>
                  </label>
                  <pre v-if="data.發送對象 == 2 && data.限定用戶.length > 0" class="mt-10 m-0" style="overflow-y: auto; max-height:240px; white-space:pre-wrap;">{{ data.限定用戶.join(",\n") }}</pre>
                </div>
              </fieldset>
              <fieldset class="form-group" v-if="data.發送對象 == 1">
                <label class="col-sm-2 control-label" for="loginRadio">
                  <span class="text-red">*</span> 登入狀態：
                </label>
                <div class="radio col-sm-10">
                  <iCheckRedioButton name="loginRadio" message="不限" class="mr-10"
                    checkValue="1" v-model.number="data.登入狀態"
                  />
                  <iCheckRedioButton name="loginRadio" message="是" class="mr-10"
                    checkValue="2" v-model.number="data.登入狀態"
                  />
                  <iCheckRedioButton name="loginRadio" message="否" class="mr-10"
                    checkValue="3" v-model.number="data.登入狀態"
                  />
                  <span class="text text-muted">※選擇「是」需登入APP才能收到推播，選擇「否」需不開啟APP可收到推播</span>
                </div>
              </fieldset>
              <!-- <fieldset class="form-group">
                <label class="col-sm-2 control-label" for="platformRadio">
                  <span class="text-red">*</span> 發送平台：
                </label>
                <div class="radio col-sm-10">
                  <iCheckRedioButton name="platformRadio" message="全部" class="mr-10"
                    checkValue="1" v-model.number="data.發送平台"
                  />
                  <iCheckRedioButton name="platformRadio" message="iOS" class="mr-10"
                    checkValue="2" v-model.number="data.發送平台"
                  />
                  <iCheckRedioButton name="platformRadio" message="Android" class="mr-10"
                    checkValue="3" v-model.number="data.發送平台"
                  />
                </div>
              </fieldset> -->
              <fieldset class="form-group">
                <label class="col-sm-2 control-label" for="timeRadio">
                  <span class="text-red">*</span> 推播時間：
                </label>
                <div class="radio col-sm-10">
                  <p>
                    <iCheckRedioButton name="timeRadio" message="立即發送" style="margin-right:1em"
                      checkValue="1" v-model.number="data.推播時間"
                    />
                    <iCheckRedioButton name="timeRadio" message="預約發送"
                      checkValue="2" v-model.number="data.推播時間"
                    />
                  </p>
                  <div class="radio-date" v-show="data.推播時間 == 2">
                    <DateAndTimePicker v-model="data.預約時間" format="YYYY-MM-DD HH:mm:ss"></DateAndTimePicker>
                  </div>
                </div>
              </fieldset>
            </div>
            <div class="box-footer">
              <div class="btns">
                <button type="submit" class="btn btn-primary btn-lg fa fa-send-o" style="margin-right:.5em">
                  確認送出
                </button>
                <button type="button" @click="backList()" class="btn btn-default btn-lg fa fa-reply" style="margin-right:.5em">
                  取消
                </button>
                <button type="button" v-if="id" @click="deleteMessage()" class="btn btn-danger btn-lg fa fa-trash">
                  取消此推播
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
      <!-- <pre>
        {{ data }}
      </pre> -->
      <!-- <pre>{{ JSON.parse(`{\r\n    \"標題\": \"測試推播訊息3\",\r\n    \"內容\": \"測試推播訊息4\",\r\n  
        \"推播圖片\": \"https://storage.waningames.com/omp_nmj/news/aa01144521eabf288fb11b6efeea7755.png\",\r\n    
        \"發送對象\": 1,\r\n    \"發送狀態\": 1,\r\n    \"登入狀態\": 1,\r\n    \"發送平台\": 1,\r\n    \"推播時間\": 2,\r\n  
          \"失敗log\": \"\",\r\n    \"限定用戶\": [],\r\n    \"預約時間\": \"2024-09-30 13:30:00\"\r\n}`) }}</pre> -->
    </section>
  </div>
</template>

<style scoped>
  .control-label{
    padding-right: 0;
  }
  .text-clamp{
    display:-webkit-box;
    -webkit-box-orient:vertical;
    -webkit-line-clamp:1;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .text-clamp2{
    display:-webkit-box;
    -webkit-box-orient:vertical;
    -webkit-line-clamp:2;
    overflow: hidden;
    text-overflow: ellipsis;
  }
</style>

<script>
/* eslint-disable */
import define from "@/views/projects/NMJ/_Define";
import moment from "moment";
import DatePicker from "@/components/DatePicker";
import DateAndTimePicker from "@/components/DateAndTimePicker";
import iCheckCheckBox from "@/components/iCheckBox";
import iCheckRedioButton from "@/components/iCheckRedioButton";
import MultilangInput from "../AWH5/components/MultilangInput.vue";
import { mapState } from "vuex";

export default {
  components: {
    DatePicker,
    DateAndTimePicker,
    iCheckCheckBox,
    iCheckRedioButton,
    MultilangInput,
  },
  props: {
    id: "",
  },
  data() {
    return {
      def: define,
      loading: true,
      titleMax: 16,
      contentMax: 200,
      data: {
        "標題": "",
        "內容": "",
        "推播圖片": "",
        "發送對象": 1, // 兩種發送對象：1.全用戶(預設)、2.限定用戶(需另外上傳名單)、3.iOS、4.Android
        "發送狀態": 1,
        "登入狀態": 1, // 是否限制登入APP才收的到推播：1.不限(預設)、2.是、3.否
        "發送平台": 1,
        "推播時間": 1, // 推播時間
        "失敗log": "",
        "限定用戶": [],
        "預約時間": new moment().add(1, "days").format("YYYY-MM-DDT10:00:00"),
      },
      fileName: "",
    };
  },
  watch: {
    "data.發送對象": function(value) {
      switch(value){
        case 1: this.data.發送平台 = 1; break;
        case 2: this.data.發送平台 = 1; break;
        case 3: this.data.發送平台 = 2; break;
        case 4: this.data.發送平台 = 3; break;
      }
      switch(value){
        case 2: this.data.登入狀態 = 1; break;
        case 3: this.data.登入狀態 = 1; break;
        case 4: this.data.登入狀態 = 1; break;
      }
    }
  },
  mounted() {
    this.getMessage();
  },
  computed: {
    previewTime(){
      let date = this.data.推播時間 == 2 ? new Date(this.data.預約時間) : new Date();
      return date.getHours() + ":" + date.getMinutes().toString().padStart(2,"0");
    }
  },
  methods: {
    getMessage() {
      if (this.id) {
        this.$store.dispatch("nmjNotification/query", {id: this.id}).then((result) => {
          Object.assign(this.data, result);
          if (this.data.推播名單) {
            try {
              this.data.限定用戶 = JSON.parse(this.data.推播名單);
            } catch {

            }
          }
        }, err => {
          this.$eventBus.$emit("showAlert", err);
        });
      }
    },
    backList() {
      this.$router.push({
        name: "NmjNotification",
        params: {
          searchData: this.$route.params.searchData,
        },
      });
    },
    submit() {
      if (this.data.推播時間 == 1){
        this.data.預約時間 = new moment().format("YYYY-MM-DDTHH:mm:ss");
        this.data.發送狀態 = 2;
      } 
      const api = this.data.id ? "update" : "create";
      this.$store.dispatch(`nmjNotification/${api}`, this.data).then(() => {
        this.backList();
      }, err => {
        this.$eventBus.$emit("showAlert", err);
      });
    },
    deleteMessage(){
      this.$store.dispatch(`nmjNotification/delete`, this.data).then(() => {
        this.backList();
      }, err => {
        this.$eventBus.$emit("showAlert", err);
      });
    },//存入檔案 還需調整
    uploadFile(event) {
      let files = event.target.files;
      if (files[0].size > (200 * 1024)){
        this.$eventBus.$emit("showAlert", `您選擇的檔案「${files[0].name}」超過上限 <b class="text-red">200KB</b>，請重新選擇。`);
        return
      }
      // console.log(files, projectId, folderName, type);

      let data = new FormData();

      for (var i = 0; i < files.length; i++) {
        data.append("files", files[i]);
      }
      data.append("files", files);
      data.append("projectId", this.$store.projectId);
      data.append("module", "notification");
      data.append("moduleId", "image");

      this.$store.dispatch("nmjClassTypes/fileUpload", data).then((result) => {
        if (result.status != "1") {
          this.$eventBus.$emit("showAlert", "檔案上傳失敗！");
        } else {
          this.data.推播圖片 = result.result[0].filesUrl;
        }
      });
    },
    onImportCSV(event){
      let file = event.target.files[0];
      this.fileName = file.name;
      this.data.發送對象 = 2;

      let reader = new FileReader();
      reader.onload = (evt) => {
        let string = evt.target.result;
        let arr = string.split(/\s*,\s*/g);
        arr = arr.map(s => s.trim());
        arr = arr.filter(x => x != "");

        this.data.限定用戶 = arr;
      }
      reader.readAsText(file);
    }
  },
};
</script>