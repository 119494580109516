import Vue from 'vue';
import axios from 'axios';
import VueI18n from "vue-i18n";

Vue.use(VueI18n);

/**
 * ProjectLangSetting 專案預設語系與是否能切換語系
 * key值為專案名稱，
 * defLang，該專案的預設語系
 * langList，切換列表，如果為空陣列，畫面不顯示切換的按鈕
 */
const ProjectLangSetting = {
    defautlProject: { defLang: "zh-TW", langList: [] },//defautlProject，這行設定不要修改，設定沒加的專案，都跑此組設定，繁中，且不可切換語系
    omp_starcastle: { defLang: "th-TH", langList: [{ id: "zh-TW", name: 'TW' }, { id: "th-TH", name: 'TH' }] },
    // omp_xinstars: { defLang: "zh-TW", langList: [{ id: "zh-TW", name: 'TW' }, { id: "th-TH", name: 'TH' }] },
    omp_FBRobot: { defLang: "zh-TW", langList: [{ id: "zh-TW", name: 'TW' }, { id: "en-US", name: 'EN' }, { id: "th-TH", name: 'TH' }] },
    omp_xin_my_web: { defLang: "en-US", langList: [{ id: "zh-TW", name: 'TW' }, { id: "en-US", name: 'EN' }] },
    omp_xin_my: { defLang: "en-US", langList: [{ id: "zh-TW", name: 'TW' }, { id: "en-US", name: 'EN' }] },
}

const i18n = new VueI18n({
    locale: "無設定",
    fallbackLocale: "",
    messages: {},
    silentTranslationWarn: true,
});

export default i18n;

function _setI18nLanguage(lang, projectId) {
    i18n.locale = lang;
    try {
        localStorage.setItem(`lang_${projectId}`, lang);
    } catch (e) {
        //儲存失敗
    }
}

const loadedLanguages = []; // our default language that is preloaded

export async function loadLanguageAsync(lang, projectId) {
    try {
        if (i18n.locale === lang) {
            return Promise.resolve(_setI18nLanguage(lang, projectId))
        }
        if (loadedLanguages.includes(lang)) {
            return Promise.resolve(_setI18nLanguage(lang, projectId))
        }
        let response = await axios({
            method: 'get',
            url: `./lang/${lang}.json?${new Date().getTime()}`,
            data: {
            }
        });
        let messages = response.data;
        i18n.setLocaleMessage(lang, messages);
        loadedLanguages.push(lang)
        _setI18nLanguage(lang, projectId);
        return true;
    } catch (e) {
        console.error("載入失敗", e);
        return false;
    }
}

export const ProjectLang = {
    //帶入專案，回傳此專案的語系設定值
    getLangSetting(projectId) {
        return ProjectLangSetting[projectId] ? ProjectLangSetting[projectId] : ProjectLangSetting.defautlProject;
    },
}
//帶入專案，取得最適合的語系
function getLang(projectId, defLang) {
    try {
        //回傳localStorage記錄的語系
        let savedLang = localStorage.getItem(`lang_${projectId}`);
        if (ProjectLang.getLangSetting(projectId).langList.find((item) => { return item.id == savedLang; })) {
            return savedLang;
        }
    } catch (e) {
        //抓取localStorage記錄的語系失敗
    }
    try {
        //回傳前端設定的語系
        return ProjectLang.getLangSetting(projectId).defLang;
    } catch (e) {
        //抓取前端設定的語系失敗
    }
    //回傳預設語系
    return defLang;
}
export async function loadLangByProject(projectId) {
    let lang = getLang(projectId, "zh-TW");
    let isSuccess = await loadLanguageAsync(lang, projectId);
    if (!isSuccess) {
        await loadLanguageAsync("zh-TW", projectId);
    }
}