<template>
  <div id="GameMessage">
    <section class="content-header">
      <h1>{{$t('遊戲信件發送管理')}}</h1>
      <ol class="breadcrumb">
        <li><router-link to="/">{{$t('首頁')}}</router-link></li>
        <li class="active">{{$t('遊戲信件發送管理')}}</li>
      </ol>
    </section>
    <section class="content">
      <div class="nav-tabs-custom mb-10">
        <div class="box-header" style="padding-bottom:0;">
          <form class="top-box clearfix" 
            @submit.prevent="pageNum = 1; getDataList();">
            <div class="pull-left" v-if="can歷程 || can持有">
              <div class="input-group pull-left mb-10 mr-10">
                <input type="text" v-model="searchData.暱稱" class="form-control" :placeholder="$t('UID')" >
              </div>
              <div class="pull-left m-0">
                <button type="submit" class="btn btn-primary pull-left mb-10">
                  {{$t('查詢')}}
                </button>
              </div>
            </div>
            <router-link v-if="can發送" class="btn btn-block btn-warning btn-add pull-right mb-10"
              :to="{name:'XsgGameMessageEdit', params:{nick:searchData.暱稱}}">
              <i class="fa fa-plus"></i> {{$t('新增信件')}}
            </router-link>
          </form>
        </div>
      </div>
      <div class="nav-tabs-custom tab-warning" v-if="tab != 0">
        <ul class="nav nav-tabs">
          <li :class="{ active: tab == 1 && can歷程}"><a @click="tab = 1" style="cursor:pointer;">{{$t('歷程列表')}}</a></li>
          <li :class="{ active: tab == 2 && can持有}"><a @click="tab = 2" style="cursor:pointer;">{{$t('持有信件列表')}}</a></li>
        </ul>
        <div v-show="tab == 1" class="box-body">
          <div class="top-box">
            <div class="input-group pull-left mb-10 mr-10">
              <input type="text" v-model="filterString" class="form-control" :placeholder="$t('搜尋')">
            </div>
            <div class="select-group pull-left mb-10 mr-10">
              <select class="form-control" v-model="filterState">
                <option value="">{{$t('全部動作')}}</option>
                <option v-for="option in filterOptions" :value="option">{{ $t(option) }}</option>
              </select>
            </div>
            <div class="form-group pull-left m-0">
              <label class="control-label pull-left mt-7">{{$t('發送時間')}}：</label>
              <div class="input-group mb-10 mr-10">
                <div class="input-group-addon fa fa-calendar"></div>
                <DateRangePicker
                  :propEndDate.sync="searchData.結束時間"
                  :propStartDate.sync="searchData.開始時間"
                  displayFormat="YYYY/MM/DD HH:mm"
                  format="YYYY-MM-DD HH:mm:ss"
                />
              </div>
            </div>
            <div class="pull-left m-0">
              <button @click="pageNum = 1; getDataList();" class="btn btn-primary pull-left mb-10 mr-10">
                {{$t('查詢')}}
              </button>
              <button class="btn btn-default pull-left mb-10" @click="exportHistory()" :disabled="datas.length === 0">
                {{$t('匯出')}}
              </button>
            </div>
          </div>
          <div class="table-responsive mt-0">
            <table class="table table-bordered text-center">
              <tbody>
                <tr class="bg-gray">
                  <th style="min-width: 80px; width:10%">{{$t('信件編號')}}</th>
                  <th style="min-width:105px; width:10%">{{$t('寄件者')}}</th>
                  <th style="min-width:105px; width:10%">{{$t('收件者')}}</th>
                  <th style="min-width: 80px; width: 5%">{{$t('信件動作')}}</th>
                  <th style="min-width:105px; width: 5%">{{$t('物品編號')}}</th>
                  <th style="min-width:105px; width: 5%">{{$t('數量')}}</th>
                  <th style="min-width:105px; width:10%">{{$t('時間')}}</th>
                </tr>
                <tr v-if="message"><td colspan="8">{{ $t(message) }}</td></tr>
                <tr v-else-if="filterMessage"><td colspan="8">{{ $t(filterMessage) }}</td></tr>
                <tr v-else v-for="(item, index) in pagedData" :key="index" :class="{ 'bg-gray-light': item.狀態 === 3 }">
                  <td v-html="item.信件編號"></td>
                  <td v-html="item.寄件者"></td>
                  <td v-html="item.收件者"></td>
                  <td v-html="$t(item.信件動作)"></td>
                  <td colspan="2" style="padding:.5em;">
                    <span v-if="!item.items || item.items.length == 0">-</span>
                    <table class="table table-bordered table-condensed table-striped">
                      <tbody>
                        <tr></tr>
                        <tr v-for="x in item.items">
                          <td style="width:50%;padding:.1em;" :title="$t(gameTypes[x.物品編號])">
                            {{ x.物品編號 || "-" }}
                            <small class="text-muted" v-if="gameTypes[x.物品編號]">({{ $t(gameTypes[x.物品編號]) }})</small>
                          </td>
                          <td style="width:50%;padding:.1em;">{{ x.數量 || "-" }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                  <td>{{ uiDate(item.時間) }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="box-footer text-center no-border">
            <Pagination
              :pageLimitCount="pageSize"
              :total="total"
              v-on:pageMethod="onPageChange"
            />
          </div>
        </div>
        <div v-show="tab == 2" class="box-body">
          <div class="top-box">
            <div class="input-group pull-left mb-10 mr-10">
              <input type="text" v-model="filterHoldString" class="form-control" :placeholder="$t('搜尋')">
            </div>
            <div class="select-group pull-left mb-10 mr-10">
              <select class="form-control" v-model="filterHoldState">
                <option value="">{{$t('領取狀態')}}</option>
                <option value="true">{{$t('已領取')}}</option>
                <option value="false">{{$t('未領取')}}</option>
              </select>
            </div>
            <div class="pull-left m-0">
              <button class="btn btn-default pull-left mb-10" @click="exportHold()" :disabled="holdList.length === 0">
                {{$t('匯出')}}
              </button>
            </div>
          </div>
          <div class="table-responsive mt-0">
            <table class="table table-bordered text-center">
              <tbody>
                <tr class="bg-gray">
                  <th style="min-width: 80px; width:10%">{{$t('信件編號')}}</th>
                  <th style="min-width:105px; width:10%">{{$t('寄件者')}}</th>
                  <th style="min-width:105px; width:10%">{{$t('標題')}}</th>
                  <th style="min-width: 80px; width: 5%">{{$t('領取狀態')}}</th>
                  <th style="min-width:105px; width: 5%">{{$t('物品編號')}}</th>
                  <th style="min-width:105px; width: 5%">{{$t('數量')}}</th>
                  <th style="min-width:105px; width:10%">{{$t('領取期限')}}</th>
                </tr>
                <tr v-if="holdMessage"><td colspan="8">{{ $t(holdMessage) }}</td></tr>
                <tr v-else-if="filterHoldMessage"><td colspan="8">{{ $t(filterHoldMessage) }}</td></tr>
                <tr v-else v-for="(item, index) in pagedHoldData" :key="index" :class="{ 'bg-gray-light': item.狀態 === 3 }">
                  <td v-html="item.id"></td>
                  <td v-html="item.寄件者"></td>
                  <td v-html="item.標題"></td>
                  <td :class="item.已領取 ? 'text-green':'text-red'">{{ $t(item.已領取 ? "已領取" : "未領取")}}</td>
                  <!-- <td>
                    <div v-if="!item.items || item.items.length == 0">-</div>
                    <div v-else v-for="x in item.items" :title="'id: ' + x.物品編號">{{ $t(gameTypes[x.物品編號]) || "-" }}</div>
                  </td>
                  <td>
                    <div v-if="!item.items || item.items.length == 0">-</div>
                    <div v-else v-for="x in item.items">{{ x.數量 || "-" }}</div>
                  </td> -->
                  <td colspan="2" style="padding:.5em;">
                    <span v-if="!item.items || item.items.length == 0">-</span>
                    <table class="table table-bordered table-condensed table-striped">
                      <tbody>
                        <tr></tr>
                        <tr v-for="x in item.items">
                          <td style="width:50%;padding:.1em;" :title="$t(gameTypes[x.物品編號])">
                            {{ x.物品編號 || "-" }}
                            <small class="text-muted" v-if="gameTypes[x.物品編號]">({{ $t(gameTypes[x.物品編號]) }})</small>
                          </td>
                          <td style="width:50%;padding:.1em;">{{ x.數量 || "-" }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                  <td>{{ uiDate(item.期限) }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="box-footer text-center no-border">
            <Pagination
              :pageLimitCount="holdPageSize"
              :total="holdTotal"
              v-on:pageMethod="onPageChange"
            />
          </div>
        </div>
        <!-- <pre>{{ datas[0] }}</pre> -->
        <!-- <pre>{{ holdList[0] }}</pre> -->
        <!-- <pre>{{ gameTypes }}</pre> -->
      </div>
    </section>
  </div>
</template>

<style scoped>
.table>>>mark{
  padding: 0;
  background: #ff8;
}
</style>

<script>
/* eslint-disable */
import dfn from "@/views/projects/XSG/_Define";
import moment from "moment";
import DateRangePicker from "@/components/DateRangePicker";
import Pagination from "@/components/Pagination";
import utils from "@/assets/js/app-utils";

export default {
  components: {
    DateRangePicker,
    Pagination,
  },
  data() {
    return {
      tab: 0,

      filterString: "",
      filterState: "",
      filterHoldString: "",
      filterHoldState: "",

      searchData: {
        暱稱: "",
        開始時間: new moment().subtract(1, "month").format("YYYY-MM-DD 00:00:00"),
        結束時間: new moment().format("YYYY-MM-DD 23:59:59")
      },
      datas: [],
      loading: false,
      message: "請先查詢暱稱",
      pageSize: 20,
      pageNum: 1,
      total:  0, //總筆數

      holdList: [],
      holdLoading: false,
      holdMessage: "請先查詢暱稱",
      holdPageSize: 20,
      holdPageNum: 1,
      holdTotal:  0, //總筆數

      senderLang: {},

      gameTypes: {},
      checks: ["類型編號", "類型名稱", "獎項編號", "獎項名稱"],

      // 權限
      can發送: this.$user.hasPermission(dfn.permission.信件發送),
      can歷程: this.$user.hasPermission(dfn.permission.信件歷程查詢),
      can持有: this.$user.hasPermission(dfn.permission.持有信件查詢),
    };
  },

  computed: {
    filterOptions: function(){
      return Array.from(new Set(this.datas.map(item => item.信件動作)));
    },
    filterData: function() {
      let data = this.datas;
      if (this.filterState != ""){
        data = data.filter(item => item.信件動作 == this.filterState);
      }
      if (this.filterString != ""){
        let re = new RegExp(`(${this.filterString.trim()})` , "ig");
        let arr = [];
        for (let item of data){
          item = Object.assign({}, item);
          let isMatch = false;
          if (re.test(item.信件編號)){ isMatch = true; item.信件編號 = item.信件編號.replace(re, "<mark>$1</mark>");} 
          if (re.test(item.寄件者)){ isMatch = true; item.寄件者 = item.寄件者.replace(re, "<mark>$1</mark>");}
          if (re.test(item.收件者)){ isMatch = true; item.收件者 = item.收件者.replace(re, "<mark>$1</mark>");}
          if (isMatch) arr.push(item);
        }
        data = arr;
      }
      this.$nextTick(()=>{
        this.pageNum = 1;
        this.total = data.length;
      })
      return data;
    },

    pagedData: function () {
      return this.filterData.slice((this.pageNum - 1) * this.pageSize, (this.pageNum) * this.pageSize);
    },

    filterHoldData: function() {
      let data = this.holdList;
      if (this.filterHoldState != ""){
        data = data.filter(item => item.已領取.toString() == this.filterHoldState);
      }
      if (this.filterHoldString != "") {
        let re = new RegExp(`(${this.filterHoldString.trim()})` , "ig");
        let arr = [];
        for (let item of data){
          item = Object.assign({}, item);
          let isMatch = false;
          if (re.test(item.id)){ isMatch = true; item.id = item.id.replace(re, "<mark>$1</mark>");} 
          if (re.test(item.寄件者)){ isMatch = true; item.寄件者 = item.寄件者.replace(re, "<mark>$1</mark>");}
          if (re.test(item.暱稱)){ isMatch = true; item.暱稱 = item.暱稱.replace(re, "<mark>$1</mark>");}
          if (isMatch) arr.push(item);
        }
        data = arr;
      }
      this.$nextTick(()=>{
        this.holdPageNum = 1;
        this.holdTotal = data.length;
      })
      return data;
    },
    pagedHoldData: function () {
      return this.filterHoldData.slice((this.holdPageNum - 1) * this.holdPageSize, (this.holdPageNum) * this.holdPageSize);
    },
    filterHoldMessage: function(){
      let msg = "";
      if (this.holdList.length > 0 && this.filterHoldData.length == 0){
        let err = []
        if (this.filterHoldString != "") err.push(this.filterHoldString);
        if (this.filterHoldState != "") err.push( this.$t(this.filterHoldState == "true" ? "已領取" : "未領取"));
        msg =  this.$t("資料中搜尋「X」無結果", [err.join(' + ')]);
      }
      return msg;
    },
    filterMessage: function(){
      let msg = "";
      if (this.datas.length > 0 && this.filterData.length == 0){
        let err = []
        if (this.filterString != "") err.push(this.filterString);
        if (this.filterState != "") err.push( this.$t(this.filterState));
        msg =  this.$t("資料中搜尋「X」無結果", [err.join(' + ')]);
      }
      return msg;
    }
  },
  watch: {
    
  },
  async created() {
    this.translateSender();

    if (this.can歷程) this.tab = 1;
    else if (this.can持有) this.tab = 2;

    if (this.$route.query.nick) {
      this.searchData.暱稱 = this.$route.query.nick;
      this.getDataList();
    }

    this.gameTypes = Object.assign(dfn.gameItem /* 預設ID */, {} /* 未來API取物品ID */)
    // this.getDataList();
    // this.$store.dispatch("nmjMail/itemList").then(result => {
    //   this.gameTypes = result;
    //   this.gameTypes.Item[0] = "雀幣";
    // })
  },
  methods: {
    resetDataList() {
      this.pageNum = 1;
      this.getDataList();
      // this.onPageChange(1, this.searchData.pageSize)
    },
    getDataList() {
      if (!this.searchData.暱稱) {
        this.$eventBus.$emit("showAlert", this.$t("請填寫會員暱稱！"));
        return;
      }

      let query = {};
      if (this.searchData.暱稱 != "") query['nick'] = this.searchData.暱稱;
      if (JSON.stringify(query) != JSON.stringify(this.$route.query)){
        this.$router.replace({query:query});
      }

      if (this.can歷程){
        this.message = "查詢中";
        this.loading = true;
        this.$store.dispatch('xsw/信件歷程查詢', this.searchData)
        .then(result => {
          let res = result.datas;
          res.sort((a, b) => {return new Date(b.時間) - new Date(a.時間) });
          // console.log(res)
          // this.datas = res || [];
          this.datas = this.replaceSenderLang(res) || [];
          this.total = this.datas.length;
          this.message = this.datas.length ? "" : "查無資料";
          this.loading = false;
          this.pageNum = 1;
        }, (msg) =>{
          this.message = msg;
          this.loading = false;
        });
      }
      if (this.can持有){
        this.holdMessage =  this.$t("查詢中");
        this.holdLoading = true;
        this.$store.dispatch('xsw/持有信件查詢', this.searchData)
        .then(res => {
          let data = res.datas;
          data.sort((a, b) => b.id - a.id);
          // console.log(res)
          // this.holdList = res.datas || [];
          this.holdList = this.replaceSenderLang(res.datas) || [];
          this.holdTotal = res.total;
          this.holdMessage = res.total ? "" :  this.$t("查無資料");
          this.holdLoading = false;
          this.holdPageNum = 1;
        }, (msg) =>{
          this.holdMessage = msg;
          this.holdLoading = false;
        });
      }
    },
    
    translateSender(){
      this.$store.dispatch('xsw/語言檔查詢')
        .then(res => {
          let lang = res || [];
          lang["lang_200"] = "JOYOUS PALACE";
          this.senderLang = lang;
          // console.log(this.senderLang);
      
        }, (msg) =>{
          this.message = msg;
          this.loading = false;
        });
    },

    replaceSenderLang(items) {
      return items.map(item => {
        if (item.寄件者.startsWith("@lang_")) {
          // console.log(item)
          const langKey = item.寄件者.split("@lang_")[1];
          item.寄件者 = this.senderLang['lang_' + langKey] || item.寄件者; 
        }
        return item;
      });
    },

    async onPageChange(pageIdx, pageSize) {
      if (this.tab == 1){
        this.pageNum = pageIdx;
        this.pageSize = pageSize;
      } else {
        this.holdPageNum = pageIdx;
        this.holdPageSize = pageSize;
      }
      // this.getDataList();
    },

    exportHistory() {
        let data = utils.deepCloneObj(this.datas);
        let items = [];
        data.forEach(item => {
            items.push({
              '信件編號': item.信件編號.toString(),
              '寄件者': item.寄件者,
              '收件者': item.收件者,
              '信件動作': item.信件動作,
              '物品編號': ['0', '1', '3'].includes(item.物品編號) ? item.物品編號 : null,
              '數量': item.數量 != "0" ? item.數量 : null,
              '領取期限': this.uiDate(item.時間)
            });
        });
        utils.exportData(
            items,
            `${utils.formatTime(new Date(), 'YYYYMMDD')}_${this.searchData.暱稱}_持有信件列表.xlsx`,
            '持有信件列表'
        );
    },
    exportHold() {
        let data = utils.deepCloneObj(this.holdList);
        let items = [];
        data.forEach(item => {
            items.push({
              '信件編號': item.id.toString(),
              '寄件者': item.寄件者,
              '收件者': item.暱稱,
              '領取狀態': item.已領取 ? "已領取" : "未領取",
              '物品編號': ['0', '1', '3'].includes(item.物品編號) ? item.物品編號 : null,
              '數量': item.數量 != "0" ? item.數量 : null,
              '領取期限': this.uiDate(item.期限)
            });
        });
        utils.exportData(
            items,
            `${utils.formatTime(new Date(), 'YYYYMMDD')}_${this.searchData.暱稱}_信件歷程列表.xlsx`,
            '信件歷程列表'
        );
    },

    uiDate(date){
      let d = new Date(date);
      if (date && d) return d.toLocaleString('zh-TW', {
        year:"numeric",
        month:"2-digit",
        day:"2-digit",
        hour:"2-digit",
        minute:"2-digit",
        second:"2-digit",
        hourCycle:"h23",
      });
      else return '';
    }
  },
};
</script>
