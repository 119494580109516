<template>
  <div id="MemberData">
    <section class="content-header">
      <h1>{{$t('序號管理')}}</h1>
      <ol class="breadcrumb">
        <li><router-link to="/">{{$t('首頁')}}</router-link></li>
        <li class="active">{{$t('序號管理')}}</li>
      </ol>
    </section>
    <section v-if="!id" class="content" style="min-height:auto;">
      <div class="box">      
        <!-- 遊戲歷程 -->
        <div class="box-body">
          <form class="top-box clearfix" @submit.prevent="getData()">
            <div class="input-group pull-left mb-10 mr-10">
              <input type="text" class="form-control" :placeholder="$t('序號')" v-model="searchData.序號">
            </div>
            <!-- <div class="select-group mb-10 mr-10">
              <select class="form-control" v-model.number="searchData.類型" @change="getData()">
                <option :value="0">{{$t('全部類型')}}</option>
                <option v-for="(name, key) in serialTypes" :key="key" :value="key">{{$t(name)}}</option>
              </select>
            </div> -->
            <div class="form-group pull-left m-0">
              <!-- <div class="form-group pull-left m-0">
                <label class="control-label pull-left mt-7">{{$t('查詢區間')}}：</label>
                <div class="input-group date pull-left mb-10 mr-10">
                  <div class="input-group-addon fa fa-calendar"></div>
                  <DateRangePicker
                    :propStartDate.sync="searchData.開始時間"
                    :propEndDate.sync="searchData.結束時間"
                    displayFormat="YYYY/MM/DD HH:mm"
                  />
                </div>
              </div> -->

              <div class="pull-left mb-10 mr-10">
                <button type="submit" class="btn btn-primary mr-10">
                  {{$t('查詢')}}
                </button>
                <button type="button" class="btn btn-default" @click="exportData()" :disabled="datas.length === 0">
                  {{$t('匯出')}}
                </button>
              </div>
              <!-- <div class="text mb-10">
                {{ $t('資料總筆數') }}: <span>{{ total }}</span>
              </div> -->
            </div>
          </form>

          <div class="table-responsive max850">
            <table class="table table-striped table-bordered text-center">
              <thead>
                <tr class="bg-gray">
                  <!-- <th style="min-width: 50px; width: 5%">{{$t('ROWID')}}</th> -->
                  <th style="min-width:150px; width:10%">{{$t('序號')}}</th>
                  <th style="min-width:120px; width:10%">{{$t('兌換品項')}}</th>
                  <th style="min-width:120px; width:10%">{{$t('兌換項目')}}</th>
                  <th style="min-width:150px; width:10%">{{$t('兌換時間')}}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-if="loading">
                  <td colspan="11">{{$t('查詢中')}}</td>
                </tr>
                <tr v-else-if="message">
                  <td colspan="11">{{ $t(message) }}</td>
                </tr>
                <tr v-else-if="datas.length === 0">
                  <td colspan="11">{{$t('查無資料')}}</td>
                </tr>
                <tr v-else v-for="(item, index) in pagedData" :key="item.兌換日期">
                  <!-- <td>{{ index + 1 + ((searchData.pageNum-1)*searchData.pageSize) }}</td> -->
                  <td>{{ item.序號 }}</td>
                  <td>{{ $t('新手禮包') }}</td>
                  <!-- <td>{{ item.兌換玩家 }}</td> -->
                  <td>
                    <!-- {{ item.兌換物品 }} -->
                    <template v-for="(x, i) in item.兌換物品">
                      {{ i!=0?', ':'' }}{{ $t(dfn.gameItem[x.id]) || `(id:${x.id})` }}&times;{{ Number(x.count).toLocaleString('zh-TW') }}
                    </template>
                  </td>
                  <td>{{ datas.length }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div v-show="false && total > searchData.pageSize" class="text-center mt-10">
            <Pagination ref="pagination" :total="total"
              :pageLimitCount="searchData.pageSize"
              v-on:pageMethod="onPageChange" :type2="true"
            />
          </div>
        </div>
      </div>
    </section>

    <!-- <pre>{{ eventList }}</pre> -->
    <!-- <pre>{{ accountList }}</pre> -->
    <!-- <pre>{{ serialList }}</pre> -->
  </div>
</template>

<style scoped>
.table >>> mark{
  padding: 0;
  background: #fea;
}
</style>

<script>
/* eslint-disable */
import moment from "moment";
import types from "@/views/projects/XSG/_Define";
import dfn from "@/views/projects/XSW/_Define";
import DateRangePicker from "@/components/DateRangePicker";
import Pagination from "@/components/Pagination";
import iCheckCheckBox from "@/components/iCheckBox";
import router from "../../../router";
import utils from "@/assets/js/app-utils";

export default {
  components: {
    DateRangePicker,
    iCheckCheckBox,
    Pagination,
    router
  },
  props:{
    id: ""
  },
  data() {
    return {
      dfn: dfn,
      searchData: { 
        "序號": "",
        pageNum: 1,
        pageSize: 1,
      },
      datas: [], //all data
      total: 0, //總筆數
      message: "",
      loading: false,
    };
  },
  async created() {
    // this.searchSerialData.序號 = this.id;
    // if (this.$route.query.nick) {
    //   this.searchAccountData.UID = this.$route.query.nick;
    //   this.tab = 2;
    // }
    this.getData();
  },
  watch: {

  },
  computed: {
    pagedData: function(){
      let pageNum = this.searchData.pageNum;
      let pageSize = this.searchData.pageSize;
      return this.datas.slice((pageNum - 1) * pageSize, (pageNum) * pageSize);
    },
    // pagedAccountData: function(){
    //   let pageNum = this.searchAccountData.pageNum;
    //   let pageSize = this.searchAccountData.pageSize;
    //   return this.accountList.slice((pageNum - 1) * pageSize, (pageNum) * pageSize);
    // },
    // filteredSerialData: function(){
    //   let data = utils.deepCloneObj(this.serialList);
    //   let f = this.filterSerialData;
    //   let sDate = new Date(f.開始時間).valueOf();
    //   let eDate = new Date(f.結束時間).valueOf();

    //   if (f.狀態 == "已兌換" || f.狀態 == "時間區間"){
    //     data = data.filter(x => x.兌換狀態 == "已兌換");
    //   }
    //   if (f.狀態 == "時間區間"){
    //     data = data.filter(x => {
    //       if (x.兌換時間 == "") return true;
    //       let d = new Date(x.兌換時間).valueOf();
    //       return sDate <= d && d <= eDate;
    //     });
    //   }
    //   if (f.關鍵字 != ""){
    //     let arr = [];
    //     let re = new RegExp('(' + f.關鍵字 + ')', "gi");
    //     for (let item of data){
    //       let isMatch = false;
    //       if (re.test(item.序號)){
    //         item._序號 = item.序號.replace(re, "<mark>$1</mark>");
    //         isMatch = true;
    //       }
    //       if (re.test(item.uid)){
    //         item._uid = item.uid.replace(re, "<mark>$1</mark>");
    //         isMatch = true;
    //       }
    //       if (re.test(item.暱稱)){
    //         item._暱稱 = item.暱稱.replace(re, "<mark>$1</mark>");
    //         isMatch = true;
    //       }
    //       if (isMatch) arr.push(item);
    //     }
    //     data = arr;
    //   }
    //   this.serialTotal = data.length;
    //   return data;
    // },
    // pagedSerialData: function(){
    //   let pageNum = this.searchSerialData.pageNum;
    //   let pageSize = this.searchSerialData.pageSize;
    //   return this.filteredSerialData.slice((pageNum - 1) * pageSize, (pageNum) * pageSize);
    // }
  },
  methods: {
    async onPageChange(pageIdx, pageSize) {
      this.searchData.pageNum = pageIdx;
      this.searchData.pageSize = pageSize;
    },
    getData(){
      // return;
      this.loading = true;
      this.$store.dispatch("xsw/序號兌換活動列表查詢", this.searchData).then((res) => {
          this.datas = res || [];
          this.total = this.datas.length || 0;
          this.loading = false;
          this.message = "";
          this.$refs.pagination.onPageChange(1);

        }, (err) => {
          this.message = err;
          this.datas = [];
          this.total =  0;
          this.loading = false;
        });
    },
    exportData() {
        let data = utils.deepCloneObj(this.datas);
        data.map(x => {x.兌換物品 = JSON.stringify(x.兌換物品); return x })
        utils.exportData(
          data,
          `${this.searchData.序號}_序號管理.xlsx`,
          '序號管理'
        );
    },
    uiDate(date){
      let d = new Date(date);
      if (d == "Invalid Date") return date;
      return d.toLocaleString('zh-TW', {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        hourCycle: "h23",
      });
    }
  }
};
</script>