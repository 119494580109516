@@ -1,292 +0,0 @@
<template>
  <div id="AnnouncementList">
    <section class="content-header">
      <h1>{{ $t('圖片管理') }}</h1>
      <ol class="breadcrumb">
        <li><router-link to="/">{{ $t('首頁') }}</router-link></li>
        <li v-if="id" class="active"><router-link :to="{params:{ id:null }}">{{ $t('圖片管理') }}</router-link></li>
        <li v-else class="active">{{ $t('圖片管理') }}</li>
        <li v-if="id" class="active">{{ id }}</li>
      </ol>
    </section>

    <section class="content">
      <div class="box">
        <div class="box-body">
          <div class="top-box">
            <router-link :to="{name:'XsgWebBannerEdit', params:{id:'New', searchData }}" class="btn btn-block btn-warning btn-add fa fa-plus mb-10">
              {{ $t('新增') }}
            </router-link>
            <div class="select-group">
              <select class="form-control" v-model="searchData.類別" @change="clickSearch()" required>
                <option v-for="(name, key) in bannerType" :key="key" :value="name">{{ $t(name) }}</option>
              </select>
            </div>
            <div class="select-group">
              <select class="form-control" v-model="searchData.判斷時間" @change="clickSearch()" required>
                <option :value="0">{{ $t('所有狀態') }}</option>
                <option :value="1">{{ $t('等待上線') }}</option>
                <option :value="2">{{ $t('進行中') }}</option>
                <option :value="3">{{ $t('已過期') }}</option>
              </select>
            </div>
            <div class="input-group pull-left mb-10 mr-10">
              <input type="text" v-model="searchData.標題" class="form-control" :placeholder="$t('請輸入標題')">
            </div>
            <div class="form-group pull-left m-0">
              <div class="form-group pull-left m-0">
                <label class="control-label pull-left mt-7">{{$t('查詢區間')}}：</label>
                <div class="input-group date pull-left mb-10 mr-10">
                  <div class="input-group-addon fa fa-calendar"></div>
                  <DateRangePicker
                    :propStartDate.sync="searchData.開始時間"
                    :propEndDate.sync="searchData.結束時間"
                    :propTimePicker="true"
                    displayFormat="YYYY/MM/DD"
                  />
                </div>
              </div>
              <div class="pull-left m-0">
                <button class="btn btn-primary pull-left mb-10" @click="clickSearch()">
                  {{$t('查詢')}}
                </button>
              </div>
              <div class="text data-num">
                <span>{{$t('資料總筆數')}}：</span>
                <span id="total">{{ total }}</span>
              </div>
            </div>
          </div>
          <div class="table-responsive mt-10 text-center">
            <table class="table table-hover table-bordered AdListTable">
              <tbody>
                <tr class="bg-gray">
                  <th style="min-width: 50px; width: 3%">No.</th>
                  <th style="min-width:120px; width: 5%">{{$t('圖檔')}}</th>
                  <th style="min-width:120px; width:10%">{{$t('標題')}}</th>
                  <th style="min-width:120px; width:15%">{{$t('網址')}}</th>
                  <th style="min-width: 75px; width: 5%">{{$t('狀態')}}</th>
                  <th style="min-width: 95px; width:10%">{{$t('開始時間')}}</th>
                  <th style="min-width: 95px; width:10%">{{$t('結束時間')}}</th>
                  <th style="min-width: 80px; width: 5%">{{$t('排序')}}</th>
                  <th style="min-width: 80px; width: 5%">{{$t('異動人員')}}</th>
                  <th style="min-width: 95px; width:10%">{{$t('異動時間')}}</th>
                  <th style="min-width: 80px; width: 5%">{{$t('功能')}}</th>
                </tr>
                <tr v-if="getDatas.length <= 0"><td class="text-center" colspan="10">{{$t('無資料')}}</td></tr>
                <tr v-for="(item, index) in getDatas" :key="item.id">
                  <td>{{ index+1 }}</td>
                  <td><div class="pic"><img :src="getImageUrl(item,'1')" draggable="false" alt="" /></div></td>
                  <td>{{ item.標題 }}</td>
                  <td><span class="text-clamp">{{ item.連結 }}</span></td>
                  <td>{{ GetStatus(item, false) }}</td>
                  <td>{{ uiDate(item.開始時間) }}</td>
                  <td>{{ uiDate(item.結束時間) }}</td>
                  <td>
                    <fieldset style="display:flex; flex-direction:column; gap:5px">
                      <input type="number" class="form-control p-5" v-model.number="item.排序" min="1" max="" style="padding-right:0; text-align:center;" />
                      <button type="button" class="btn btn-warning" @click="changeStatus(item)">
                        <i class="fa fa-fw fa-arrows-v"></i> {{$t('更新')}}
                      </button>
                    </fieldset>
                    <!-- <input type="number" class="form-control sort" v-model.number="item.排序" min="1" max="" />
                    <button type="button" class="btn btn-warning ml-5" @click="changeStatus(item)">更新</button> -->
                  </td>
                  <td>{{ item.異動人員 || item.建立者 }}</td>
                  <td>{{ /^0/.test(item.異動時間) ? "-" : uiDate(item.異動時間) }}</td>
                  <td>
                    <div style="display:flex; flex-direction:column; gap:5px">
                      <router-link class="btn btn-primary" :to="{name:'XsgWebBannerEdit', params:{id:item.id, searchData}}">
                        <i class="fa fa-fw fa-pencil"></i> {{$t('修改')}}
                      </router-link>
                      <button class="btn btn-danger" @click="deleteItem(item)">
                        <i class="fa fa-fw fa-close"></i> {{$t('刪除')}}
                      </button>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="box-footer text-center no-border" v-show="total > searchData.PageSize">
          <Pagination
            ref="pagination"
            :total="total"
            :pageLimitCount="searchData.PageSize"
            v-on:pageMethod="onPageChange"
          />
        </div>
      </div>
    </section>
  </div>
</template>

<style lang="scss" scoped>
  .text-clamp{
    display:-webkit-box;
    -webkit-box-orient:vertical;
    -webkit-line-clamp:4;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  input[type="checkbox"]{
    width: 1.5em;
    height: 1.5em;
    vertical-align: sub;
    margin-top: -.2em;
    accent-color: #3498db;
    cursor: pointer;
  }
  label.required::before{content: "* "; color: #dd4b39 }
  .form-control.sort{
    display: inline-block;
    width: 54px;
    padding: 5px;
    vertical-align: middle;
    border-radius: 3px;
    text-align: center;
  }
</style>

<script>
/* eslint-disable */
//import CKEditor from "@/components/CKEditor";
import api from "@/assets/js/app-api";
// import define from "@/views/projects/XSG/_Define";
import types from "@/views/projects/XSG/_Define";
import Pagination from "@/components/Pagination";
import DateAndTimePicker from "@/components/DateAndTimePicker";
import iCheckCheckBox from "@/components/iCheck_CheckBox";
import iCheckRedioButton from "@/components/iCheckRedioButton";
import utils from "@/assets/js/app-utils";
import config from "@/assets/js/app-config";
import { mapState, mapActions } from "vuex";
import moment from "moment";
import ImageSelect from "@/views/projects/XSG/components/ImageSelect";
import DateRangePicker from "@/components/DateRangePicker";


export default {
  components: {
    Pagination,
    DateAndTimePicker,
    iCheckCheckBox,
    iCheckRedioButton,
    ImageSelect,
    DateRangePicker
  },
  props: {
    id: "",
  },
  data() {
    return {
      types: types,
      bannerType: types.webBannerType,
      // def: define,
      total: 0, //總筆數
      // dfn: dfn,
      projectId: this.$store.state.projectId,
      searchData: {
        Id: "",
        類別: types.webBannerType[Object.keys(types.webBannerType)[0]],
        標題: "",
        判斷時間: 0,
        開始時間: new moment().subtract(1, "month").format("YYYY-MM-DD 00:00:00"),
        結束時間: new moment().format("YYYY-MM-DD 23:59:59"),
        PageNum: 1,
        PageSize: 10
      },
      coverMode: false, // 蓋板廣告(賀緹)
      hasIsOn: '',
      filterString: "",
      filterStatus: "",
      previewImg: "",
      imgMaxSize: 200 /* kb */,
      loading: false,
      message: "",
      datas: [],
      total: 0, //總筆數
    };
  },
  async created() {
    if (this.$route.params.searchData != undefined) {
      this.searchData.類別 =this.$route.params.searchData.類別
    }

    this.getData();
  },
  mounted() {
    window.v = this;
  },
  computed: {
    getImageUrl() {
      return (item, info) => {
        const logo1File = item.files.find((file) => file.info === info);
        return logo1File ? logo1File.filesUrl : "";
      };
    },

    getDatas() {
      if(this.filterStatus == "") return this.datas;
      let newData = this.datas.filter(item => this.GetStatus(item) == this.filterStatus);

      return newData;
    }
  },
  watch: {
  },
  methods: {
    async onPageChange(pageIdx, pageSize) {
      this.searchData.PageNum = pageIdx;
      this.searchData.PageSize = pageSize;
      this.getData();
    },
    getData(){
      this.loading = true;
      this.$store.dispatch("xsw/官網Banner列表查詢", this.searchData).then(res => {
        this.message = "";
        this.datas = res.datas || [];
        this.total = res.total || 0;
        this.loading = false;

        if (this.$refs.pagination) this.$refs.pagination.setPage(this.searchData.PageNum, this.total);
      }, err => {
        this.message = err;
        this.datas =  [];
        this.total = 0;
        this.loading = false;
      });
    },

    changeStatus(item){
      let err = [];

      // this.$eventBus.$emit("showAlert", "資料送已送出處理中，請稍後回到列表");

      // let api = this.id == 'New' ? "新增" : "修改";

      let sendData = Object.assign({}, item);
      let sendDataEdit =  {
        Id: sendData.id,
        類別: sendData.類別,
        標題: sendData.標題,
        內容: sendData.內容,
        是否顯示標題: sendData.是否顯示標題,
        連結: sendData.連結,
        排序: sendData.排序,
        是否另開視窗: sendData.是否另開視窗,
        開始時間: this.uiDate2(item.開始時間),
        結束時間: this.uiDate2(item.結束時間),
        是否常駐顯示: sendData.是否常駐顯示,
        備註: sendData.備註,
      }

      // delete sendData.files;

      this.$store.dispatch("xsw/官網Banner修改", sendDataEdit).then(() => {
        this.$eventBus.$emit("showAlert", this.$t("修改成功"));
        this.getData();
      });
    },
    uiDate2(date){
      const originalString = date;
      return originalString.replace(/\//g, '-');
    },

    clickSearch() {
      this.onPageChange(1, this.searchData.PageSize)
    },

    //*---- 刪除
    deleteItem(item) {
      this.$eventBus.$emit("showConfirm", this.$t("確定要刪除嗎?"), (isOK) => {
        if (isOK) {
          this.$store.dispatch("xsw/官網Banner刪除", {Id: item.id}).then(res => {
            this.getData();
          });
        }
      });
    },
    GetStatus(item) {
      if (!item.開始時間 || !item.結束時間) return this.$t("無設時間");
      if (new moment().isBefore(new moment(item.開始時間))) return this.$t("等待上線");
      if (new moment().isAfter(new moment(item.結束時間))) return this.$t("已過期");
      return this.$t("進行中");
    },
    uiDate(date){
      let d = new Date(date);
      if (date && d) return d.toLocaleString('zh-TW', {
        year:"numeric",
        month:"2-digit",
        day:"2-digit",
        hour:"2-digit",
        minute:"2-digit",
        second:"2-digit",
        hourCycle:"h23",
      });
      else return '';
    },
  },
};
</script>