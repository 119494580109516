<template>
  <div id="MemberData">
    <section class="content-header">
      <h1>{{$t('公會明細查詢')}}</h1>
      <ol class="breadcrumb">
        <li><router-link to="/">{{$t('首頁')}}</router-link></li>
        <li class="active">{{$t('公會明細查詢')}}</li>
      </ol>
    </section>
    <section class="content" style="min-height:auto;">
      <div class="nav-tabs-custom tab-warning">
        <ul class="nav nav-tabs">
          <li :class="{ active: tab == 1 }">
            <a @click="setTab(1)" aria-expanded="true" data-toggle="tab" style="cursor:pointer;">{{$t('公會列表')}}</a>
          </li>
          <li :class="{ active: tab == 2 }" v-if="can歷程">
            <a @click="setTab(2)" aria-expanded="true" data-toggle="tab" style="cursor:pointer;">{{$t('公會解散歷程')}}</a>
          </li>
        </ul>
        <div class="tab-content" v-if="tab == 1">
          <form class="top-box clearfix" @submit.prevent="getDataList()">
            <div class="form-group m-0">
              <!-- <label class="control-label pull-left mt-7">公會名稱：</label> -->
              <div class="input-group pull-left mb-10 mr-10">
                <input type="text" v-model="filterString" class="form-control" :placeholder="$t('搜尋公會')" >
              </div>
            </div>
            <div class="pull-left mb-10 mr-10">
              <!-- <button type="submit" class="btn btn-primary mr-10">
                查詢
              </button> -->
              <button type="button" class="btn btn-default" @click="exportGuildData()" :disabled="datas.length === 0">
                {{$t('匯出')}}
              </button>
            </div>
            <button type="button" @click="$refs.addDialog.show()" class="btn btn-warning pull-right mb-10 ml-10">
              <i class="fa fa-plus"></i> {{$t('創建公會')}}
            </button>
          </form>
          <div class="table-responsive">
            <table class="table table-bordered text-center">
              <thead>
                <tr class="bg-gray">
                  <th style="min-width:120px; width: 5%">{{$t('公會ID')}}</th>
                  <th style="min-width:120px; width: 5%">{{$t('公會名稱')}}</th>
                  <th style="min-width:120px; width: 5%">{{$t('會長')}}</th>
                  <th style="min-width:100px; width: 5%">{{$t('線上/人數')}}</th>
                  <th style="min-width:100px; width: 5%">{{$t('戰力')}}</th>
                  <th style="min-width:100px; width:10%">{{$t('建立時間')}}</th>
                  <th style="min-width: 75px; width: 5%">{{$t('功能')}}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-if="loading"><td colspan="11">{{$t('查詢中')}}</td></tr>
                <tr v-else-if="message"><td colspan="11">{{ $t(message) }}</td></tr>
                <tr v-else-if="datas.length === 0"><td colspan="11">{{$t('查無資料')}}</td></tr>
                <tr v-else-if="datas.length > 0 && filterData.length == 0"><td colspan="11">{{ $t('資料中搜尋「X」無結果', [filterString]) }}</td></tr>
                <tr v-else v-for="(item, index) in pagedData" :key="item.id">
                  <td>{{ item.id }}</td>
                  <td v-html="item.名稱match || item.名稱"></td>
                  <td>
                    <span v-if="projectId == 'omp_xin_my'" v-html="item.會長match || item.會長"></span>
                    <router-link v-else :to="{name:'XswMemberData', query:{nick:item.會長}, params:{back:$route}}" v-html="item.會長match || item.會長"></router-link>
                  </td>
                  <td>
                    <span v-if="!can成員">{{ item.上線數 }} / {{ item.會員數 }}</span>
                    <button v-else class="btn btn-default" @click="showMembers(item.id)">{{ item.上線數 }} / {{ item.會員數 }}</button>
                  </td>
                  <td>{{ item.積分 }}</td>
                  <td>{{ uiDate(new Date(item.id).toISOString().replace('Z','')) }}</td>
                  <td>
                    <button type="button" @click="editRemove = Object.assign({解散原因:''},item); $refs.removeDialog.show();"
                      class="btn btn-danger">{{$t('解散公會')}}</button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="text-center mt-10">
            <Pagination
              :pageLimitCount="pageSize"
              :total="total"
              ref="pagination"
              v-on:pageMethod="onPageChange"
            />
          </div>
        </div>
        <div class="tab-content" v-if="tab == 2 && can歷程">
          <form class="top-box clearfix" @submit.prevent="pastSearchData.pageNum = 1; getPastList()">
            <div class="form-group m-0">
              <!-- <label class="control-label pull-left mt-7">公會名稱：</label> -->
              <div class="input-group pull-left mb-10 mr-10">
                <input type="text" v-model="filterPastString" class="form-control" :placeholder="$t('搜尋公會')" >
              </div>
            </div>
            <!-- <div class="form-group m-0">
              <label class="control-label pull-left mt-7">公會名稱：</label>
              <div class="input-group pull-left mb-10 mr-10">
                <input type="text" v-model="pastSearchData.公會名稱" class="form-control" placeholder="公會名稱" >
              </div>
            </div>
            <div class="form-group m-0">
              <label class="control-label pull-left mt-7">公會長：</label>
              <div class="input-group pull-left mb-10 mr-10">
                <input type="text" v-model="pastSearchData.會長" class="form-control" placeholder="公會長" >
              </div>
            </div> -->
            <div class="pull-left mr-10">
              <!-- <button type="submit" class="btn btn-primary pull-left mb-10">查詢</button> -->
              <button type="button" class="btn btn-default pull-left mb-10" @click="exportGuildPast()" :disabled="pastList.length === 0">
                {{$t('匯出')}}
              </button>
            </div>
          </form>
          <div class="table-responsive">
            <table class="table table-bordered text-center">
              <thead>
                <tr class="bg-gray">
                  <th style="min-width:120px; width: 5%">{{$t('公會ID')}}</th>
                  <th style="min-width:120px; width: 5%">{{$t('公會名稱')}}</th>
                  <th style="min-width:120px; width: 5%">{{$t('會長')}}</th>
                  <th style="min-width:180px; width:10%">{{$t('解散原因')}}</th>
                  <th style="min-width:120px; width: 5%">{{$t('解散時間')}}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-if="pastLoading"><td colspan="11">{{$t('查詢中')}}</td></tr>
                <tr v-else-if="pastMessage"><td colspan="11">{{ $t(pastMessage) }}</td></tr>
                <tr v-else-if="pastList.length === 0"><td colspan="11">{{$t('查無資料')}}</td></tr>
                <tr v-else-if="pastList.length > 0 && filterPastData.length == 0"><td colspan="11">{{ $t('資料中搜尋「X」無結果', [filterPastString]) }}</td></tr>
                <tr v-else v-for="(item, index) in pagedPastData" :key="item.公會ID">
                  <td>{{ item.公會ID }}</td>
                  <td v-html="item.公會名稱match || item.公會名稱"></td>
                  <td>
                    <span v-if="projectId == 'omp_xin_my'" v-html="item.會長match || item.會長"></span>
                    <router-link v-else :to="{name:'XswMemberData', query:{nick:item.會長}, params:{back:$route}}" v-html="item.會長match || item.會長"></router-link>
                  </td>
                  <td>{{ item.解散原因 }}</td>
                  <td>{{ uiDate(item.解散時間) }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="text-center mt-10">
            <Pagination :pageLimitCount="pastPageSize" :total="pastTotal" ref="pagination"
              v-on:pageMethod="onPageChange" />
          </div>
        </div>
      </div>
    </section>


    <!-- <pre>{{ datas }}</pre> -->
    <!-- <pre>{{ pastList }}</pre> -->
    <!-- <pre>{{ memberList }}</pre> -->
    <!-- <pre>{{ serviceList }}</pre> -->
    <!-- <pre>{{ banList }}</pre> -->
     
    <FormDialog ref="statusDialog" :title="$t('公會成員')">
      <div>
        <table class="table table-bordered table-condensed">
          <thead>
            <tr class="bg-gray-light">
              <th>{{$t('狀態')}}</th>
              <th>{{$t('UID')}}</th>
            </tr>
          </thead>
          <tbody class="text-center">
            <tr v-for="item in memberList">
              <td :class="{'text-red':item[1]=='0','text-green':item[1]=='1'}">{{ $t( item[1] == "1" ? '在線' : '離線' ) }}</td>
              <td>{{ item[0] }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <template slot="footer">
        <button class="btn btn-primary" style="min-width:8em" data-dismiss="modal">{{ $t('確定') }}</button>
      </template>
    </FormDialog>
    <FormDialog ref="addDialog" :title="$t('創建公會')" @submit="addGuild" @hide="edit = {會長:''}">
      <table class="table table-bordered">
        <tbody>
          <tr>
            <th v-if="projectId == 'omp_xin_my'">{{ $t('玩家UID') }}</th>
            <th v-else>{{$t('玩家暱稱')}}</th>
            <td><input class="form-control" type="text" v-model="edit.會長" :placeholder="projectId == 'omp_xin_my' ? $t('輸入玩家UID') : $t('輸入玩家暱稱')" required></td>
          </tr>
        </tbody>
      </table>
    </FormDialog>
    <FormDialog ref="removeDialog" :title="$t('解散公會')" @submit="removeGuild" @hide="editRemove = {}">
      <table class="table table-bordered">
        <tbody>
          <tr>
            <th>{{$t('會長')}}</th>
            <td>{{ editRemove.會長 }}</td>
          </tr>
          <tr>
            <th>{{$t('公會名稱')}}</th>
            <td>{{ editRemove.名稱 }}</td>
          </tr>
          <tr>
            <th>{{$t('解散原因')}}</th>
            <td><textarea class="form-control" type="text" v-model="editRemove.解散原因" :placeholder="$t('原因')" rows="3" required></textarea></td>
          </tr>
        </tbody>
      </table>
    </FormDialog>
  </div>
</template>

<style lang="scss" scoped>
  table.table>tbody>tr {
    word-break: break-word;
  }
  table.table>thead>tr>th {
    word-break: keep-all;
  }
</style>
<style scoped>
  .table>>>mark{
    padding: 0;
    background: #ff8;
  }
</style>

<script>
/* eslint-disable */
import dfn from "@/views/projects/XSW/_Define";
import moment from "moment";
import DateRangePicker from "@/components/DateRangePicker";
import FormDialog from "./components/FormDialog.vue";
import Pagination from "@/components/Pagination";
import router from "../../../router";
import utils from "@/assets/js/app-utils";

export default {
  components: {
    DateRangePicker,
    FormDialog,
    Pagination,
    router
  },
  data() {
    return {
      tab: 1,
      projectId: this.$store.state.projectId,

      filterString: "",
      searchData: {},
      loading: false,
      message: "",
      total: 0, //總筆數
      datas: [],
      pageNum: 1,
      pageSize: 20,

      // pastList
      filterPastString: "",
      pastSearchData: {
        // 會長: "",
        // 公會名稱: "",
        開始時間: new moment(new Date("2024-01-01")).format("YYYY-MM-DD 00:00:00"),
        結束時間: new moment().format("YYYY-MM-DD 23:59:59"),
        // pageNum: 1,
        // pageSize: 10, //每頁顯示筆數
      },
      pastMessage: "", //錯誤訊息
      pastLoading: false,
      pastTotal: 0, //總筆數
      pastList: [],
      pastPageNum: 1,
      pastPageSize: 20,

      edit: {會長:""},
      editRemove: {},
      serviceTypes: {},

      memberList: [],
      memberMessage: "",
      memberTotal: 0,
      memberPageNum: 1,
      memberPageSize: 20,

      // 權限
      can建立: this.$user.hasPermission(dfn.permission.公會建立),
      can解散: this.$user.hasPermission(dfn.permission.公會解散),
      can歷程: this.$user.hasPermission(dfn.permission.公會解散歷程查詢),
      can成員: this.$user.hasPermission(dfn.permission.公會成員列表查詢),
    };
  },
  async created() {
    if (this.$route.query.tab){
      this.tab = this.$route.query.tab
    }
    this.getDataList();
    this.getPastList();
    // this.getTypes();
    // window.v = this
  },
  watch: {

  },
  computed:{
    filterData: function() {
      let data = this.datas;
      if (this.filterString != ""){
        let re = new RegExp(`(${this.filterString.trim()})` , "ig");
        let arr = [];
        for (let item of data){
          item = Object.assign({}, item);
          let isMatch = false;
          if (re.test(item.名稱)){ isMatch = true; item.名稱match = item.名稱.replace(re, "<mark>$1</mark>");}
          if (re.test(item.會長)){ isMatch = true; item.會長match = item.會長.replace(re, "<mark>$1</mark>");}
          if (isMatch) arr.push(item);
        }
        data = arr;
      }
      this.pageNum = 1;
      this.total = data.length;
      return data;
    },
    pagedData: function () {
      return this.filterData.slice((this.pageNum - 1) * this.pageSize, (this.pageNum) * this.pageSize);
    },
    filterPastData: function() {
      let data = this.pastList;
      if (this.filterPastString != ""){
        let re = new RegExp(`(${this.filterPastString.trim()})` , "ig");
        let arr = [];
        for (let item of data){
          item = Object.assign({}, item);
          let isMatch = false;
          if (re.test(item.公會名稱)){ isMatch = true; item.公會名稱match = item.公會名稱.replace(re, "<mark>$1</mark>");}
          if (re.test(item.會長)){ isMatch = true; item.會長match = item.會長.replace(re, "<mark>$1</mark>");}
          if (isMatch) arr.push(item);
        }
        data = arr;
      }
      this.pastPageNum = 1;
      this.pastTotal = data.length;
      return data;
    },
    pagedPastData: function () {
      return this.filterPastData.slice((this.pastPageNum - 1) * this.pastPageSize, (this.pastPageNum) * this.pastPageSize);
    },
    pagedMembers: function(){
      return this.memberList.slice((this.memberPageNum - 1) * this.memberPageSize, (this.memberPageNum) * this.memberPageSize);
    }
  },
  methods: {
    setTab(num){
      this.tab = num;
      let query = Object.assign({}, this.$route.query, {tab:num});
      if (JSON.stringify(query) != JSON.stringify(this.$route.query)){
        this.$router.replace({query:query});
      }
    },
    addGuild(){
      this.$store.dispatch("xsw/公會建立", {
        暱稱: this.edit.會長,
        公會名稱: this.edit.會長 + "的公會",
      }).then(res => {
        this.$eventBus.$emit("showAlert", `「${this.edit.會長}的公會」${this.$t('創建成功')}`);
        this.$refs.addDialog.hide();
        this.getDataList();
      }, err => {
        this.$eventBus.$emit("showAlert", err);
      });
    },
    removeGuild(){
      this.$store.dispatch("xsw/公會解散", {
        公會ID: this.editRemove.id,
        會長: this.editRemove.會長,
        公會名稱: this.editRemove.名稱,
        解散原因: this.editRemove.解散原因,
      }).then(res => {
        this.$eventBus.$emit("showAlert", `「${this.editRemove.會長}」${this.$t('解散成功')}`);
        this.$refs.removeDialog.hide();
        this.getDataList();
        this.getPastList();
      }, err => {
        this.$eventBus.$emit("showAlert", err);
      });
    },
    showMembers(guildID){
      this.$store.dispatch("xsw/公會成員列表查詢", {公會ID: String(guildID) })
      .then(res => {
        this.memberMessage = "";
        this.memberList = Object.entries(res) || [];
        this.memberList.sort((a, b) => ((b[1] == "1")-(a[1] == "1")));
        this.memberTotal = this.memberList.length;
        this.$refs.statusDialog.show();
      }, err => {
        this.$eventBus.$emit("showAlert", err);
        this.memberMessage = err;
        this.memberList = [];
        this.memberTotal = 0;
      });
    },
    async onPageChange(pageIdx, pageSize) {
      if (this.tab == 2) {
        this.pastPageNum = pageIdx;
        this.pastPageSize = pageSize;
        // this.getPastInfo();
      } else {
        this.pageNum = pageIdx;
        this.pageSize = pageSize;
        // this.getServiceInfo();
      }
    },
    // getTypes() {
    //   this.$store.dispatch("xsw/服務類別查詢").then((result) => {
    //     for (let item of result) {
    //       if (item.啟用YN == 0) { // 0 == 啟用
    //         this.$set(this.serviceTypes, item.編號, item.名稱);
    //         // console.log(item);
    //       }
    //     }
    //   });
    // },
    getDataList() {
      this.loading = true;
      this.$store.dispatch("xsw/公會查詢")
      .then(res => {
        let data = res.datas || [];
        data = data.sort((a, b) => b.id - a.id);
        data = data.map(item => {
          if (Array.isArray(item.積分)) item.積分 = item.積分.reduce((a,b) => a+b, 0);
          return item;
        });
        this.message = "";
        this.datas = data;
        this.total = res.total;
        this.loading = false;
      }, err => {
        this.message = err;
        this.datas = [];
        this.total = 0;
        this.loading = false;
      });
    },
    getPastList(){
      this.pastLoading = true;
      this.$store.dispatch("xsw/公會解散歷程查詢", this.pastSearchData)
      .then(res => {
        this.pastMessage = "";
        this.pastList = res.datas || [];
        this.pastTotal = res.total;
        this.pastLoading = false;
      }, err => {
        this.pastMessage = err;
        this.pastList = [];
        this.pastTotal = 0;
        this.pastLoading = false;
      });
    },
    exportGuildData() {
        let data = utils.deepCloneObj(this.datas);
        let items = [];
        data.forEach(item => {
          // console.log(item);
            items.push({
                '公會ID': item.id.toString(),
                '公會名稱': item.名稱,
                '會長': item.會長,
                '會員數': item.會員數,
                '戰力(積分)': item.積分,
                '建立時間': this.uiDate(new Date(item.id).toISOString().replace('Z','')),
            });
        });
        utils.exportData(
            items,
            `${utils.formatTime(new Date(), 'YYYYMMDD')}_公會列表.xlsx`,
            '公會列表'
        );
    },
    exportGuildPast() {
      let data = utils.deepCloneObj(this.pastList);
      let items = [];
      data.forEach(item => {
        // console.log(item);
          items.push({
            '公會ID': item.公會ID.toString(),
            '公會名稱': item.公會名稱,
            '會長': item.會長,
            '解散原因': item.解散原因,
            '解散時間': this.uiDate(item.解散時間),
          });
      });
      utils.exportData(
          items,
          `${utils.formatTime(new Date(), 'YYYYMMDD')}_公會解散歷程.xlsx`,
          '公會解散歷程'
      );
    },
    uiDate(date) {
      let d = new Date(date);
      if (d != "Invalid Date") return d.toLocaleString('zh-TW', {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        hourCycle: "h23",
      });
      else return date;
    }
  },
};
</script>