<template>
  <div id="AnnouncementList">
    <section class="content-header">
      <h1>遊戲介紹列表</h1>
      <ol class="breadcrumb">
        <li><router-link to="/">Home</router-link></li>
        <li class="active">遊戲介紹管理</li>
      </ol>
    </section>

    <section class="content">
      <div class="box box-primary">
        <div class="box-body">
          <form class="top-box" @submit.prevent="clickSearch()">
            <router-link class="btn btn-block btn-warning btn-add mb-10" :to="{name:'AWH5GameIntroEdit', params:{id: 'New'}}">
              <i class="fa fa-plus"></i> 新增遊戲
            </router-link>
            <button type="button" class="btn btn-block btn-default btn-add mb-10 mr-10" @click="clearCatch()">
              <i class="fa fa-refresh"></i> 清除Cache
            </button>

            <!-- 分類下拉 -->
            <div class="select-group mb-10">
              <select class="form-control" v-model="searchData.遊戲分類" @change="clickSearch()">
                <option value>全部遊戲分類</option>
                <option v-for="(item, index) in classTypes" :value="item.classTypeId" :key="index">{{ item.className }}</option>
              </select> 
            </div>

            <div class="select-group mb-10">
              <select class="form-control" v-model="searchData.是否發佈" @change="clickSearch()">
                <option value=null>上/下架</option>
                <option value=true>上架</option>
                <option value=false>下架</option>
              </select> 
            </div>
            
            <div class="select-group mb-10">
              <select class="form-control" v-model="searchData.語系" @change="getDataList()">
                <option v-for="lang in langs" :value="lang.id">{{ lang.id + ' ' + lang.name }}</option>
              </select> 
            </div>

            <!-- 搜尋 -->
            <div class="input-group mb-10">
              <input type="text" class="form-control pull-right" v-model="searchData.遊戲名稱" placeholder="搜尋遊戲名稱" />
              <div class="input-group-btn">
                <button type="submit" class="btn btn-default">
                  <i class="fa fa-search"></i>
                </button>
              </div>
            </div>

            <div class="text mb-10">
              <p class="data-num">資料總筆數: <span>{{ total }}</span></p>
            </div>
          </form>

          <div class="table-responsive">
            <table class="table table-hover table-bordered text-center">
              <tbody>
                <tr class="bg-gray">
                  <th style="min-width: 80px; width: 5%">遊戲編號</th>
                  <th style="min-width: 80px; width:10%">分類</th>
                  <th style="min-width:100px; width:15%">遊戲Logo</th>
                  <th style="min-width:100px; width:15%">遊戲名稱</th>
                  <th style="min-width: 50px; width: 5%">狀態</th>
                  <th style="min-width: 80px; width: 3%">排序</th>
                  <th style="min-width: 80px; width: 3%">功能</th>
                </tr>
                <tr v-if="message"><td colspan="10">{{ message }}</td></tr>
                <tr v-else-if="total <= 0"><td colspan="10">查詢無任何資料</td></tr>
                <tr v-else v-for="(item, index) in datas" :key="item.gid">
                  <td>{{ item.遊戲館 }}</td>
                  <td>{{ getClassTypeName(item) }} </td>
                  <td><img :src="getLogoUrl(item)" alt="" style="max-height:100px;" /></td>
                  <td>{{ getName(item) }}</td>
                  <td>
                    <fieldset class="ckbx-style-8 ckbx toggleInput">
                      <input type="checkbox" :true-value="true" :false-value="false" :id="'check'+index" v-model="item.是否發佈" @change="toggle(item)" />
                      <label :for="'check'+index"></label>
                    </fieldset>
                  </td>
                  <td class="text-nowrap">
                    <div style="display:flex; flex-direction:column; gap:5px">
                      <input type="number" class="form-control p-5" v-model.number="item.排序" min="1" max="" :disabled="!item.是否發佈" style="padding-right:0; text-align:center;" />
                      <button type="button" class="btn btn-warning btn-add btn-upDate" @click="updateOrder(item)" :disabled="!item.是否發佈">
                        <i class="fa fa-arrows-v"></i> 更新
                      </button>
                    </div>
                  </td>

                  <td>
                    <div style="display:flex; flex-direction:column; gap:5px">
                      <router-link class="btn btn-primary" :to="{name:'AWH5GameIntroEdit',params:{id: item.gid}}">
                        <i class="fa fa-fw fa-pencil"></i> 修改
                      </router-link>
                      <button class="btn btn-warning" @click="copyItem(item.gid)">
                        <i class="fa fa-fw fa-copy"></i> 複製
                      </button>
                      <button class="btn btn-danger" @click="deleteItem(item)">
                        <i class="fa fa-fw fa-close"></i> 刪除
                      </button>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div v-show="total" class="box-footer text-center no-border">
          <Pagination
            ref="pagination"
            :total="total "
            :pageLimitCount="searchData.pageSize"
            v-on:pageMethod="onPageChange"
            :type2="true"
          />
        </div>
      </div>
    </section>

    <!-- <pre>
      {{ classTypes }}
      {{ datas }}
    </pre> -->
  </div>
</template>

<script>
/* eslint-disable */
import api from "@/assets/js/app-api";
import dfn from "@/views/projects/XSW/_Define";
import types from "@/views/projects/AWH5/_Define";
import Pagination from "@/components/Pagination";
export default {
  components: {
    Pagination,
  },
  props: {
    id: "",
  },
  data() {
    return {
      total: 0, //總筆數
      langs: types.langs,
      projectId: this.$store.state.projectId,
    
      searchData: {
        是否發佈: null,  //-1:all, 1:上架, 0:下架
        遊戲分類: "", 
        遊戲名稱: "",
        語系: types.langs[0].id,
        pageNum: 1,
        pageSize: 10, //每頁顯示筆數
      },

      datas: [],
      message: "",
      
      classTypes: [],
      
      can列表: this.$user.hasPermission(dfn.permission.遊戲簡介列表查詢),
      can查詢: this.$user.hasPermission(dfn.permission.遊戲簡介查詢),
      can新增: this.$user.hasPermission(dfn.permission.遊戲簡介新增),
      can修改: this.$user.hasPermission(dfn.permission.遊戲簡介修改),
      can刪除: this.$user.hasPermission(dfn.permission.遊戲簡介刪除),
      can複製: this.$user.hasPermission(dfn.permission.遊戲簡介複製),
      can清暫存: this.$user.hasPermission(dfn.permission.遊戲簡介清暫存),
    };
  },
  async created() {
    this.getClassTypes();
    this.searchData = this.$route.params.searchData || this.searchData;
  },
  mounted() {
    this.getDataList();
  },
  computed: {
    getLogoUrl() {
      return (item) => {
        const logo1File = item.files.find((file) => file.info === "logo");
        return logo1File ? logo1File.filesUrl : "";
      };
    },

    getName() {
      return (item) => {
        let lang = item.languageList.find(lang => lang.language_type === "en-US");
        if (!lang) lang = item.languageList[0];
        return lang ? lang.name : "";
      }
    },

    getClassTypeName() {
      return (item) => {
        const type = this.classTypes.find(type => type.classTypeId == item.遊戲分類);
        return type ? type.className : '未分類';
      };
    },
  },

  methods: {
    getClassTypes(){
      this.$store.dispatch("xsw/分類列表明細查詢", {module:"game", isDelete:0}).then(res => {
        this.classTypes = Array.isArray(res) ? res.map(x => Array.isArray(x.detail_model) ? x.detail_model.find(y => y.lang == "zh-TW") : {}) : [];
      });
    },
    getDataList() {
      //列表
      this.$store.dispatch("xsw/遊戲介紹列表查詢", this.searchData).then((result) => {
        if (result) {
          this.datas = result.datas || [];
          if (this.datas.length > 0) this.datas.forEach(item => item.files.sort((a, b) => b.id - a.id));
          this.total = result.total;
          this.message = "";
          this.$refs.pagination.setPage(this.searchData.pageNum, this.total);
        }
      }, (err) => {
          this.datas = [];
          this.total = 0;
          this.message = err;
      });
    },

    async onPageChange(pageIdx, pageSize) {
      this.searchData.pageNum = pageIdx;
      this.searchData.pageSize = pageSize;
      this.getDataList();
    },

    clickSearch() {
      if (typeof this.searchData.是否發佈 === "string"){
        this.searchData.是否發佈 = this.searchData.是否發佈 == 'null' ? null : (this.searchData.是否發佈 == 'true' ? true : false);
      }
      this.searchData.pageNum = 1;

      this.getDataList();
    },

    toggle(item) {
      const action = item.是否發佈 ? "上架" : "下架";
      this.$eventBus.$emit("showConfirm", `確定要${action}嗎?`, (isOK) => {
        if (isOK) {
          this.$store.dispatch("xsw/遊戲介紹修改", item).then((result) => {
            this.$eventBus.$emit("showAlert", `${action}更新成功！`);
            this.getDataList();
          }, err => {
            this.$eventBus.$emit("showAlert", err);
          });
        } else {
          item.是否發佈 = item.是否發佈 ? false : true;
        }
      });
    },

    updateOrder(item) {
      this.$store.dispatch("xsw/遊戲介紹修改", item).then((result) => {
        this.$eventBus.$emit("showAlert", "排序更新成功！");
        this.getDataList();
      }, err => {
        this.$eventBus.$emit("showAlert", err);
      });
    },

    //*---- 復製
    copyItem(id) {
      this.$eventBus.$emit("showConfirm", "確定要複製嗎?", (isOK) => {
        if (isOK) {
          this.$store.dispatch("xsw/遊戲介紹複製", { gid: id }).then((result) => {
            this.$eventBus.$emit("showAlert", "複製成功！");
            this.getDataList();
          }, err => {
            this.$eventBus.$emit("showAlert", err);
          });
        }
      });
    },

    //*---- 刪除
    deleteItem(item) {
      this.$eventBus.$emit("showConfirm", "確定要刪除嗎?", (isOK) => {
        if (isOK) {
          this.$store.dispatch("xsw/遊戲介紹刪除", item).then((result) => {
            this.$eventBus.$emit("showAlert", "刪除成功！");
            this.getDataList();
          }, err => {
            this.$eventBus.$emit("showAlert", err);
          });
        }
      });
    },

    // clear 
    clearCatch() {
      this.$store.dispatch("xsw/遊戲介紹清暫存").then((result) => {
        console.log("已清除Catch");
        this.$eventBus.$emit("showAlert", "已清除Catch");
      }, err => {
        this.$eventBus.$emit("showAlert", err);
      });
    },
  },
};
</script>
