// import Vue from 'vue' 沒使用到

class EventBus{
    constructor(){
        this.isInstalled = false;
    }
    install(Vue, options){
        if( this.isInstalled ) return;
        this.isInstalled = true;
        Vue.prototype.$eventBus = new Vue();
    }
}

export default new EventBus();