<template>
  <div>
    <form class="top-box" @submit.prevent="clickSearch()">
      <router-link :to="{name:'AWH5WebNewsEdit',params:{id:'New'}}" class="btn btn-block btn-warning btn-add mb-10">
        <i class="fa fa-plus"></i> 新增內容
      </router-link>

      <!-- 分類下拉 -->
      <div class="select-group mb-10">
        <select class="form-control" v-model="searchData.分類" @change="clickSearch()">
          <option value>全部分類</option>
          <option v-for="(item, index) in classTypes" :value="item.classTypeId" :key="index">{{ item.className }}</option>
        </select> 
      </div>

      <!-- <div class="select-group mb-10">
        <select class="form-control" v-model="searchData.是否發佈" @change="clickSearch()">
          <option value=null>上/下架</option>
          <option value=true>上架</option>
          <option value=false>下架</option>
        </select> 
      </div> -->
      
      <div class="select-group mb-10">
        <select class="form-control" v-model="searchData.語系">
          <option v-for="lang in langs" :value="lang.id">{{ lang.name }}</option>
        </select> 
      </div>

      <!-- 搜尋 -->
      <div class="input-group mb-10">
        <input type="text" class="form-control pull-right" v-model="searchData.消息標題" placeholder="搜尋消息標題" />
        <div class="input-group-btn">
          <button type="submit" class="btn btn-default">
            <i class="fa fa-search"></i>
          </button>
        </div>
      </div>

      <div class="text mb-10">
        <p class="data-num">資料總筆數: <span>{{ total }}</span></p>
      </div>
    </form>

    <div class="table-responsive">
      <table class="table table-hover table-bordered text-center">
        <tbody>
          <tr class="bg-gray">
            <th style="min-width:100px; width: 5%">消息分類</th>
            <th style="min-width:120px; width:10%">消息封面</th>
            <th style="min-width: 50px; width: 1%">置頂</th>
            <th style="min-width:150px; width:10%">消息名稱</th>
            <th style="min-width:100px; width:15%">建立日期</th>
            <th style="min-width:100px; width:15%">更新日期</th>
            <th style="min-width:100px; width:10%">操作者</th>
            <th style="min-width:150px; width: 5%">動作</th>
          </tr>
          <tr v-if="message"><td colspan="10">{{ message }}</td></tr>
          <tr v-else-if="total <= 0"><td colspan="10">查詢無任何資料</td></tr>
          <tr v-else v-for="(item, index) in datas" :key="item.gid" :class="{'bg-gray-light': isEditing && !item.isEdit }">
            <td>{{ getClassTypeName(item) }}</td>
            <td><img :src="getImage(item,'desktop')" alt="" style="max-height:50px;" ></td>
            <td><i class="fa fa-thumb-tack" v-show="item.是否置頂"></i></td>
            <td>{{ getProp(item, 'newsTitle') }}</td>
            <td>{{ item.createTime }}</td>
            <td>{{ /^0001/.test(item.updateTime) ? '-' : item.updateTime }}</td>
            <td>{{ item.updateUName || item.createUName }}</td>
            <td>
              <fieldset style="display:grid; grid-template-columns:1fr 1fr; gap:5px; justify-content:center" :disabled="isEditing && !item.isEdit">
                <router-link v-show="!item.isEdit" :to="{name:'AWH5WebNewsEdit',params:{id:item.newsId}}" class="btn btn-primary">
                  <i class="fa fa-fw fa-pencil"></i>修改
                </router-link>
                <!-- <button v-show="item.isEdit" type="submit" form="editForm" class="btn btn-success">
                  <i class="fa fa-fw fa-save"></i>儲存
                </button> -->
                <button v-show="!item.isEdit" @click="copyItem(item, index)" class="btn btn-warning">
                  <i class="fa fa-fw fa-copy"></i>複製
                </button>
                <button v-show="!item.isEdit" @click="setTop(item, index)" class="btn btn-success">
                  <i class="fa fa-fw fa-arrow-up"></i>置頂
                </button>
                <button v-show="!item.isEdit" @click="deleteItem(item, index)" class="btn btn-danger">
                  <i class="fa fa-fw fa-close"></i>刪除
                </button>
                <!-- <button v-show="item.isEdit" @click="cancel(item, index)" class="btn btn-default">
                  <i class="fa fa-fw fa-reply"></i>取消
                </button> -->
              </fieldset>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div v-show="total > searchData.PageSize" class="box-footer text-center no-border" :style="isEditing ? 'pointer-events:none; opacity:.6':''">
      <Pagination
        ref="pagination"
        :total="total"
        :pageLimitCount="searchData.PageSize"
        v-on:pageMethod="onPageChange"
        :type2="true"
      />
    </div>

    <!-- <pre>
      {{ classTypes }}
      {{ datas }}
     </pre> -->
  </div>
</template>

<style scoped>
.form-horizontal{
  display:grid;
  gap: 0 1em;
  grid-template-columns: repeat(auto-fit, minmax(min(100%, max(250px, 50% - 1em)), 1fr));

}
fieldset.form-group{
  display: contents;
}
.form-group::before, .form-group::after{
  content: none;
}
.table .form-group .help-block{
  display: block;
}
legend{
  font-weight: bold;
  font-size: larger;
  border: none;
  text-align: left;
  margin: 0;
  grid-column: 1 / -1;
}
[class|="col"]{
  padding-inline: 0;
}
label{
  flex: 1 0 auto;
}
label.required::before{
  content: "* ";
  color: #dd4b39;
}
</style>

<script>
/* eslint-disable */
import api from "@/assets/js/app-api";
import dfn from "@/views/projects/XSW/_Define";
import types from "@/views/projects/AWH5/_Define";
import ImageSelect from "@/views/projects/XSG/components/ImageSelect";
import MultilangInput from "@/views/projects/AWH5/components/MultilangInput";
import iCheckRedioButton from "@/components/iCheckRedioButton";
import Pagination from "@/components/Pagination";
import utils from "@/assets/js/app-utils";
import { mapState, mapActions } from "vuex";
import moment from "moment";

export default {
  components: {
    Pagination,
    ImageSelect,
    MultilangInput,
    iCheckRedioButton,
  },
  props: {

  },
  data() {
    return {
      langs: types.projectData.langs,
      lang: types.projectData.langs[0].id,
    
      searchData: {
        NewsId: "",
        分類: "",
        消息標題: "",
        語系: types.projectData.langs[0].id,
        PageNum: 1,
        PageSize: 10, //每頁顯示筆數
      },

      datas: [],
      total: 0, //總筆數
      message: "",
      
      can列表: this.$user.hasPermission(dfn.permission.官網News列表查詢),
      can查詢: this.$user.hasPermission(dfn.permission.官網News查詢),
      can新增: this.$user.hasPermission(dfn.permission.官網News新增),
      can修改: this.$user.hasPermission(dfn.permission.官網News修改),
      can刪除: this.$user.hasPermission(dfn.permission.官網News刪除),
      can複製: this.$user.hasPermission(dfn.permission.官網News複製),

      isEditing: false,
      editBackup: null,

      classTypes: [],
    };
  },
  async created() {
    this.getClassTypes();
    this.getDataList();
  },
  mounted() {
  },
  computed: {
    getImage() {
      return (item, info) => {
        const image = Array.isArray(item.files) ? item.files.find((file) => file.info === this.searchData.語系 + "_" + info) : null;
        return image ? image.filesUrl : "";
      };
    },
    getProp(){
      return (item, prop) => {
        const obj = Array.isArray(item.newsLanguageList) ? item.newsLanguageList.find(x => x.lang == this.searchData.語系) : null;
        return obj ? obj[prop] : "";
      };
    },
    getClassTypeName() {
      return (item) => {
        const type = Array.isArray(this.classTypes) ? this.classTypes.find(type => type.classTypeId == item.分類) : null;
        return type ? type.className : '未分類';
      };
    },
  },

  methods: {
    getClassTypes(){
      this.$store.dispatch("xsw/分類列表明細查詢", {module:"banner", isDelete:0}).then(res => {
        this.classTypes = Array.isArray(res) ? res.map(x => Array.isArray(x.detail_model) ? x.detail_model.find(y => y.lang == "zh-TW") : {}) : [];
      });
    },
    getDataList() {
      this.message = "查詢中";
      //列表
      this.$store.dispatch("xsw/官網News列表查詢", this.searchData).then((result) => {
        if (result) {
          this.datas = result.datas || [];
          // if (this.datas.length > 0) this.datas.forEach(item => item.files.sort((a, b) => b.id - a.id));
          this.total = result.total || 0;
          this.message = "";
          this.$refs.pagination.setPage(this.searchData.PageNum, this.total);
        }
      }, (err) => {
          this.datas = [];
          this.total = 0;
          this.message = err;
      });
    },
    clickSearch() {
      this.searchData.PageNum = 1;
      this.getDataList();
    },
    setTop(item){
      let sendEdit = Object.assign({}, item, {files:undefined, NewsId: item.newsId, newsId: undefined, 是否置頂: true});
      this.$store.dispatch("xsw/官網News修改", sendEdit).then((res) => {
        this.$eventBus.$emit("showAlert", this.$t('更新成功'));
        this.getDataList();
      }, (err) => {
          this.$eventBus.$emit("showAlert", this.$t(err));
      });
    },
    deleteItem(item){
      this.$eventBus.$emit("showConfirm", "確定要刪除嗎?", (isOK) => {
        if (isOK) {
          let sendEdit = Object.assign({}, item, {files:undefined, NewsId: item.newsId, newsId: undefined});
          this.$store.dispatch("xsw/官網News刪除", sendEdit).then((res) => {
            this.$eventBus.$emit("showAlert", this.$t('刪除成功'));
            this.getDataList();
          }, (err) => {
              this.$eventBus.$emit("showAlert", this.$t(err));
          });
        }
      });
    },
    copyItem(item){
      this.$eventBus.$emit("showConfirm", `確定要複製嗎?`, (isOK) => {
        if (isOK) {
          this.$store.dispatch("xsw/官網News複製", { NewsId: item.newsId }).then(res => {
            this.$eventBus.$emit("showAlert", this.$t('複製成功'));
            this.getDataList();
          }, err => {
            this.$eventBus.$emit("showAlert", err );
          });
        }
      });
    },
    async onPageChange(pageIdx, pageSize) {
      this.searchData.PageNum = pageIdx;
      this.searchData.PageSize = pageSize;
      this.getDataList();
    },

  },
};
</script>
