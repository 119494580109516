<template>
    <button class="btn relative" :class='btnClass' :disabled="spinner || disabled" @click="$emit('click')">
        <span class='absolute left-0 right-0 transition-1' :class="{'opacity-0 transition-1': !spinner}">
            <i class="fa fa-spinner fa-spin" aria-hidden="true" ></i>
        </span>
        <span class='transition-1' :class="{'opacity-0 transition-1': spinner}">
            <i :class="icon"></i>
            {{name}}
        </span>
    </button>
</template>

<script>
export default {
    props: {
        disabled: Boolean,
        spinner: Boolean,
        name: String,
        icon: String,
        btnClass: String
    },
}
</script>

<style lang='scss' scoped>
  .transition-1 {
    transition: .3s;
  }

  .relative {
    position: relative;
  }

  .absolute {
    position: absolute;
  }

  .left-0 {
    left: 0;
  }

  .right-0 {
    right: 0;
  }

  .opacity-0 {
    opacity: 0;
  }

</style>