<template>
  <div>
    <section class="content-header">
      <h1>FunOne Games {{ $t('帳單查詢')}}</h1>
      <ol class="breadcrumb">
        <li><router-link to="/">{{$t('首頁')}}</router-link></li>
        <li class="active">FunOne Games {{ $t('帳單查詢')}}</li>
      </ol>
    </section>
    <section class="content" style="min-height:auto;">
      
      <form class="nav-tabs-custom tab-warning mb-10" @submit.prevent="getData()">
        <ul class="nav nav-tabs">
          <li :class="{ active: searchTab == 1 }">
            <a @click="setSearchTab(1)" aria-expanded="true" data-toggle="tab" style="cursor:pointer;">{{$t('查詢暱稱與區間')}}</a>
          </li>
          <li :class="{ active: searchTab == 2 }">
            <a @click="setSearchTab(2)" aria-expanded="true" data-toggle="tab" style="cursor:pointer;">{{$t('單筆交易查詢')}}</a>
          </li>
          <!-- <li :class="{ active: searchTab == 3 }">
            <a @click="setSearchTab(3)" aria-expanded="true" data-toggle="tab" style="cursor:pointer;">{{$t('交易批次查詢')}}</a>
          </li> -->
        </ul>
        <div class="tab-content clearfix mt-10">
          <div class="pull-left" v-if="searchTab == 1">
            <div class="form-group m-0 pull-left">
              <label class="control-label pull-left mt-7" for="searchUID">UID：</label>
              <div class="input-group pull-left mb-10 mr-10">
                <input type="text" v-model="searchData.nickname" id="searchUID" class="form-control" placeholder="UID" >
              </div>
            </div>
            <div class="form-group pull-left m-0">
              <label class="control-label pull-left mt-7">{{$t('查詢區間')}}：</label>
              <div class="input-group date datetime pull-left mb-10 mr-10" style="width:300px;">
                <div class="input-group-addon fa fa-calendar"></div>
                <DateRangePicker :propStartDate.sync="searchData.startDate" :propEndDate.sync="searchData.endDate"
                  displayFormat="YYYY/MM/DD HH:mm" format="YYYY-MM-DDTHH:mm:ss" :propTimePicker="true" />
              </div>
            </div>
          </div>
          <div class="pull-left" v-if="searchTab == 2">
            <div class="form-group m-0 pull-left">
              <label class="control-label pull-left mt-7" for="searchSerial">{{$t('交易序號')}}：</label>
              <div class="input-group pull-left mb-10 mr-10">
                <input type="text" v-model="searchData.transactionId" id="searchSerial" class="form-control" :placeholder="$t('交易序號')" >
              </div>
            </div>
          </div>
          <!-- <div class="pull-left" v-if="searchTab == 3">
            <div class="form-group m-0 pull-left">
              <label class="control-label pull-left mt-7" for="searchBatch">{{$t('交易序號批次查詢')}}：</label>
              <div class="input-group pull-left mb-10 mr-10">
                <input class="form-control mt-0" type="file" accept=".xlsx" @change="searchFile = $event.target.files[0]; getData()" id="searchBatch" >
              </div>
            </div>
          </div> -->
          <div class="pull-left mb-10">
            <button type="submit" class="btn btn-primary mr-10">
              {{ $t('查詢') }}
            </button>
            <!-- <button type="button" class="btn btn-default" @click="exportData()" :disabled="datas.length === 0">
              {{$t('匯出')}}
            </button> -->
          </div>
          
          <!-- <div class="top-box clearfix" v-if="點數合計 != '-' || 不重複數 != '-'">
            <p class="text m-0 mr-10 mb-10"><b>點數合計：{{ 點數合計 }}</b></p>
            <p class="text m-0 mr-10 mb-10"><b>不重複數：{{ 不重複數 }}</b></p>
          </div> -->
        </div>
      </form>
      <div class="nav-tabs-custom tab-warning">
        <ul class="nav nav-tabs">
          <li :class="{ active: tab == 1 }">
            <a @click="setTab(1)" aria-expanded="true" data-toggle="tab" style="cursor:pointer;">{{$t('點數合計查詢')}}</a>
          </li>
          <li :class="{ active: tab == 2 }">
            <a @click="setTab(2)" aria-expanded="true" data-toggle="tab" style="cursor:pointer;">{{$t('帳單查詢')}}</a>
          </li>
        </ul>
        <div class="tab-content" v-show="tab == 1">
          <div class="table-responsive mt-10 mb-10" style="max-width:900px; margin:auto;">
            <table class="table table-bordered text-center table-striped">
              <thead>
                <tr class="bg-gray">
                  <th style="min-width:150px; width:20%">{{$t('日期')}}</th>
                  <th style="min-width:150px; width:20%">{{$t('點數合計')}}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-if="pastMessage"><td colspan="11">{{ $t(pastMessage) }}</td></tr>
                <tr v-else-if="pastList.length === 0"><td colspan="11">{{$t('查無資料')}}</td></tr>
                <tr v-else v-for="(item, index) in pagedPastData" :key="item.point">
                  <td>{{ item.date }}</td>
                  <td>{{ Number(item.point).toLocaleString('zh-TW') }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="text-center mt-10" v-show="pastTotal > pastPageSize">
            <Pagination ref="paginationPast" :total="pastTotal" :pageLimitCount="pastPageSize" @pageMethod="onPageChange" :type2="true" />
          </div>
        </div>
        <div class="tab-content" v-show="tab == 2">
          <div class="table-responsive mt-10 mb-10" style="max-width:900px; margin:auto;">
            <table class="table table-bordered text-center table-striped">
              <thead>
                <tr class="bg-gray">
                  <th style="min-width:150px; width:20%">{{$t('交易序號')}}</th>
                  <th style="min-width:100px; width:20%">{{$t('暱稱')}}</th>
                  <th style="min-width:100px; width:20%">{{$t('UID')}}</th>
                  <th style="min-width:100px; width:20%">{{$t('點數')}}</th>
                  <th style="min-width:150px; width:20%">{{$t('配點時間')}}</th>
                </tr>
              </thead>
              <tbody v-if="message"><tr><td colspan="11">{{ $t(message) }}</td></tr></tbody>
              <tbody v-else-if="datas.length === 0"><tr><td colspan="11">{{$t('查無資料')}}</td></tr></tbody>
              <tbody v-else>
                <tr v-for="(item, index) in pagedData" :key="item.交易序號">
                  <td>{{ item.交易序號 }}</td>
                  <td>{{ item.暱稱 }}</td>
                  <td>{{ item.id }}</td>
                  <td>{{ Number(item.點數).toLocaleString('zh-TW')  }}</td>
                  <td>{{ uiDate(item.配點時間) }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="text-center mt-10" v-show="total > pageSize">
            <Pagination ref="pagination" :total="total" :pageLimitCount="pageSize" @pageMethod="onPageChange" :type2="true" />
          </div>
        </div>
      </div>
    </section>

    <!-- <pre>{{ searchFile }} {{ searchData }}</pre>
    <pre>{{ datas }}</pre>
    <pre>{{ pastList }}</pre> -->
  </div>
</template>

<style lang="scss" scoped>
  table.table>tbody>tr {
    word-break: break-word;
  }
  table.table>thead>tr>th {
    word-break: keep-all;
  }
</style>
<style scoped>
  .table>>>mark{
    padding: 0;
    background: #ff8;
  }
</style>

<script>
/* eslint-disable */
import dfn from "@/views/projects/XSW/_Define";
import moment from "moment";
import DateRangePicker from "@/components/DateRangePicker";
import FormDialog from "./components/FormDialog.vue";
import Pagination from "@/components/Pagination";
import router from "../../../router";
import utils from "@/assets/js/app-utils";
import xlsx from "xlsx";

export default {
  components: {
    DateRangePicker,
    FormDialog,
    Pagination,
    router
  },
  data() {
    return {
      tab: 1,
      searchTab: 1,
      projectId: this.$store.state.projectId,

      filterString: "",
      searchFile: null,
      searchData: {
        "nickname": "",
        "transactionId": "",
        "startDate": new moment().subtract(1, "month").format("YYYY-MM-DDT00:00:00"),
        "endDate": new moment().format("YYYY-MM-DDT23:59:59"),
      },
      // "點數合計": "-",
      // "不重複數": "-",

      datas: [],
      message: "",
      total: 0, //總筆數
      pageNum: 1,
      pageSize: 20,

      pastMessage: "", //錯誤訊息
      pastTotal: 0, //總筆數
      pastList: [],
      pastPageNum: 1,
      pastPageSize: 20,
    };
  },
  async created() {
    if (this.$route.query.tab){
      this.tab = this.$route.query.tab;
    }
    this.getData();
    // this.getTypes();
    // window.v = this
  },
  computed:{
    pagedData: function () {
      return this.datas.slice((this.pageNum - 1) * this.pageSize, (this.pageNum) * this.pageSize);
    },
    pagedPastData: function () {
      return this.pastList.slice((this.pastPageNum - 1) * this.pastPageSize, (this.pastPageNum) * this.pastPageSize);
    },
  },
  methods: {
    setSearchTab(num){
      this.searchTab = num;
    },
    setTab(num){
      this.tab = num;
      let query = Object.assign({}, this.$route.query, {tab:num});
      if (JSON.stringify(query) != JSON.stringify(this.$route.query)){
        this.$router.replace({query:query});
      }
    },
    async onPageChange(pageIdx, pageSize) {
      if (this.tab == 1){
        this.pastPageNum = pageIdx;
        this.pastPageSize = pageSize;
      }
      if (this.tab == 2) {
        this.pageNum = pageIdx;
        this.pageSize = pageSize;
      }
    },
    getData(){
      let sendData = utils.deepCloneObj(this.searchData);
      sendData.startDate = new moment(sendData.startDate).subtract(16,"hours").format("YYYY-MM-DDTHH:mm:ss[Z]");
      sendData.endDate = new moment(sendData.endDate).subtract(16,"hours").format("YYYY-MM-DDTHH:mm:ss[Z]");
      this.$store.dispatch("xsa/post", {
        path: "/XinMYAPP/Payment/QueryFunOneBillList",
        data: sendData
      }).then(res => {
        let data = res.bills || {};
        let past = res.points || [];

        this.message = "";
        this.datas = data.datas || [];
        this.total = data.total || 0;
        this.pageNum = 1;
        this.$refs.pagination.setPage(this.pageNum, this.total);

        this.pastMessage = "";
        this.pastList = past || [];
        this.pastTotal = past.length || 0;
        this.pastPageNum = 1;
        this.$refs.paginationPast.setPage(this.pastPageNum, this.pastTotal);
      }, err => {
        this.message = err;
        this.datas = [];
        this.total = 0;

        this.pastMessage = err;
        this.pastList = [];
        this.pastTotal = 0;
      });
    },

    // exportData() {
    //     let 點數合計 = xlsx.utils.json_to_sheet(this.pastList || "");
    //     let 帳單 = xlsx.utils.json_to_sheet(this.datas || "");
    //     let fileName = `${this.platform == 'Google' ? 'GooglePlay' : 'iOS'}帳單查詢_${utils.getTimeId()}.xlsx`;
    //     let new_workbook = xlsx.utils.book_new();
    //     xlsx.utils.book_append_sheet(new_workbook, 點數合計, "點數合計");
    //     xlsx.utils.book_append_sheet(new_workbook, 帳單, "帳單");
    //     xlsx.writeFile(new_workbook, fileName);
    // },
    uiDate(date) {
      if (typeof date === "string") date = date.replace(' ', 'T') + 'Z';
      let d = new Date(date);
      if (d != "Invalid Date") return d.toLocaleString('zh-TW', {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        hourCycle: "h23",
      });
      else return date;
    }
  },
};
</script>