export default class {
  static permission = {
    //:'', 
    CreateFAQCategory:'新增常見問題分類', 
    UpdateFAQCategory:'修改常見問題分類', 
    DeleteFAQCategory:'刪除常見問題分類', 
    QueryFAQCategories:'查詢常見問題分類', 
    CreateFAQ:'新增常見問題與答案', 
    UpdateFAQ:'修改常見問題與答案', 
    DeleteFAQ:'刪除常見問題與答案', 
    QueryFAQs:'查詢常見問題', 
    DeleteAnswers:'刪除答案', 
    
  };
}


/* CreateFAQCategory  // 新增常見問題分類
UpdateFAQCategory  // 修改常見問題分類
DeleteFAQCategory  // 刪除常見問題分類
QueryFAQCategories  // 查詢常見問題分類
CreateFAQ  // 新增常見問題與答案
UpdateFAQ  // 修改常見問題與答案
DeleteFAQ  // 刪除常見問題與答案
QueryFAQs  // 查詢常見問題
DeleteAnswers  // 刪除答案 */