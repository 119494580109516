export default class {
  static permission = {
    
  }


  static langs = [
    { id: "en", name: "英文版" },
    { id: "tw", name: "繁中版" },
    { id: "cn", name: "簡中版" },
    { id: "vn", name: "越南版" },
    { id: "jp", name: "日文版" },
    { id: "th", name: "泰文版" },
    { id: "es", name: "西班牙版" },
    { id: "kr", name: "韓文版" },
  ]
  
  static projectData = {
    "langs": [
      { id: "zh-TW", name: "繁體中文", required: true },
      { id: "zh-CN", name: "簡體中文", required: true },
      { id: "en-US", name: "英語", required: true },
      { id: "ja-JP", name: "日本語" },
      { id: "th-TH", name: "泰語" },
      { id: "vi-VN", name: "越語" },
      { id: "es-ES", name: "西班牙語" },
      { id: "ko-KR", name: "韓語" },
    ], 
    "modules": [
      { id: "game", name: "遊戲分類" },
      { id: "banner", name: "消息分類" }
    ]
  }
}