import Vue from 'vue';
import moment from 'moment';
import define from '@/views/projects/FC/_Define';

Vue.filter("dateTimeFormat", (value, format = "YYYY/MM/DD") => {
    const now = moment();
    const datetime = moment(value);
    const duration = moment.duration(now.diff(datetime));
    const hours = Math.floor(duration.asHours());
    const days = Math.floor(duration.asDays());
    return hours < 1
      ? `${duration.minutes()} 分鐘前`
      : hours < 24
      ? `${hours} 小時前`
      : days <= 30
      ? `${days} 天前`
      : value
      ? datetime.format(format)
      : "";
  });

Vue.filter('dateFormat', (value, format = 'YYYY-MM-DD') => {
    const datetime = moment(value);
    return value ? datetime.format(format) : '-';
});

//數字加上千分位
Vue.filter('currencyFormat', (value) => {
    return value && !isNaN(value) ? value.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,') : value;
});
//數字加上千分位, false -> NaN
Vue.filter('currencyFormat2', (value) => {
    return value && !isNaN(value) ? value.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,') : 0;
});
//判斷是否為空值
Vue.filter('isAnyContent', (value) => {
    return !value ? '–' : value  ;
});

Vue.filter('ellipsis', (value, limit) => {
    value = value || '';
    limit = limit || value.length;
    return value.length > limit
        ? `${value.substring(0, limit)}...`
        : value;
});

Vue.prototype.define = define;
Vue.prototype.global = {
    getTime(dateTime) {
        const day = dateTime ? new Date(dateTime) : new Date();
        return day.toLocaleTimeString('en-US', {
            timeStyle: 'short',
        });
    },
    isValidDay(day) {
        return !day ? false : isNaN(day.getTime()) ? false : true;
    },
    isValidFutureDay(day) {
        return this.isValidDay(day) && day > new Date() ? true : false;
    },
    isValidDayRange(startDay, endDay, required = true) {
        if (this.isValidDay(startDay) && this.isValidDay(endDay)) {
            return endDay >= startDay;
        } else if (!startDay && !endDay && !required) {
            return true;
        }
        return false;
    },
    showResponse(self, res, action, callback, hideMsg) {
        const message = hideMsg ? "" : res.data.message;
        if (res.data.status === '1') {
            if (action) {
                self.$eventBus.$emit('showAlert', message || `${this.getMessage(action)}成功`);
            }
            if (callback) {
                callback();
            }
        } else {
            self.$eventBus.$emit('showAlert', message || `${this.getMessage(action)}失敗`);
        }
    },
    getMessage(action) {
        switch (action) {
            case 'create':
                return '新增';
            case 'update':
                return '修改';
            case 'delete':
                return '刪除';
            case 'send':
                return '送出';
            default:
                return '執行';
        }
    },

    // 將base64轉成file格式後上傳
    regexImg64(self, content, propLang, callback) {
        const regexImg64 = new RegExp(
            "data:image/([a-zA-Z]*);base64,([^\"']*)",
            "g"
        );
        const uploadImgs = content.match(regexImg64) || [];
        const images = [];
        if (uploadImgs.length) {
            let success = true;
            let upload = 0;
            let done = 0;
            uploadImgs.forEach(image => {
                if (success) {
                    const regexImg = new RegExp("image/([a-zA-Z]*)", "g");
                    const types = image.match(regexImg) || [];
                    if (types.length) {
                        upload++;
                        self.global
                            .urltoFile(image, new Date().getTime(), types[0])
                            .then(file => {
                                done++;
                                if (success) {
                                    images.push({ file: file, image: image });
                                    if (upload === done) {
                                        self.global.uploadFile(self, content, images, propLang, 'fromFilter', callback);
                                    }
                                } else {
                                    success = false;
                                }
                            });
                    }
                }
            });
        }
    },

    // 上傳圖片並將內容base64替換成網址
    uploadFile(self, content, images, propLang, type, callback) {
        if (images) {
            let fileApi;
            const isNewtalk =
            location.href.indexOf("/Newtalk/") !== -1 ? true : false;
            const data = new FormData();
            if (isNewtalk) {
                // 新頭殼
                data.append("qppPath", "vote");
                fileApi = "newtalk/fileUpload";
            } else {
                fileApi = "file/fileUpload";
                data.append("projectId", self.$store.state.projectId);
                data.append("module", "news");
                data.append("lang", propLang);
                data.append("multi", true);
            }

            if (type === 'fromFilter') {
                images.forEach(image => {
                    data.append("files", image.file);
                });
            } else {
                for (var i = 0; i < images.length; i++) {
                    data.append("files", images[i]);
                }
            }
            self.$store.dispatch(fileApi, data).then((res) => {
                let newContent = content;
                const multiUrl = isNewtalk ? res.files : res.multiUrl;
                if (isNewtalk) {
                    for (var i = 0; i < multiUrl.length; i++) {
                        data.append("files", multiUrl[i]);
                        newContent = newContent.replace(
                            images[i].image,
                            multiUrl[i].imageUrl
                        );
                    }
                } else {
                    multiUrl.forEach((file, index) => {
                        newContent = newContent.replace(
                        images[index].image,
                        file
                      );
                    });
                }
                if (callback) callback(newContent);
            });
        }
    },
    urltoFile(url, filename, mimeType) {
        return fetch(url)
            .then(function(res) {
                return res.arrayBuffer();
            })
            .then(function(buf) {
                return new File([buf], filename, { type: mimeType });
            });
    },
};
